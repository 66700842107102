// Helper types and functions derived from the BorrowerIntakeForm data structure.

import _ from "lodash";
import sections, { entityTypes } from "src/data/BorrowerIntakePages";
import { BorrowerIntakePage } from "src/models/BorrowerIntakePage";
import { DocumentStatus } from "src/models/DocumentDetails";
import { Uuid } from "src/utils/uuid";
import { z } from "zod";
import { documentRequestOrder } from "@interfold-ai/shared/utils/EntityToDocumentMapping";
import { QuestionSchema } from "src/models/WorkflowSettings";

export type EntityType = (typeof entityTypes)[number];

export function isIndividual(entityType: EntityType): boolean {
  return (
    entityType === "Person/Household" || entityType === "Person" || entityType === "INDIVIDUAL"
  );
}

export function isCompany(entityType: EntityType): boolean {
  return !isIndividual(entityType);
}

const allQuestions = Object.values(sections)
  .flat()
  .flatMap((page: BorrowerIntakePage) => page.questions)
  .flatMap((question) =>
    question.subQuestions ? [question, ...question.subQuestions] : [question],
  );

export function getQuestionById(
  questionId: Uuid,
  workflowQuestions: QuestionSchema[] = allQuestions as QuestionSchema[],
): QuestionSchema | undefined {
  // Strip the last character from the questionId to match the format of the workflowQuestions
  return workflowQuestions.find((question) => question.id.slice(0, -1) === questionId.slice(0, -1));
}

// Define ordered quarters as a readonly tuple
export type OrderedQuarters = readonly ["Q1", "Q2", "Q3", "Q4"];
export const ORDERED_QUARTERS: OrderedQuarters = ["Q1", "Q2", "Q3", "Q4"];

// Correctly initialize ZodQuarter using the array syntax
export const ZodQuarter = z.enum(["Q1", "Q2", "Q3", "Q4"]);

// Infer the Quarter type from ZodQuarter
export type Quarter = z.infer<typeof ZodQuarter>;

// Define the Zod enum for months
export const ZodMonthEnum = z.enum([
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]);

// Infer the Month type from ZodMonthEnum
export type Month = z.infer<typeof ZodMonthEnum>;

// Define ordered months as a readonly tuple
export type OrderedMonths = readonly [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Correct the constant name and assign the ordered months
export const ORDERED_MONTHS: OrderedMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function sortByDocumentName(a: string, b: string) {
  return documentRequestOrder.indexOf(a) - documentRequestOrder.indexOf(b);
}

// This is generic so that it can be used for both DocumentStatus and DocumentStatusWithCheckbox.
export function sortDocumentStatusesByName<T extends DocumentStatus>(documentStatuses: T[]): T[] {
  return [...documentStatuses].sort((a, b) => {
    return sortByDocumentName(a.docType, b.docType);
  });
}

export function sortDocumentStatuses(documentStatuses: DocumentStatus[]): DocumentStatus[] {
  let sortedDocumentStatuses = sortDocumentStatusesByName(documentStatuses);

  sortedDocumentStatuses.forEach((documentStatus, index) => {
    sortedDocumentStatuses[index] = {
      ...documentStatus,
      frequencies: _.orderBy(
        documentStatus.frequencies,
        [
          "year",
          "half",
          "quarter",
          (dsFreq) => (dsFreq.month ? ORDERED_MONTHS.indexOf(dsFreq.month as Month) : -1),
        ],
        ["desc", "desc", "desc", "desc"],
      ),
    };
  });

  return sortedDocumentStatuses;
}

export const DOCUMENT_CLASSIFICATION = {
  DOCUMENT: "document",
  PDF: "pdf",
  SPREADSHEET: "spreadsheet",
  IMAGE: "image",
  DOWNLOAD: "download",
  ZIP: "zip",
};

// This constant is meant to serve as a basis for
// how to handle various file types when previewing.
// Note: This is not implemented yet, but flagged for
// future use.
export const SUPPORTED_FILE_TYPES = {
  PDF: DOCUMENT_CLASSIFICATION.PDF,
  DOC: DOCUMENT_CLASSIFICATION.DOCUMENT,
  DOCX: DOCUMENT_CLASSIFICATION.DOCUMENT,
  ODT: DOCUMENT_CLASSIFICATION.DOCUMENT,
  PPT: DOCUMENT_CLASSIFICATION.DOCUMENT,
  PPTX: DOCUMENT_CLASSIFICATION.DOCUMENT,
  TXT: DOCUMENT_CLASSIFICATION.DOCUMENT,
  XLS: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  XLSX: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  CSV: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  TSV: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  ODS: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  NUMBERS: DOCUMENT_CLASSIFICATION.SPREADSHEET,
  JPG: DOCUMENT_CLASSIFICATION.IMAGE,
  PNG: DOCUMENT_CLASSIFICATION.IMAGE,
  GIF: DOCUMENT_CLASSIFICATION.IMAGE,
  WEBP: DOCUMENT_CLASSIFICATION.IMAGE,
  JPEG: DOCUMENT_CLASSIFICATION.IMAGE,
  JIF: DOCUMENT_CLASSIFICATION.IMAGE,
  ZIP: DOCUMENT_CLASSIFICATION.ZIP,
};

// This is a list of file types we do not allow
// as they pose security risks. Please keep this list
// up-to-date and add any file types we deem dangerous.
export const BLACKLISTED_FILE_TYPES = [
  "ade",
  "adp",
  "app",
  "asp",
  "aspx",
  "bat",
  "cer",
  "chm",
  "cmd",
  "com",
  "config",
  "cpl",
  "crt",
  "db",
  "dbf",
  "der",
  "dll",
  "dmg",
  "exe",
  "hlp",
  "hta",
  "htm",
  "html",
  "ini",
  "jar",
  "js",
  "json",
  "jsp",
  "msc",
  "msi",
  "pem",
  "php",
  "pl",
  "ps1",
  "py",
  "rar",
  "reg",
  "scr",
  "sh",
  "so",
  "sql",
  "tar",
  "vbs",
  "ws",
  "wsf",
];

export const isValidFileType = (fileName: string): boolean => {
  try {
    const fileExtension = fileName.split(".").pop();
    return !!fileExtension && !BLACKLISTED_FILE_TYPES.includes(fileExtension);
  } catch (error) {
    console.error(`File Extension not available: ${error}`);
    return false;
  }
};
