import { createAsyncThunk, createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { Memo } from "@prisma/client";
import { CreateOrUpdateMemo } from "src/models/Memo";
import { MemoService } from "src/services/memo/MemoService";
import { AppState } from "../reducers";
import { StatusCodes } from "src/constants/status-codes";

export interface MemoState {
  data: Memo | null;
  isLoading: boolean;
  error: string | null;
}

export const initialMemoState: MemoState = {
  data: null,
  isLoading: false,
  error: null,
};

export const selectMemoState = (state: AppState) => state.memo;

export const fetchMemoById = createAsyncThunk<
  Memo | null,
  FetchMemoByIdParams,
  { rejectValue: { memoId: number; error: string } }
>("memo/fetchMemoById", async ({ memoId }, { rejectWithValue }) => {
  try {
    const service = MemoService.getInstance();
    const memo = await service.getMemoById(memoId);
    return memo ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ memoId, error: (error as Error).message });
  }
});

export const fetchMemoByLoanId = createAsyncThunk<
  Memo | null,
  FetchMemoByLoanIdParams,
  { rejectValue: { loanId: number; error: string } }
>("memo/fetchMemoByLoanId", async ({ loanId }, { rejectWithValue }) => {
  try {
    const service = MemoService.getInstance();
    const memo = await service.getMemoByLoanId(loanId);
    return memo ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ loanId, error: (error as Error).message });
  }
});

export const fetchMemoByReviewId = createAsyncThunk<
  Memo | null,
  FetchMemoByReviewIdParams,
  { rejectValue: { reviewId: number; error: string } }
>("memo/fetchMemoByReviewId", async ({ reviewId }, { rejectWithValue }) => {
  try {
    const service = MemoService.getInstance();
    const memo = await service.getMemoByReviewId(reviewId);
    return memo ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ reviewId, error: (error as Error).message });
  }
});

export const createMemo = createAsyncThunk<
  Memo,
  CreateOrUpdateMemo,
  { rejectValue: { loanId?: number; reviewId?: number; error: string } }
>("memo/createMemo", async (params, { rejectWithValue }) => {
  try {
    const service = MemoService.getInstance();
    const newlyCreatedMemo = await service.createMemo(params);
    if (!newlyCreatedMemo) {
      return rejectWithValue({
        loanId: params.loanId,
        reviewId: params.reviewId,
        error: "Failed to create memo",
      });
    }
    return newlyCreatedMemo;
  } catch (error: any) {
    return rejectWithValue({
      loanId: params.loanId,
      reviewId: params.reviewId,
      error: (error as Error).message,
    });
  }
});

export const updateMemo = createAsyncThunk<
  Memo,
  UpdateMemoParams,
  { rejectValue: { memoId: number; loanId?: number; reviewId?: number; error: string } }
>("memo/updateMemo", async (params, { rejectWithValue }) => {
  try {
    const service = MemoService.getInstance();
    const updatedMemo = await service.updateMemo(params.id, params);
    if (!updatedMemo) {
      return rejectWithValue({
        memoId: params.id,
        loanId: params.loanId,
        reviewId: params.reviewId,
        error: "Failed to update memo ",
      });
    }
    return updatedMemo;
  } catch (error: any) {
    return rejectWithValue({
      memoId: params.id,
      loanId: params.loanId,
      reviewId: params.reviewId,
      error: (error as Error).message,
    });
  }
});

export const memoSlice: Slice<MemoState> = createSlice({
  name: "memo",
  initialState: initialMemoState,
  reducers: {
    resetMemoState: (state) => {
      state = initialMemoState;
    },
    setMemoData: (
      state,
      action: PayloadAction<{
        data: Memo | null;
      }>,
    ) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    setMemoLoading: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>,
    ) => {
      state.isLoading = action.payload.isLoading;
    },
  },
  extraReducers: (builder) => {
    // fetchMemoById
    builder
      .addCase(fetchMemoById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMemoById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchMemoById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "Failed to fetch memo";
      });

    // fetchMemoByLoanId
    builder
      .addCase(fetchMemoByLoanId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMemoByLoanId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchMemoByLoanId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "Failed to fetch memo by loan ID";
      });

    // fetchMemoByReviewId
    builder
      .addCase(fetchMemoByReviewId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMemoByReviewId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchMemoByReviewId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "Failed to fetch memo by review ID";
      });

    // createMemo
    builder
      .addCase(createMemo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createMemo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createMemo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "Failed to create memo";
      });

    // updateMemo
    builder
      .addCase(updateMemo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMemo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(updateMemo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "Failed to update memo";
      });
  },
  selectors: {
    memoData: (state: MemoState) => state.data,
    memoLoading: (state: MemoState) => state.isLoading,
  },
});

export interface FetchMemoByIdParams {
  memoId: number;
}

export interface FetchMemoByLoanIdParams {
  loanId: number;
}

export interface FetchMemoByReviewIdParams {
  reviewId: number;
}

export interface UpdateMemoParams extends CreateOrUpdateMemo {
  id: number;
}

export default memoSlice.reducer;
