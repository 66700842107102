import { createAsyncThunk, createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { DocumentAnalysis } from "@prisma/client";
import { CreateOrUpdateDocumentAnalysis } from "src/models/DocumentAnalysis";
import { DocumentAnalysisService } from "src/services/document-analysis/DocumentAnalysisService";
import { AppState } from "src/redux/reducers";
import { StatusCodes } from "src/constants/status-codes";
import { stat } from "fs";

export interface DocumentAnalysisState {
  data: DocumentAnalysis[];
  isLoading: boolean;
  error: string | null;
}

export const initialDocumentAnalysisState: DocumentAnalysisState = {
  data: [],
  isLoading: false,
  error: null,
};

export const selectDocumentAnalysisState = (state: AppState) => state.documentAnalysis;

export const fetchDocumentAnalysisById = createAsyncThunk<
  DocumentAnalysis | null,
  FetchDocumentAnalysisByIdParams,
  { rejectValue: { documentAnalysisId: number; error: string } }
>("memo/fetchDocumentAnalysisById", async ({ documentAnalysisId }, { rejectWithValue }) => {
  try {
    const service = DocumentAnalysisService.getInstance();
    const documentAnalysis = await service.getDocumentAnalysisById(documentAnalysisId);
    return documentAnalysis ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ documentAnalysisId, error: (error as Error).message });
  }
});

export const fetchDocumentAnalysisByLoanId = createAsyncThunk<
  DocumentAnalysis[] | null,
  FetchDocumentAnalysisByLoanIdParams,
  { rejectValue: { loanId: number; error: string } }
>("memo/fetchDocumentAnalysisByLoanId", async ({ loanId }, { rejectWithValue }) => {
  try {
    const service = DocumentAnalysisService.getInstance();
    const documentAnalysis = await service.getDocumentAnalysisByLoanId(loanId);
    return documentAnalysis ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ loanId, error: (error as Error).message });
  }
});

export const fetchDocumentAnalysisByReviewId = createAsyncThunk<
  DocumentAnalysis[] | null,
  FetchDocumentAnalysisByReviewIdParams,
  { rejectValue: { reviewId: number; error: string } }
>("memo/fetchDocumentAnalysisByReviewId", async ({ reviewId }, { rejectWithValue }) => {
  try {
    const service = DocumentAnalysisService.getInstance();
    const documentAnalysis = await service.getDocumentAnalysisByReviewId(reviewId);
    return documentAnalysis ?? null;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return null;
    }
    return rejectWithValue({ reviewId, error: (error as Error).message });
  }
});

export const createDocumentAnalysis = createAsyncThunk<
  DocumentAnalysis[],
  CreateOrUpdateDocumentAnalysis,
  { rejectValue: { loanId?: number; reviewId?: number; error: string } }
>("memo/createDocumentAnalysis", async (params, { rejectWithValue }) => {
  try {
    const service = DocumentAnalysisService.getInstance();
    const newlyCreatedDocumentAnalysis = await service.createDocumentAnalysis(params);
    if (!newlyCreatedDocumentAnalysis) {
      return rejectWithValue({
        loanId: params.loanId,
        reviewId: params.reviewId,
        error: "Failed to create memo analysis results",
      });
    }
    return newlyCreatedDocumentAnalysis;
  } catch (error: any) {
    return rejectWithValue({
      loanId: params.loanId,
      reviewId: params.reviewId,
      error: (error as Error).message,
    });
  }
});

export const updateDocumentAnalysis = createAsyncThunk<
  DocumentAnalysis[],
  UpdateDocumentAnalysisParams,
  { rejectValue: { memoId: number; loanId?: number; reviewId?: number; error: string } }
>("memo/updateDocumentAnalysis", async (params, { rejectWithValue }) => {
  try {
    const service = DocumentAnalysisService.getInstance();
    const updatedDocumentAnalysis = await service.updateDocumentAnalysis(params.id, params);
    if (!updatedDocumentAnalysis) {
      return rejectWithValue({
        memoId: params.id,
        loanId: params.loanId,
        reviewId: params.reviewId,
        error: "Failed to update memo analysis results",
      });
    }
    return updatedDocumentAnalysis;
  } catch (error: any) {
    return rejectWithValue({
      memoId: params.id,
      loanId: params.loanId,
      reviewId: params.reviewId,
      error: (error as Error).message,
    });
  }
});

export const documentAnalysisSlice: Slice<DocumentAnalysisState> = createSlice({
  name: "documentAnalysis",
  initialState: initialDocumentAnalysisState,
  reducers: {
    resetDocumentAnalysisState: (state) => {
      state = initialDocumentAnalysisState;
    },
    setDocumentAnalysisData: (
      state,
      action: PayloadAction<{
        data: DocumentAnalysis[];
      }>,
    ) => {
      state.data = action.payload.data;
      state.isLoading = false;
    },
    setDocumentAnalysisIsLoading: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>,
    ) => {
      state.isLoading = action.payload.isLoading;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchDocumentAnalysisStateById
    builder
      .addCase(fetchDocumentAnalysisById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload ? [action.payload] : [];
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "An error occurred";
      });

    // Handle fetchDocumentAnalysisByLoanId
    builder
      .addCase(fetchDocumentAnalysisByLoanId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisByLoanId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload ? action.payload : [];
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisByLoanId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "An error occurred";
      });

    // Handle fetchDocumentAnalysisByReviewId
    builder
      .addCase(fetchDocumentAnalysisByReviewId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisByReviewId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload ? action.payload : [];
        state.error = null;
      })
      .addCase(fetchDocumentAnalysisByReviewId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "An error occurred";
      });

    // Handle createDocumentAnalysis
    builder
      .addCase(createDocumentAnalysis.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createDocumentAnalysis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createDocumentAnalysis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "An error occurred";
      });

    // Handle updateDocumentAnalysis
    builder
      .addCase(updateDocumentAnalysis.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateDocumentAnalysis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(updateDocumentAnalysis.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error ?? "An error occurred";
      });
  },
});

export interface FetchDocumentAnalysisByIdParams {
  documentAnalysisId: number;
}

export interface FetchDocumentAnalysisByLoanIdParams {
  loanId: number;
}

export interface FetchDocumentAnalysisByReviewIdParams {
  reviewId: number;
}

export interface UpdateDocumentAnalysisParams extends CreateOrUpdateDocumentAnalysis {
  id: number;
}

export default documentAnalysisSlice.reducer;
