import { GridState } from "src/classes/GridState";
import { ManualWorkFlowEnum } from "@interfold-ai/shared/models/SpreadsTabConstants";
import generalMiddleMarket from "./acb/general-middle-market.json";
import { ColDef } from "ag-grid-community";
import { TabName } from "src/redux/reducers/types";

export class ManualWorkflowFactory {
  constructor(public workflow: ManualWorkFlowEnum) {}
  asGridState(): Record<string, GridState> {
    switch (this.workflow) {
      case ManualWorkFlowEnum.GENERAL_MIDDLE_MARKET:
        return generalMiddleMarket;
    }
  }

  asColDefs(): Record<TabName, ColDef[]> {
    const generateNumCols = (num: number) =>
      "ABCDEF"
        .split("")
        .slice(0, num)
        .map((letter, index) => {
          return {
            headerName: letter,
            field: letter,
            editable: true,
            colId: letter,
            minWidth: 300,
          };
        });
    switch (this.workflow) {
      case ManualWorkFlowEnum.GENERAL_MIDDLE_MARKET:
        return {
          ["Input Form" as TabName]: generateNumCols(6),
        };
    }
  }
}
