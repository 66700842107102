import produce from "immer";
import { Reducer } from "redux";
import { LenderEmployee } from "src/models/LenderEmployee";
import { LenderAction } from "../actions/actions.constants";
import { LenderSettings } from "@interfold-ai/db";

export interface LenderState {
  data: LenderEmployee[];
  settings: LenderSettings[];
  isLoading: boolean;
}

export const initialLenderState: LenderState = {
  data: [],
  settings: [],
  isLoading: false,
};

export const lenderReducer: Reducer<LenderState> = (
  state: LenderState = initialLenderState,
  action,
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY:
      case LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE: {
        draft.isLoading = true;
        break;
      }
      case LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE: {
        draft.isLoading = true;
        break;
      }
      case LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY_FAILED:
      case LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE_FAILED: {
        draft.isLoading = false;
        break;
      }
      case LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE_FAILED: {
        draft.isLoading = false;
        break;
      }
      case LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY_COMPLETED:
      case LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE_COMPLETED: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE_COMPLETED: {
        draft.data = action.payload;
        draft.isLoading = false;
        break;
      }
      case LenderAction.FETCH_LENDER_SETTINGS: {
        draft.isLoading = true;
        break;
      }
      case LenderAction.FETCH_LENDER_SETTINGS_COMPLETED: {
        draft.settings = action.payload;
        draft.isLoading = false;
        break;
      }
      case LenderAction.FETCH_LENDER_SETTINGS_FAILED: {
        draft.isLoading = false;
        break;
      }
      case LenderAction.UPDATE_LENDER_SETTINGS: {
        draft.isLoading = true;
        break;
      }
      case LenderAction.UPDATE_LENDER_SETTINGS_COMPLETED: {
        draft.isLoading = false;
        draft.settings = [...draft.settings].map((setting) => {
          if (setting.id === (action.payload as LenderSettings).id) {
            return action.payload;
          }
          return setting;
        });
        break;
      }
    }
  });

// Select lender employees from the lender state
// in the "options" format required by react-select.
// Should this be a selector? Should selectors transform data like this?
export const optionsFromLenderState = (lenders: LenderState) => {
  return lenders.data.map((lender) => ({
    value: String(lender.id),
    label: lender.name,
  }));
};
