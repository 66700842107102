// Shared between the borrower intake screens (overview, borrower, guarantors, collateral)
export enum BorrowerIntakeAction {
  FETCH_ANSWER = "intake/fetch/answer",
  FETCH_ANSWER_COMPLETED = "intake/fetch/answer/completed",
  FETCH_ANSWER_ERROR = "intake/fetch/answer/error",
  CREATE_QUESTION = "intake/create/question",
  CREATE_QUESTION_COMPLETED = "intake/create/question/completed",
  CREATE_QUESTION_ERROR = "intake/create/question/error",
  UPLOAD_DOCUMENT = "intake/upload/document",
  UPLOAD_DOCUMENT_PROGRESS = "intake/upload/document/progress",
  UPLOAD_DOCUMENT_COMPLETED = "intake/upload/document/completed",
  UPLOAD_DOCUMENT_ERROR = "intake/upload/document/error",
  REVIEW_UPLOAD_DOCUMENT = "intake/review/upload/document",
  REVIEW_UPLOAD_DOCUMENT_COMPLETED = "intake/review/upload/document/completed",
  BULK_UPLOAD_DOCUMENT = "intake/bulk_upload/document",
  BULK_UPLOAD_DOCUMENT_COMPLETE = "intake/bulk_upload/document/completed",
  LENDER_SIDE_UPLOAD_DOCUMENT_COMPLETE = "intake/lender_side/upload/document/completed",
  DROP_UPLOADING_DOCUMENT = "intake/upload/document/drop",
  GUARANTOR_PAGE_UPDATE = "intake/guarantorPage/update",
  ARE_ALL_SECTIONS_COMPLETED_UPDATE = "intake/sections_completion/update",
  IS_OVERVIEW_COMPLETED = "intake/overviewCompletion/update",
  SAVE_FETCHED_ANSWER = "intake/save_answer/question",
  NAVBAR_LOADING = "intake/navbar_loading/update",
  SAVE_LOAN_DESCRIPTION = "intake/loan/description",

  OPEN_ADD_ANY_FILE_MODAL = "intake/open_add_any_file_modal",
  CLOSE_ADD_ANY_FILE_MODAL = "intake/close_add_any_file_modal",

  DISABLE_ADD_ANY_FILE_UPLOAD = "intake/disable_add_any_file_upload",
  ENABLE_ADD_ANY_FILE_UPLOAD = "intake/enable_add_any_file_upload",

  SET_DEFAULT_ADD_ANY_ENTITY = "intake/set_default_add_any_entity",

  MULTI_DOCUMENT_UPLOAD = "intake/multi_document_upload",
  MULTI_DOCUMENT_DELETE = "intake/multi_document_delete",
}

export enum BootStrapActionType {
  FETCH_INITIAL_REQUIRED_VALUES = "initial-required-values/fetch",
}

export enum FeatureFlags {
  SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS",
  FETCH_FEATURE_FLAGS = "FETCH_FEATURE_FLAGS",
  UPDATE_FEATURE_FLAG = "UPDATE_FEATURE_FLAG",
  UPDATED_FEATURE_FLAG = "UPDATED_FEATURE_FLAG",
  CANCEL_EDIT_FEATURE_FLAG = "CANCEL_EDIT_FEATURE_FLAG",
  EDIT_FEATURE_FLAG = "EDIT_FEATURE_FLAG",
}

export enum LoanWorkflows {
  SET_LOAN_WORKFLOWS = "SET_LOAN_WORKFLOWS",
  FETCH_LOAN_WORKFLOWS = "FETCH_LOAN_WORKFLOWS",
  CREATE_NEW_LOAN_WORKFLOW = "CREATE_NEW_LOAN_WORKFLOW",
  UPDATE_LOAN_WORKFLOW = "UPDATE_LOAN_WORKFLOW",
  DELETE_LOAN_WORKFLOW = "DELETE_LOAN_WORKFLOW",
}

export enum PipelineLoans {
  FETCH_LOANS = "FETCH_LOANS",
  FETCH_ANNUAL_REVIEWS = "FETCH_ANNUAL_REVIEWS",
  UPDATE_PIPELINE_SEARCH_PARAMS = "UPDATE_PIPELINE_SEARCH_PARAMS",
  SET_PIPELINE_SEARCH_PARAMS = "SET_PIPELINE_SEARCH_PARAMS",
  SET_LOANS = "SET_LOANS",
  SET_REVIEWS = "SET_REVIEWS",
  SET_MANAGEMENT_DATA = "SET_MANAGEMENT_DATA",
  FETCH_LOAN_DETAIL = "FETCH_LOAN_DETAIL",
  SET_LOAN_DETAIL = "SET_LOAN_DETAIL",
  FETCH_ANNUAL_REVIEW_DETAIL = "FETCH_ANNUAL_REVIEW_DETAIL",
  FETCH_ANNUAL_REVIEW_DETAIL_FAILED = "FETCH_ANNUAL_REVIEW_DETAIL_FAILED",
  SET_ANNUAL_REVIEW_DETAIL = "SET_ANNUAL_REVIEW_DETAIL",
  CREATE_DOCUMENT_REQUEST = "CREATE_DOCUMENT_REQUEST",
  CREATE_DOCUMENT_REQUEST_COMPLETED = "CREATE_DOCUMENT_REQUEST_COMPLETED",
  CREATE_DOCUMENT_REQUEST_FAILED = "CREATE_DOCUMENT_REQUEST_FAILED",
  CREATE_REPORTING_SEQUENCE = "CREATE_REPORTING_SEQUENCE",
  // TODO: remove this later
  UPDATE_REPORTING_SEQUENCE = "UPDATE_REPORTING_SEQUENCE",
  EDIT_REPORTING_SEQUENCE = "EDIT_REPORTING_SEQUENCE",
  DELETE_REPORTING_SEQUENCE = "DELETE_REPORTING_SEQUENCE",
  CREATE_REPORTING_SEQUENCE_COMPLETED = "CREATE_REPORTING_SEQUENCE_COMPLETED",
  CREATE_REPORTING_SEQUENCE_FAILED = "CREATE_REPORTING_SEQUENCE_FAILED",
  SET_UPLOADED_DOCUMENT = "SET_UPLOADED_DOCUMENT",
  UPDATE_DOCUMENT_STATUS = "UPDATE_DOCUMENT_STATUS",
  UPDATE_DOCUMENT_STATUS_COMPLETED = "UPDATE_DOCUMENT_STATUS_COMPLETED",
  UPDATE_DOCUMENT_STATUS_FAILED = "UPDATE_DOCUMENT_STATUS_FAILED",
  EDIT_LOAN = "EDIT_LOAN",
  EDIT_LOAN_COMPLETED = "EDIT_LOAN_COMPLETED",
  EDIT_LOAN_FAILED = "EDIT_LOAN_FAILED",
  UPDATE_LOAN_STATUS = "UPDATE_LOAN_STATUS",
  UPDATE_ANNUAL_REVIEW_STATUS = "UPDATE_ANNUAL_REVIEW_STATUS",
  MODIFY_CUSTOMER_USERS = "MODIFY_CUSTOMER_USERS",
  ADD_UPDATE_NOTE = "ADD_UPDATE_NOTE",
  ADD_UPDATE_NOTE_COMPLETED = "ADD_UPDATE_NOTE_COMPLETED",
  ADD_UPDATE_NOTE_FAILED = "ADD_UPDATE_NOTE_FAILED",
  UPDATE_REMINDER = "UPDATE_REMINDER",
  UPDATE_METADATA = "UPDATE_METADATA",
}

export enum LenderAction {
  FETCH_LENDER_EMPLOYEE_BY_QUERY = "FETCH_LENDER_EMPLOYEE_BY_QUERY",
  FETCH_LENDER_EMPLOYEE_BY_QUERY_COMPLETED = "FETCH_LENDER_EMPLOYEE_BY_QUERY_COMPLETED",
  FETCH_LENDER_EMPLOYEE_BY_QUERY_FAILED = "FETCH_LENDER_EMPLOYEE_BY_QUERY_FAILED",

  UPDATE_LOAN_LENDER_EMPLOYEE = "UPDATE_LOAN_LENDER_EMPLOYEE",
  UPDATE_LOAN_LENDER_EMPLOYEE_COMPLETED = "UPDATE_LOAN_LENDER_EMPLOYEE_COMPLETED",
  UPDATE_LOAN_LENDER_EMPLOYEE_FAILED = "UPDATE_LOAN_LENDER_EMPLOYEE_FAILED",

  FETCH_LENDER_BY_USER_CODE = "FETCH_LENDER_BY_USER_CODE",
  FETCH_LENDER_BY_USER_CODE_COMPLETE = "FETCH_LENDER_BY_USER_CODE_COMPLETE",
  FETCH_LENDER_BY_USER_CODE_FAILED = "FETCH_LENDER_BY_USER_CODE_FAILED",

  UPDATE_REVIEW_LENDER_EMPLOYEE = "UPDATE_REVIEW_LENDER_EMPLOYEE",
  UPDATE_REVIEW_LENDER_EMPLOYEE_COMPLETED = "UPDATE_REVIEW_LENDER_EMPLOYEE_COMPLETED",
  UPDATE_REVIEW_LENDER_EMPLOYEE_FAILED = "UPDATE_REVIEW_LENDER_EMPLOYEE_FAILED",

  FETCH_LENDER_SETTINGS = "FETCH_LENDER_SETTINGS",
  FETCH_LENDER_SETTINGS_COMPLETED = "FETCH_LENDER_SETTINGS_COMPLETED",
  FETCH_LENDER_SETTINGS_FAILED = "FETCH_LENDER_SETTINGS_FAILED",

  UPDATE_LENDER_SETTINGS = "UPDATE_LENDER_SETTINGS",
  UPDATE_LENDER_SETTINGS_COMPLETED = "UPDATE_LENDER_SETTINGS_COMPLETED",
  UPDATE_LENDER_SETTINGS_FAILED = "UPDATE_LENDER_SETTINGS_FAILED",
}

export enum AnnualReviewAction {
  SAVE_STEP_DATA = "SAVE_STEP_DATA",
  CREATE_ANNUAL_REVIEW = "CREATE_ANNUAL_REVIEW",
  CREATE_ANNUAL_REVIEW_COMPLETED = "CREATE_ANNUAL_REVIEW_COMPLETED",
}

export enum LoanAction {
  CREATE_LOAN_STARTED = "CREATE_LOAN_STARTED",
  CREATE_LOAN_ENDED = "CREATE_LOAN_ENDED",
  CREATE_LOAN = "CREATE_LOAN",
  CREATE_LOAN_COMPLETED = "CREATE_LOAN_COMPLETED",
  CREATE_LOAN_FAILED = "CREATE_LOAN_FAILED",
  UPDATE_LOAN = "UPDATE_LOAN",
  UPDATE_LOAN_COMPLETED = "UPDATE_LOAN_COMPLETED",
  UPDATE_LOAN_FAILED = "UPDATE_LOAN_FAILED",
  FETCH_DRAFT_LOANS = "FETCH_DRAFT_LOANS",
  FETCH_DRAFT_LOANS_COMPLETED = "FETCH_DRAFT_LOANS_COMPLETED",
  FETCH_DRAFT_LOANS_FAILED = "FETCH_DRAFT_LOANS_FAILED",
  DELETE_DRAFT_LOAN = "DELETE_DRAFT_LOAN",
  DELETE_DRAFT_LOAN_COMPLETED = "DELETE_DRAFT_LOAN_COMPLETED",
  DELETE_DRAFT_LOAN_FAILED = "DELETE_DRAFT_LOAN_FAILED",
  CLEAR_LOAN_DETAIL = "CLEAR_LOAN_DETAIL",
}

export enum ContactAction {
  FETCH_CONTACTS = "FETCH_CONTACTS",
  FETCH_CONTACTS_COMPLETED = "FETCH_CONTACTS_COMPLETED",
  FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR",

  FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID = "FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID",
  FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_COMPLETED = "FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_COMPLETED",
  FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_ERROR = "FETCH_CONTACTS_BY_ANNUAL_REVIEW_ID_ERROR",

  FETCH_CONTACTS_BY_LOAN_ID = "FETCH_CONTACTS_BY_LOAN_ID",
  FETCH_CONTACTS_BY_LOAN_ID_COMPLETED = "FETCH_CONTACTS_BY_LOAN_ID_COMPLETED",
  FETCH_CONTACTS_BY_LOAN_ID_ERROR = "FETCH_CONTACTS_BY_LOAN_ID_ERROR",

  FETCH_CONTACT = "FETCH_CONTACT",
  FETCH_CONTACT_COMPLETED = "FETCH_CONTACT_COMPLETED",
  FETCH_CONTACT_ERROR = "FETCH_CONTACT_ERROR",
}

export enum SpreadAction {
  RESET_CHOOSE_ASSETS = "RESET_CHOOSE_ASSETS_SPREAD_ACTION",
  LOAD_FROM_SLUG = "LOAD_FROM_SLUG_SPREAD_ACTION",
  CALCULATE_SPREAD = "CALCULATE_SPREAD_SPREAD_ACTION",

  SET_WORKFLOW = "SET_WORKFLOW_SPREAD_ACTION",

  HIDE_SHEET = "HIDE_SHEET_SPREAD_ACTION",
  SHOW_SHEET = "SHOW_SHEET_SPREAD_ACTION",

  ADD_ROW = "ADD_ROW_SPREAD_ACTION",
  REMOVE_ROW = "REMOVE_ROW_SPREAD_ACTION",

  UPDATE_CELL = "UPDATE_CELL_SPREAD_ACTION",

  SAVE_TO_DB = "SAVE_TO_DB_SPREAD_ACTION",
  FETCH_FROM_DB = "FETCH_FROM_DB_SPREAD_ACTION",
  FETCHED_FROM_DB = "FETCHED_FROM_DB_SPREAD_ACTION",

  SET_FORMULA_BAR = "SET_FORMULA_BAR_SPREAD_ACTION",
  BEGIN_EDITING_CELL = "BEGIN_EDITING_CELL_SPREAD_ACTION",
  STOP_EDITING = "STOP_EDITING_SPREAD_ACTION",

  SPREAD_ERROR = "SPREAD_ERROR_SPREAD_ACTION",
  SET_RENDERED_DOCS = "SET_RENDERED_DOCS_SPREAD_ACTION",

  GENERATE_SHEETS = "GENERATE_SHEETS_SPREAD_ACTION",
  SET_SHEETS = "SET_SHEETS_SPREAD_ACTION",

  SET_GRID_STATES = "SET_GRID_STATES_SPREAD_ACTION",
  SET_GRID_OPTIONS = "SET_GRID_OPTIONS_SPREAD_ACTION",
  SET_CURRENT_SPREAD_ID = "SET_CURRENT_SPREAD_ID_SPREAD_ACTION",

  SET_COLUMN_DEFS = "SET_COLUMN_DEFS_SPREAD_ACTION",

  SET_SUBJECT_ASSETS = "SET_SUBJECT_ASSETS_SPREAD_ACTION",

  LOAD_MANUAL_WORKFLOW = "LOAD_MANUAL_WORKFLOW_SPREAD_ACTION",

  SET_USER_STATE = "SET_USER_STATE_SPREAD_ACTION",

  SET_ANALYSIS_TYPE = "SET_ANALYSIS_TYPE_SPREAD_ACTION",

  RESET = "RESET_SPREAD_ACTION",
  SET_TAB_GROUPS = "SET_TAB_GROUPS_SPREAD_ACTION",
  SET_HOVER_INFOS = "SET_HOVER_INFOS",
  SET_CONFIDENCES = "SET_CONFIDENCES",
  SET_PROPERTY_GROUPS = "SET_PROPERTY_GROUPS_SPREAD_ACTION",
  SET_JOINTLY_OWNED_ASSETS = "SET_JOINTLY_OWNED_ASSETS_SPREAD_ACTION",
  SET_SELECTED_ASSETS = "SET_SELECTED_ASSETS_SPREAD_ACTION",
  SET_COL_SPANS = "SET_COL_SPANS_SPREAD_ACTION",
}

export enum OcrAction {
  START_OCR = "START_OCR",
  POLL_OCR_STATUS = "POLL_OCR_STATUS",
  UPDATE_OCR_DATA = "UPDATE_OCR_DATA",
  OCR_COMPLETED = "OCR_COMPLETED",
  OCR_ERROR = "OCR_ERROR",
  OCR_POLLING = "OCR_POLLING",
  OCR_POLLING_COMPLETED = "OCR_POLLING_COMPLETED",
  OCR_POLLING_ERROR = "OCR_POLLING_ERROR",
  SET_AVAILABLE_UPLOAD_IDS = "SET_AVAILABLE_UPLOAD_IDS",
  SET_SELECTED_UPLOAD_IDS = "SET_SELECTED_UPLOAD_IDS",
  SELECT_DOCUMENT_UPLOAD_ID = "SELECT_DOCUMENT_UPLOAD_ID",
  UNSELECT_DOCUMENT_UPLOAD_ID = "UNSELECT_DOCUMENT_UPLOAD_ID",
  RESET_OCR_DATA = "RESET_OCR_DATA",
}

export enum ReportingAction {
  GET_REPORTING_HEADERS = "GET_REPORTING_HEADERS",
  SET_REPORTING_HEADERS = "SET_REPORTING_HEADERS",
  GET_REPORTING_DATA_FOR_TABLE = "GET_REPORTING_DATA_FOR_TABLE",
  SET_REPORTING_DATA_FOR_TABLE = "SET_REPORTING_DATA_FOR_TABLE",
  RESET_IS_LOADING = "RESET_IS_LOADING",
  SET_REPORTING_ALL_DATA_LOADED = "SET_REPORTING_ALL_DATA_LOADED",
  GET_REPORTING_DATA_FOR_CSV = "GET_REPORTING_DATA_FOR_CSV",
  REPORTING_DATA_FOR_CSV_COMPLETED = "REPORTING_DATA_FOR_CSV_COMPLETED",
  GET_REPORTING_FILTERS = "GET_REPORTING_FILTERS",
  SET_REPORTING_FILTERS = "SET_REPORTING_FILTERS",
  UPDATE_REPORTING_FILTER = "UPDATE_REPORTING_FILTER",
  CREATE_REPORTING_FILTER = "CREATE_REPORTING_FILTER",
  DELETE_REPORTING_FILTER = "DELETE_REPORTING_FILTER",
}

export enum ManagementAction {
  GET_MANAGEMENT_DATA_FOR_TABLE = "GET_MANAGEMENT_DATA_FOR_TABLE",
  SET_MANAGEMENT_DATA_FOR_TABLE = "SET_MANAGEMENT_DATA_FOR_TABLE",
  GET_MANAGEMENT_DATA_FOR_CSV = "GET_MANAGEMENT_DATA_FOR_CSV",
  MANAGEMENT_DATA_FOR_CSV_COMPLETED = "MANAGEMENT_DATA_FOR_CSV_COMPLETED",
  SET_MANAGEMENT_SEARCH_PARAMS = "SET_MANAGEMENT_SEARCH_PARAMS",
}

export enum DocumentAndRemindersActions {
  FETCH_DOCUMENT_AND_REMINDERS = "FETCH_DOCUMENT_AND_REMINDERS",
  FETCH_DOCUMENT_AND_REMINDERS_COMPLETED = "FETCH_DOCUMENT_AND_REMINDERS_COMPLETED",
  FETCH_DOCUMENT_AND_REMINDERS_FAILED = "FETCH_DOCUMENT_AND_REMINDERS_FAILED",
}

export enum DocumentAction {
  DOWNLOAD_ALL_DOCUMENTS = "DOWNLOAD_ALL_DOCUMENTS",
  DOWNLOAD_ALL_DOCUMENTS_COMPLETED = "DOWNLOAD_ALL_DOCUMENTS_COMPLETED",
  DOWNLOAD_ALL_DOCUMENTS_FAILED = "DOWNLOAD_ALL_DOCUMENTS_FAILED",
  UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST",
  UPDATE_DOCUMENT_REQUEST_COMPLETED = "UPDATE_DOCUMENT_REQUEST_COMPLETED",
  UPDATE_DOCUMENT_REQUEST_FAILED = "UPDATE_DOCUMENT_REQUEST_FAILED",
}
