import { Rendered } from "src/classes/RenderedDoc";
import { RawCellContent } from "hyperformula";
import { GridState, RowId } from "src/classes/GridState";
import { TaxProperty } from "@interfold-ai/shared/models/tax/schedules/ScheduleE";
import { shortenPropertyName } from "src/classes/RenderedDocuments/utils";
import {
  ManyLoanCalculatorsData,
  ManyLoanCalculatorsRendered,
} from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { DSCRCalculatorData, DSCRCalculatorRendered } from "./DSCRCalculatorRendered";
import { SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig"
import { NOIAnalysisType } from "./Workflows/RenderedNOIAnalysis";
import { IncomeAssetParts } from "@interfold-ai/shared/models/Property";

export type YearWithPropertiesRowLabel =
  | "Gross Rents"
  | ""
  | "Net Income"
  | "Total Expenses"
  | "Interest"
  | "Depreciation"
  | "Amortization"
  | "NOI"
  | "Existing Debt"
  | "New Debt"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio"
  | "Excess/Deficit"
  | "Interest Rate";

export class NOIAnalysisYearRollup extends Rendered<TaxProperty[], YearWithPropertiesRowLabel> {
  constructor(
    public year: string,
    public properties: TaxProperty[],
    public yearColumn: number,
    public formatNameForPersonalTaxReturn: (name: string) => string,
    public lenderId: SupportedLenderId = 1,
    public noiAnalysisType: NOIAnalysisType = NOIAnalysisType.NEW_LOAN,
  ) {
    super(properties);
    this.underlying = properties;
  }

  get numberOfColumns(): number {
    return this.numberOfYears + 2;
  }

  get numberOfYears(): number {
    return Object.keys(this.properties).length;
  }

  getEndColumnLetter(): string {
    return this.colNumberToExcelCol(this.numberOfColumns);
  }

  getReferenceRowData(unused: any, rowOffset: number): RawCellContent[] {
    return this.properties.map((property) => {
      const propertyName = property?.propertyName || property?.propertyAddress || "";
      if (propertyName === "") {
        return "";
      }
      // Use same formatting method as defined in RenderedDocCreator to match tab names correctly
      const formattedPropertyName = this.formatNameForPersonalTaxReturn(propertyName as string);
      const reference = this.generateReference(formattedPropertyName, this.yearColumn, rowOffset);
      return `=${reference}`; // Construct reference formula
    });
  }

  generateTextPropertyArray(
    propertyName: keyof IncomeAssetParts,
    fallback?: keyof IncomeAssetParts,
  ): RawCellContent[] {
    return this.properties.map((property) => {
      return (
        (property[propertyName] as string) || (fallback && (property[fallback] as string)) || ""
      );
    });
  }

  get initialGridState(): GridState {
    if (this.numberOfColumns < 3) {
      return {};
    }
    const numCols = this.numberOfColumns;
    let index = 0;

    const mainPart = {
      // Row 1 - Source, e.g. "Rent Roll"
      source: {
        rowDataArray: ["Source", " ", ...this.getReferenceRowData("", 0)],
        rowDataType: "formula",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 2 - Entity
      entity: {
        rowDataArray: ["Entity", " ", ...this.getReferenceRowData("", 1)],
        rowDataType: "formula",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 3 - Property
      propertyName: {
        rowDataArray: [
          "Property",
          "Combined All Properties",
          ...this.generateTextPropertyArray("propertyName", "propertyAddress").map(
            shortenPropertyName,
          ),
        ],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 4 - Gross Rents
      grossRents: {
        rowDataArray: [
          "Gross Rents",
          `=SUM(C4:${this.getEndColumnLetter()}4)`,
          ...this.getReferenceRowData("grossRents", 3),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 5 - Total Expenses
      totalExpenses: {
        rowDataArray: [
          "Total Expenses",
          `=SUM(C5:${this.getEndColumnLetter()}5)`,
          ...this.getReferenceRowData("totalExpenses", 4),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 6 - Interest
      interest: {
        rowDataArray: [
          "Interest",
          `=SUM(C6:${this.getEndColumnLetter()}6)`,
          ...this.getReferenceRowData("interest", 5),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 7 - Depreciation
      depreciation: {
        rowDataArray: [
          "Depreciation",
          `=SUM(C7:${this.getEndColumnLetter()}7)`,
          ...this.getReferenceRowData("depreciation", 6),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 8 - Amortization
      amortization: {
        rowDataArray: [
          "Amortization",
          `=SUM(C8:${this.getEndColumnLetter()}8)`,
          ...this.getReferenceRowData("amortization", 7),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 9 - NOI (Net Income + Interest + Depreciation + Amortization)
      noi: {
        rowDataArray: [
          "Net Operating Income (NOI)",
          ...this.generateFormulaByColumnPattern(
            numCols,
            (colLabel: string) =>
              `=${colLabel}4-${colLabel}5+${colLabel}6+${colLabel}7+${colLabel}8`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 10 - Existing Debt (entered by user)
      existingDebt: {
        rowDataArray: [
          "Existing Debt",
          `=SUM(C10:${this.getEndColumnLetter()}10)`,
          ...this.generateRepeatedValues(numCols - 1, 0),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 11 - New Debt  = [NOI)] * 1.25 - [Existing Debt]
      newDebt: {
        rowDataArray: [
          "New Debt",
          this.noiAnalysisType === NOIAnalysisType.NEW_LOAN ? `=B9 / B13 - B10` : "0",
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 12 - Total Debt Service (Existing Debt + New Debt)
      totalDebtService: {
        rowDataArray: [
          "Total Debt Service",
          `=B10+B11`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
    } as GridState;
    if (this.noiAnalysisType === NOIAnalysisType.NEW_LOAN) {
      // Row 13 - Debt Service Coverage Ratio (NOI / Total Debt Service)
      mainPart["dscr" as RowId] = {
        rowDataArray: [
          "Debt Service Coverage Ratio",
          `1.25`,
          ...this.generateRepeatedValues(numCols - 1, " "),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      };
    }

    // row 13 or 14 - Excess/Deficit (NOI - Total Debt Service)
    mainPart["excessDeficit" as RowId] = {
      rowDataArray: ["Excess/Deficit", `=B9-B12`, ...this.generateRepeatedValues(numCols - 1, " ")],
      rowDataType: "number",
      rowStyle: "highlighted",
      isManagedByApp: true,
      index: index++,
    };

    if (this.noiAnalysisType === NOIAnalysisType.NEW_LOAN) {
      const excessDeficitRefs: string[] = this.generateFormulaByColumnPattern(
        numCols,
        (colLabel: string) => `${colLabel}${index}`,
      );
      const newDebtRefs: string[] = this.generateFormulaByColumnPattern(
        numCols,
        (colLabel: string) => `${colLabel}11`,
      );

      const manyCalcData: ManyLoanCalculatorsData = {
        excessCashflows: excessDeficitRefs,
        lenderId: this.lenderId,
        newDebt: newDebtRefs,
      };
      const manyLoanCalcs = new ManyLoanCalculatorsRendered(manyCalcData, index + 1, true);

      return {
        ...mainPart,
        ...manyLoanCalcs.initialGridState,
      };
    }

    const dscrCalculatorData: DSCRCalculatorData = {
      noiRow: 9,
      debtServiceRows: [12],
      startingColumn: "B",
      lenderId: this.lenderId,
      propertyColumnCount: this.numberOfYears,
    };
    const dscrCalculator = new DSCRCalculatorRendered(dscrCalculatorData, index + 1);
    return {
      // todo: add dscr calculator here
      ...mainPart,
      ...dscrCalculator.initialGridState,
    };
  }

  defaultRowLabels: YearWithPropertiesRowLabel[] = [
    "Gross Rents",
    "",
    "Net Income",
    "Interest",
    "Depreciation",
    "Amortization",
    "NOI",
    "Existing Debt",
    "New Debt",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  highlightedRowLabels: YearWithPropertiesRowLabel[] = [
    "NOI",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  percentageRowLabels: YearWithPropertiesRowLabel[] = ["Interest Rate"];
  metadataRowLabels: YearWithPropertiesRowLabel[] = [];
}
