import { MemoQuestionnaire } from "src/models/MemoQuestionnaire";
import { baseApiService } from "../api-services/BaseApiService";
import {
  CreateMemoQuestionnaireParams,
  GetMemoQuestionnaireAnswersParams,
  MemoQuestionnaireAnswers,
  SaveMemoQuestionnaireAnswersParams,
} from "src/redux/slices/memoQuestionnaireSlice";

export class MemoQuestionnaireService {
  static getInstance(): MemoQuestionnaireService {
    return new MemoQuestionnaireService();
  }

  async getMemoQuestionnairesByLenderId(): Promise<MemoQuestionnaire[]> {
    const response = await baseApiService.get(`/api/settings/memo-questionnaire`);
    if (!response) {
      return [];
    }
    return response.map((memoQuestionnaire: any) => memoQuestionnaire);
  }

  async saveMemoQuestionnaire(
    params: CreateMemoQuestionnaireParams,
  ): Promise<MemoQuestionnaire | undefined> {
    const memoQuestionnaire = await baseApiService.post(`/api/settings/memo-questionnaire`, params);

    if (!memoQuestionnaire) {
      return;
    }

    return memoQuestionnaire;
  }

  async saveMemoQuestionnaireAnswers(
    params: SaveMemoQuestionnaireAnswersParams,
  ): Promise<MemoQuestionnaireAnswers | undefined> {
    const response = await baseApiService.post(`/api/settings/memo-questionnaire/answers`, params);

    if (!response) {
      return;
    }

    return response;
  }

  async getMemoQuestionnaireAnswers(
    params: GetMemoQuestionnaireAnswersParams,
  ): Promise<MemoQuestionnaireAnswers[] | undefined> {
    const response = await baseApiService.get(`/api/settings/memo-questionnaire/answers`, {
      params: {
        loanId: params.loanId.toString(),
      },
    });

    if (!response) {
      return;
    }

    return response;
  }
}
