import { SpreadableProperty } from "src/redux/selectors/ocr.selector";
import { CanonicalRentRoll } from "@interfold-ai/shared/models/Property";

export function spreadablePropertyLabel(property: SpreadableProperty): string {
  const ideal =
    (property as CanonicalRentRoll)?.streetAddressWithUnitOrSuite ??
    `${(property as CanonicalRentRoll).streetAddress ?? ""} ${(property as CanonicalRentRoll).unitOrSuite ?? ""}`.trim();
  if (ideal.length > 0 && ideal !== "undefined") {
    return ideal;
  } else {
    // This should never happen, we should make sure property label is always there
    throw new Error("No property label found");
  }
}
