import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { Store } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer, { AppState } from "./reducers";
import rootSaga from "./sagas";

const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
  });
  sagaMiddleware.run(rootSaga);

  return store;
};

/*

    middleware: ,
    enhancers: [enhancer],
    */

// export an assembled wrapper
export default createWrapper<Store<AppState>>(makeStore);
