import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { RowWithType } from "src/classes/RowWithType";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import {
  buildForm1120sCashFlow,
  Form1120sCashFlowRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlow";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { HoverInfo } from "src/classes/RenderedDoc";

export class Form1120sCashFlowGrouped extends AutoRendered<RenderableWithConfidence<Form1120S>[]> {
  private rendered: AutoRendered<Form1120S>[] = [];
  constructor(data: RenderableWithConfidence<Form1120S>[]) {
    super(data);
    this.process();
  }

  private process() {
    this.underlying
      .sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year))
      .forEach((form, i) => {
        const startingColumn = this.getColumnLetter(i);
        const singleRendered = buildForm1120sCashFlow(form, startingColumn, 1);
        this.rendered.push(singleRendered);
      });

    this.setConfidenceBody();
    this.setHoverInfos();
  }

  private setConfidenceBody(): void {
    const header = [0, ...Array(this.rendered.length).fill(null)];
    const body: RawConfidenceContent[][] = [];
    this.rendered.forEach((rendered, i) => {
      const singleRendered = rendered.asConfidence();
      singleRendered.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });
    this.confidenceBody = [header, ...body];
  }

  private setHoverInfos(): void {
    const header = [0, ...Array(this.rendered.length).fill(null)];
    const hoverInfos: HoverInfo[][] = [];
    this.rendered.forEach((rendered, i) => {
      const singleRendered = rendered.hoverInfos;
      singleRendered.forEach((row, rowIndex) => {
        if (!hoverInfos[rowIndex]) {
          hoverInfos[rowIndex] = [row[0]];
        }
        hoverInfos[rowIndex].push(row[1]);
      });
    });
    this.hoverInfos = [header, ...hoverInfos];
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asColumns(): RawCellContent[][] {
    const header = autoRenderedTextRow(["Year", ...this.underlying.map((r) => r.renderable.year)]);
    const body: RawCellContent[][] = [];

    this.rendered.forEach((singleRendered, i) => {
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1] ?? "");
      });
    });
    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    return [
      "Year",
      Form1120sCashFlowRowHeadings.BusinessCashFlowBeforeTax,
      Form1120sCashFlowRowHeadings.BusinessCashFlowAfterTax,
      Form1120sCashFlowRowHeadings.BusinessCashFlowAvailableForDebtService,
      Form1120sCashFlowRowHeadings.DebtService,
      Form1120sCashFlowRowHeadings.StressScenarioDebtService,
    ];
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  metadataRowLabels: string[] = [];

  public indexOfHeader(header: keyof typeof Form1120sCashFlowRowHeadings): number {
    const heading = Form1120sCashFlowRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
