import { Reducer } from "redux";
import { type CellValue, HyperFormula, type RawCellContent } from "hyperformula";
import { SpreadAction } from "../actions/actions.constants";
import {
  AnalysisType,
  ManualWorkFlowEnum,
} from "@interfold-ai/shared/models/SpreadsTabConstants";
import {
  PropertyGroups,
  NOIAnalysisProps,
  SheetIndex,
  TabName,
  GroupName,
  JointlyOwnedAssets,
} from "./types";
import { columnIdToIndex, GridState, GridStateOperations } from "src/classes/GridState";
import type { ColDef } from "ag-grid-community";
import { UpdateCell } from "../actions/spread.action";
import { ManualWorkflowFactory } from "src/page-components/investment-detail/tabs/SpreadsTab/manual-flows";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";;
import { GenericKVTable } from "src/classes/RenderedDocuments/GenericKV";
import {
  NOIAnalysisType,
  RenderedNOIAnalysis,
} from "src/classes/RenderedDocuments/Workflows/RenderedNOIAnalysis";
import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { PersonalCashFlowRendered } from "src/classes/RenderedDocuments/PersonalCashFlowRendered";
import {
  GeneralSpreadsData,
  RenderedGeneralSpreads,
} from "src/classes/RenderedDocuments/Workflows/RenderedGeneralSpreads";
import {
  PersonalCashFlowAdapter,
  PersonalCashFlowDataWithName,
} from "@interfold-ai/shared/extractors/strategy/PersonalCashflowAdapter";
import { GenericPDFTable, TableWithContext } from "src/classes/RenderedDocuments/GenericPDF";
import { HoverInfo, Rendered, RenderedDocOptions } from "src/classes/RenderedDoc";
import { unSlugNormalized } from "src/utils/helpers";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { TabGroup } from "src/classes/RenderedDocuments/helpers";
import { Table } from "@interfold-ai/shared/models/extract/table";
import { IncomeAssetParts } from "@interfold-ai/shared/models/Property";
import { INDIVIDUAL_PROPERTIES } from "../selectors/spread.selector";

export enum SPREAD_USER_STATE {
  NOT_LOADED = "NOT_LOADED",
  CHOOSING_FILES = "CHOOSING_FILES",
  WAITING_FOR_OCR = "WAITING_FOR_OCR",
  CHOOSING_SUBJECT = "CHOOSING_SUBJECT",
  CHOOSING_ASSETS = "CHOOSING_ASSETS",
  GROUPING_ASSETS = "GROUPING_ASSETS",
  SPREADSHEET = "SPREADSHEET",
  JOINT_OWNERSHIP = "JOINT_OWNERSHIP",
}

export interface SpreadState {
  gridStates: Record<TabName, GridState> | null;
  gridOptions: Record<TabName, RenderedDocOptions>;
  rawSheets: Record<TabName, RawCellContent[][]>;
  confidences: Record<TabName, RawConfidenceContent[][]>;
  calculatedSheets: Record<TabName, CellValue[][]>;
  formulaBar: string;
  assets: IncomeAssetParts[];
  sheetVisibility: Record<TabName, boolean>;
  columnsDefs: Record<TabName, ColDef[]>;
  editingCell: false | { sheetIndex: SheetIndex; rowIndex: number; colIndex: number };
  currentSpreadId: number | null;
  subjectAssets: IncomeAssetParts[] | null;
  userState: SPREAD_USER_STATE;
  analysisType: AnalysisType;
  hoverInfos: Record<TabName, HoverInfo[][]>;
  tabGroups: TabGroup[];
  propertyGroups: PropertyGroups;
  selectedAssets: string[];
  jointlyOwnedAssets: JointlyOwnedAssets[];
  colSpans: Record<TabName, Record<string, number>>;
}

export const initialSpreadState: SpreadState = {
  gridStates: null,
  gridOptions: {},
  formulaBar: "",
  assets: [],
  sheetVisibility: {} as Record<SheetIndex, boolean>,
  editingCell: false,
  rawSheets: {} as Record<TabName, RawCellContent[][]>,
  columnsDefs: {} as Record<TabName, ColDef[]>,
  calculatedSheets: {} as Record<TabName, CellValue[][]>,
  confidences: {} as Record<TabName, number[][]>,
  currentSpreadId: null,
  subjectAssets: null,
  userState: SPREAD_USER_STATE.NOT_LOADED,
  analysisType: AnalysisType.EMPTY,
  tabGroups: [],
  hoverInfos: {},
  propertyGroups: {},
  selectedAssets: [],
  colSpans: {},
  jointlyOwnedAssets: [],
};

type SpreadActionType =
  | ReturnType<typeof UpdateCell>
  | { type: SpreadAction.CALCULATE_SPREAD; payload: NOIAnalysisProps | Record<string, Table> }
  | { type: SpreadAction.GENERATE_SHEETS }
  | {
    type: SpreadAction.FETCH_FROM_DB;
    payload: { annualReviewId: number | null; loanId: number | null };
  }
  | { type: SpreadAction.SET_WORKFLOW; payload: AnalysisType }
  | { type: SpreadAction.SET_GRID_STATES; payload: Record<TabName, GridState> }
  | { type: SpreadAction.SET_GRID_OPTIONS; payload: Record<TabName, RenderedDocOptions> }
  | {
    type: SpreadAction.ADD_ROW;
    payload: { rowIndex: number; where: "above" | "below"; docName: TabName };
  }
  | { type: SpreadAction.REMOVE_ROW; payload: { rowIndex: number; docName: TabName } }
  | {
    type: SpreadAction.UPDATE_CELL;
    payload: { rowIndex: number; colName: string; docName: TabName };
  }
  | { type: SpreadAction.FETCHED_FROM_DB; payload: Record<TabName, GridState> }
  | { type: SpreadAction.SET_FORMULA_BAR; payload: string }
  | {
    type: SpreadAction.BEGIN_EDITING_CELL;
    payload: { sheetIndex: SheetIndex; rowIndex: number; colIndex: number };
  }
  | { type: SpreadAction.STOP_EDITING }
  | {
    type: SpreadAction.FETCH_FROM_DB;
    payload: { annualReviewId: number | null; loanId: number | null };
  }
  | { type: SpreadAction.SPREAD_ERROR }
  | { type: SpreadAction.SET_RENDERED_DOCS; payload: Record<TabName, string> }
  | { type: SpreadAction.SET_CURRENT_SPREAD_ID; payload: number }
  | { type: SpreadAction.SET_COLUMN_DEFS; payload: Record<TabName, ColDef[]> }
  | {
    type: SpreadAction.SET_SUBJECT_ASSETS;
    payload:
    | IncomeAssetParts[]
    | { type: SpreadAction.LOAD_MANUAL_WORKFLOW; payload: ManualWorkFlowEnum }
    | { type: SpreadAction.SET_CURRENT_SPREAD_ID; payload: number };
  }
  | {
    type: SpreadAction.SET_USER_STATE;
    payload: SPREAD_USER_STATE;
  }
  | {
    type: SpreadAction.SET_ANALYSIS_TYPE;
    payload: AnalysisType;
  }
  | {
    type: SpreadAction.SET_WORKFLOW;
    payload: AnalysisType;
  }
  | { type: SpreadAction.SET_SELECTED_ASSETS; payload: string[] };

export const spreadReducer: Reducer<SpreadState> = (
  state: SpreadState = initialSpreadState,
  action,
) => {
  const spreadAction = action as SpreadActionType;
  switch (spreadAction.type) {
    case SpreadAction.RESET:
      return {
        ...state,
        gridStates: null,
        columnsDefs: {},
        rawSheets: {},
        calculatedSheets: {},
        assets: [],
        subjectAssets: null,
        currentSpreadId: null,
        userState: SPREAD_USER_STATE.NOT_LOADED,
        tabGroups: [],
        hoverInfos: {},
        propertyGroups: {},
        selectedAssets: [],
        jointlyOwnedAssets: [],
      };
    case SpreadAction.RESET_CHOOSE_ASSETS:
      return {
        ...state,
        gridStates: null,
        columnsDefs: {},
        rawSheets: {},
        calculatedSheets: {},
        assets: [],
        subjectAssets: null,
        propertyGroups: {},
        selectedAssets: [],
        jointlyOwnedAssets: [],
      };
    case SpreadAction.SET_ANALYSIS_TYPE:
      return {
        ...state,
        analysisType: action.payload as AnalysisType,
      };
    case SpreadAction.SET_USER_STATE:
      return {
        ...state,
        userState: action.payload as SPREAD_USER_STATE,
      };
    case SpreadAction.CALCULATE_SPREAD:
      const workload = state.analysisType;
      switch (workload) {
        // todo: come back and differentiate between new loan and portfolio management
        case AnalysisType.NOI_ANALYSIS_NEW_LOAN:
        case AnalysisType.NOI_ANALYSIS_PORTFOLIO_MANAGEMENT:
          const historicalData = action.payload as NOIAnalysisProps;
          const noiAnalysisType =
            state.analysisType === AnalysisType.NOI_ANALYSIS_NEW_LOAN
              ? NOIAnalysisType.NEW_LOAN
              : NOIAnalysisType.PORTFOLIO_MANAGEMENT;
          const analysis = new RenderedNOIAnalysis(historicalData, noiAnalysisType);
          const tabSources = analysis.getDocs();
          const gridStates = {} as Record<TabName, GridState>;
          const gridOptions = {} as Record<TabName, RenderedDocOptions>;
          const columnsDefs = {} as Record<TabName, ColDef[]>;
          const hoverInfos = {} as Record<TabName, HoverInfo[][]>;
          const colSpans = {} as Record<TabName, Record<string, number>>;
          const tabGroups: TabGroup[] = Array.from(analysis.tabGroups);

          const userState = SPREAD_USER_STATE.SPREADSHEET;

          Object.entries(tabSources).forEach(([tabName, doc], _index) => {
            gridStates[tabName as TabName] = doc.gridState;
            gridOptions[tabName as TabName] = doc.gridOptions;
            columnsDefs[tabName as TabName] = doc.columnDefs;
            hoverInfos[tabName as TabName] = doc.hoverInfos;
            colSpans[tabName as TabName] = doc.colSpans;
          });

          return {
            ...state,
            userState,
            gridStates,
            gridOptions,
            columnsDefs,
            tabGroups,
            hoverInfos,
            colSpans,
          };
        case AnalysisType.PERSONAL_CASH_FLOW:
          const ptrPayload = action.payload as Record<string, ExtractOutput>;
          const firstItem = ptrPayload[Object.keys(ptrPayload)[0]];
          const output = firstItem.output as IncomeAssetParts;
          const cashFlowData = output as Partial<PersonalCashFlowDataWithName>;
          const form1040: Form1040 = new PersonalCashFlowAdapter().adapt(
            cashFlowData as PersonalCashFlowDataWithName,
          );
          const tabName = (form1040.taxpayer?.firstName +
            " " +
            form1040.taxpayer?.lastName) as TabName;
          const renderedPtr = new PersonalCashFlowRendered(form1040);
          const sheets = GridStateOperations.generateSheets({
            [tabName]: renderedPtr.initialGridState,
          });
          return {
            ...state,
            userState: SPREAD_USER_STATE.SPREADSHEET,
            gridStates: {
              [tabName]: renderedPtr.initialGridState,
            },
            columnsDefs: {
              [tabName]: renderedPtr.columnDefs,
            },
            rawSheets: sheets.rawSheetData,
            calculatedSheets: sheets.calculatedSheetData,
          };
        case AnalysisType.EXTRACT_TABLES: {
          const payload = action.payload as Record<string, ExtractOutput>;
          const pairs = Object.entries(payload);

          const data = pairs.reduce(
            (acc, pair, currentPair) => {
              const [, extractOutput] = pair;
              const { source, output } = extractOutput;
              const normalized = unSlugNormalized(source);

              const tables = output as TableWithContext[];
              const tabs = tables.reduce(
                (acc, table, index) => {
                  const tabName = `${normalized} ${currentPair + 1} Table ${index + 1}`;
                  const context = table.context;
                  const pdfTable = new GenericPDFTable(table);
                  return {
                    ...acc,
                    [tabName]: pdfTable,
                  };
                },
                {} as Record<TabName, Rendered<any, string>>,
              );

              const columnsDefs = Object.fromEntries(
                Object.entries(tabs).map(([tabName, pdfTable]) => {
                  const ret = [tabName, pdfTable.columnDefs];
                  return ret;
                }),
              );
              const gridStates = Object.fromEntries(
                Object.entries(tabs).map(([tabName, pdfTable]) => [
                  tabName,
                  pdfTable.initialGridState,
                ]),
              );
              const gridOptions = Object.fromEntries(
                Object.entries(tabs).map(([tabName, pdfTable]) => [
                  tabName,
                  pdfTable.gridOptions,
                ]),
              );
              const sheets = GridStateOperations.generateSheets(gridStates);
              return {
                columnsDefs: { ...acc.columnsDefs, ...columnsDefs },
                sheets: {
                  rawSheetData: { ...acc.sheets?.rawSheetData, ...sheets.rawSheetData },
                  calculatedSheetData: {
                    ...acc.sheets?.calculatedSheetData,
                    ...sheets.calculatedSheetData,
                  },
                },
                gridStates: { ...acc.gridStates, ...gridStates },
                gridOptions: { ...acc.gridOptions, ...gridOptions },
              };
            },
            {} as {
              gridStates: Record<TabName, GridState>;
              gridOptions: Record<TabName, RenderedDocOptions>;
              columnsDefs: Record<TabName, ColDef[]>;
              sheets: {
                rawSheetData: Record<TabName, RawCellContent[][]>;
                calculatedSheetData: Record<TabName, CellValue[][]>;
              };
            },
          );

          const { columnsDefs, gridStates, sheets, gridOptions } = data;

          return {
            ...state,
            userState: SPREAD_USER_STATE.SPREADSHEET,
            gridStates,
            gridOptions,
            columnsDefs,
            rawSheets: sheets.rawSheetData,
            calculatedSheets: sheets.calculatedSheetData as Record<TabName, CellValue[][]>,
          };
        }
        case AnalysisType.EXTRACT_KEY_VALUES: {
          const payload = action.payload as Record<string, ExtractOutput>;
          const pair = Object.entries(payload)[0];
          const [_documentUploadId, extractOutput] = pair;
          const { output } = extractOutput;

          const pdfTable = new GenericKVTable(output);

          const tabName = "Generic" as TabName;

          const genericColDefs = { [tabName]: pdfTable.columnDefs };
          const genericGridStates = { [tabName]: pdfTable.initialGridState };
          const genericGridOptions = { [tabName]: pdfTable.gridOptions };
          const sheets = {
            rawSheetData: { [tabName]: pdfTable.asColumns() },
            calculatedSheetData: {
              [tabName]: pdfTable.asColumns().map((row) => row.map((cell) => cell ?? "")),
            },
          };

          return {
            ...state,
            userState: SPREAD_USER_STATE.SPREADSHEET,
            gridStates: genericGridStates,
            gridOptions: genericGridOptions,
            columnsDefs: genericColDefs,
            rawSheets: sheets.rawSheetData,
            calculatedSheets: sheets.calculatedSheetData as Record<TabName, CellValue[][]>,
          };
        }
        case AnalysisType.GENERAL_SPREADS: {
          const generalSpreadsData = action.payload as GeneralSpreadsData;
          let generalSpreads = new RenderedGeneralSpreads(generalSpreadsData);
          const generalSpreadGridState: Record<TabName, GridState> = Object.fromEntries(
            generalSpreads.gridStates,
          );
          const generalSpreadGridOptions: Record<TabName, RenderedDocOptions> = Object.fromEntries(
            generalSpreads.gridOptions,
          );
          const generalSpreadsConfidences = Object.fromEntries(generalSpreads.confidences);
          const generalSpreadColDefs = Object.fromEntries(generalSpreads.colDefs);
          const generalSpreadTabGroups = Array.from(generalSpreads.tabGroups);
          const hoverInfoData = Object.fromEntries(generalSpreads.hoverInfoData.entries());

          return {
            ...state,
            userState: SPREAD_USER_STATE.SPREADSHEET,
            gridStates: generalSpreadGridState,
            columnsDefs: generalSpreadColDefs,
            tabGroups: generalSpreadTabGroups,
            hoverInfos: hoverInfoData,
            confidences: generalSpreadsConfidences,
            gridOptions: generalSpreadGridOptions,
          };
        }
      }

    case SpreadAction.GENERATE_SHEETS:
      if (!state.gridStates) {
        return state;
      }
      const newHf = HyperFormula.buildEmpty({
        licenseKey: "gpl-v3",
        evaluateNullToZero: true,
      });

      const cellData: Record<TabName, RawCellContent[][]> = {};
      if (!state.gridStates || Object.values(state.gridStates).length === 0) {
        return state;
      }

      const genGridState = {} as Record<TabName, GridState>;
      const reOrdered = GridStateOperations.reOrderGridStates(state.gridStates, state.columnsDefs);
      Object.entries(reOrdered).forEach(([tabName, gridState], _index) => {
        cellData[tabName as TabName] = Object.entries(gridState).map(([, row]) => {
          return row.rowDataArray;
        });
        genGridState[tabName as TabName] = gridState;
      });

      Object.entries(cellData).forEach(([sheetName, sheet]) => {
        const sheetAlreadyExists = newHf.doesSheetExist(sheetName);
        if (sheetAlreadyExists) {
          const sheetId = newHf.getSheetId(sheetName) as number;
          newHf.setSheetContent(sheetId, sheet);
        } else {
          const givenName = newHf.addSheet(sheetName);
          const newId = newHf.getSheetId(givenName);
          if (newId == null) {
            throw new Error("Sheet ID is null, this should never happen.");
          }
          newHf.setSheetContent(newId, sheet);
        }
      });
      newHf.rebuildAndRecalculate();
      const rawSheets = newHf.getAllSheetsSerialized();
      const calculatedSheets = newHf.getAllSheetsValues();

      return {
        ...state,
        rawSheets,
        calculatedSheets,
        gridStates: genGridState,
      };

    case SpreadAction.FETCH_FROM_DB:
      const toFetch = action.payload;
      return {
        ...state,
        rawSheets: {},
        calculatedSheets: {},
        gridStates: {},
        ...(toFetch as { annualReviewId: number | null; loanId: number | null }),
      };

    case SpreadAction.SET_WORKFLOW:
      return {
        ...state,
        analysisType: action.payload as AnalysisType,
      };

    case SpreadAction.LOAD_MANUAL_WORKFLOW:
      const manualWorkflow = action.payload as ManualWorkFlowEnum;
      const manualWorkflowFactory = new ManualWorkflowFactory(manualWorkflow);
      const gridState = manualWorkflowFactory.asGridState();
      const manualColDefs = manualWorkflowFactory.asColDefs();
      return {
        ...state,
        gridStates: gridState,
        columnsDefs: manualColDefs,
      };

    case SpreadAction.SET_GRID_STATES:
      return {
        ...state,
        gridStates: action.payload as Record<TabName, GridState>,
      };

    case SpreadAction.SET_GRID_OPTIONS:
      return {
        ...state,
        gridOptions: action.payload as Record<TabName, RenderedDocOptions>,
      };

    case SpreadAction.SET_TAB_GROUPS:
      return {
        ...state,
        tabGroups: action.payload as TabGroup[],
      };

    case SpreadAction.SET_HOVER_INFOS:
      return {
        ...state,
        hoverInfos: action.payload as Record<TabName, HoverInfo[][]>,
      };

    case SpreadAction.ADD_ROW:
      const {
        rowIndex: rowIndexAR,
        where,
        docName: docNameAR,
      } = action.payload as {
        rowIndex: number;
        where: "above" | "below";
        docName: TabName;
      };
      if (!state.gridStates) {
        return state;
      }

      const gridStateToAddRow = state.gridStates[docNameAR];

      if (!gridStateToAddRow) {
        return state;
      }

      const width = Object.values(gridStateToAddRow)[0].rowDataArray.length;
      const emptyRow = Array.from({ length: width }, () => "");
      const colDefs = state.columnsDefs || {};
      const gridStatesWithRowAdded = GridStateOperations.addRow(
        state.gridStates,
        docNameAR,
        rowIndexAR,
        where,
        emptyRow,
        state.rawSheets,
        colDefs,
      );

      return {
        ...state,
        gridStates: gridStatesWithRowAdded,
      };

    case SpreadAction.REMOVE_ROW:
      return state;

    case SpreadAction.UPDATE_CELL:
      const {
        rowIndex,
        colName,
        docName: docNameUC,
      } = action.payload as ReturnType<typeof UpdateCell>["payload"];
      const gridStateToUpdate = { ...state.gridStates?.[docNameUC] };
      const originalState = { ...state };
      if (!state.gridStates || !gridStateToUpdate) {
        return state;
      }
      const newVal = state.formulaBar;

      const colIndex = columnIdToIndex(colName);
      const { newGridStates } = GridStateOperations.updateCell(
        { ...originalState.gridStates },
        docNameUC,
        rowIndex,
        colIndex,
        newVal,
        state.rawSheets,
        state.columnsDefs,
      );
      return {
        ...state,
        gridStates: newGridStates,
      };
    case SpreadAction.FETCHED_FROM_DB:
      const fetchedGridStates: Record<TabName, GridState> = action.payload as Record<
        TabName,
        GridState
      >;
      if (fetchedGridStates == null) {
        return state;
      }
      return {
        ...state,
        fetchedGridStates,
      };

    case SpreadAction.SET_FORMULA_BAR:
      return {
        ...state,
        formulaBar: action.payload as string,
      };

    case SpreadAction.BEGIN_EDITING_CELL:
      return {
        ...state,
        editingCell: action.payload as {
          sheetIndex: SheetIndex;
          rowIndex: number;
          colIndex: number;
        },
      };

    case SpreadAction.STOP_EDITING:
      return {
        ...state,
        editingCell: false,
      };

    case SpreadAction.SPREAD_ERROR:
      return state;

    case SpreadAction.SET_RENDERED_DOCS:
      return {
        ...state,
        renderedDocs: action.payload,
      };

    case SpreadAction.SET_CURRENT_SPREAD_ID:
      return {
        ...state,
        currentSpreadId: action.payload as number,
      };

    case SpreadAction.SET_COLUMN_DEFS:
      return {
        ...state,
        columnsDefs: action.payload as Record<TabName, ColDef[]>,
      };

    case SpreadAction.SET_SUBJECT_ASSETS:
      return {
        ...state,
        subjectAssets: action.payload as IncomeAssetParts[],
      };

    case SpreadAction.SET_CONFIDENCES:
      return {
        ...state,
        confidences: action.payload as Record<TabName, RawConfidenceContent[][]>,
      };

    case SpreadAction.SET_PROPERTY_GROUPS:
      return {
        ...state,
        propertyGroups: action.payload as PropertyGroups,
      };

    case SpreadAction.SET_JOINTLY_OWNED_ASSETS:
      return {
        ...state,
        jointlyOwnedAssets: action.payload as JointlyOwnedAssets[],
      };

    case SpreadAction.SET_COL_SPANS:
      return {
        ...state,
        colSpans: action.payload as Record<TabName, Record<string, number>>,
      };

    case SpreadAction.SET_SELECTED_ASSETS:
      const selectedAssets = action.payload as string[];

      const newPropertyGroups = Object.entries(state.propertyGroups).reduce((acc, [key, group]) => {
        if (key === INDIVIDUAL_PROPERTIES) {
          return acc; // We'll handle this separately
        }

        const newProperties = group.properties.filter((property) =>
          selectedAssets.includes(property),
        );
        acc[key as GroupName] = { ...group, properties: newProperties };
        return acc;
      }, {} as PropertyGroups);

      // Handle INDIVIDUAL_PROPERTIES
      const removedProperties = Object.values(state.propertyGroups).flatMap((group) =>
        group.properties.filter((property) => !selectedAssets.includes(property)),
      );

      newPropertyGroups[INDIVIDUAL_PROPERTIES] = {
        properties: Array.from(
          new Set([
            ...(state.propertyGroups[INDIVIDUAL_PROPERTIES]?.properties || []),
            ...removedProperties,
          ]),
        ),
        index: -1, // No need to order Individual Properties
      };

      return {
        ...state,
        propertyGroups: newPropertyGroups,
        selectedAssets,
      };

    default:
      return state;
  }
};
