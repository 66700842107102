import { RowWithType } from "src/classes/RowWithType";
import { columnIdToIndex, columnIndexToId } from "src/classes/GridState";
import { AutoRenderedSheetBuilder } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilder";
import { Form1040Grouped } from "src/classes/RenderedDocuments/Form1040/Form1040Grouped";
import { Form1065CashFlowGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065CashFlowGrouped";
import { Form1120sCashFlowGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlowGrouped";
import { TaxFormYear } from "@interfold-ai/shared/models/tax/common";
import { sanitizeTabName } from "../utils";

export const GlobalCashFlowRowHeadings = {
  BusinessCashFlowAvailable: "Business Cash Flow Available for Debt Service",
  LessProjectedDebtService: "Less: Projected Debt Service",
  CashFlowSurplusDeficit: "Cash Flow Surplus / (Deficit)",
  DSCR: "DSCR",
  GlobalCashFlow: "Global Cash Flow",
  TotalNetCash: "Total Net Cash Available for Debt Service",
  TotalDebtService: "Total Debt Service",
  GlobalSurplusDeficit: "Global Surplus / (Deficit)",
  GlobalDSCR: "Global DSCR",
  IndividualCashFlowAvailable: "Net Cash Available for Debt Service",
  LessTotalDebtService: "Less: Total Debt Service",
};

export interface Form1120sCashFlowAndTabName {
  tabName: string;
  cashFlow: Form1120sCashFlowGrouped;
}

export interface Form1065CashFlowAndTabName {
  tabName: string;
  cashFlow: Form1065CashFlowGrouped;
}

export interface Form1040RollUpAndTabName {
  tabName: string;
  cashFlow: Form1040Grouped;
  entityName: string;
}

interface EntityCashFlowData {
  entityName: string;
  cashAvailableForDebt: RowWithType;
  projectedDebtService: RowWithType;
}

export function buildGlobalCashFlow(
  form1120sCashFlows: Form1120sCashFlowAndTabName[] = [],
  form1065CashFlows: Form1065CashFlowAndTabName[] = [],
  form1040RollUps: Form1040RollUpAndTabName[] = [],
  startingColumn: string = "B",
  startingRow: number = 0,
) {
  const builder = new AutoRenderedSheetBuilder(
    {},
    GlobalCashFlowRowHeadings,
    startingRow,
    startingColumn,
  );
  const yearRange = getYearRange();
  const empties = Array.from({ length: yearRange.length }, () => "");
  const entityCashFlowData: EntityCashFlowData[] = [];

  builder.addRow(() => ["As of:", ...yearRange], "text", "highlighted");
  handleForm1120s();
  handleForm1065();
  handleForm1040();
  handleSummary();

  return builder;

  function getYearRange(): TaxFormYear[] {
    const uniqueYears = [
      ...new Set(
        [
          form1120sCashFlows.flatMap((f) =>
            f.cashFlow.underlying.map((u) => parseInt(u.renderable.year)),
          ),
          form1065CashFlows.flatMap((f) => f.cashFlow.underlying.map((u) => parseInt(u.year))),
          form1040RollUps.flatMap((f) =>
            f.cashFlow.underlying.map((u) => parseInt(u.wc.renderable.year)),
          ),
        ].flat(),
      ),
    ];
    if (uniqueYears.length === 0) {
      return [];
    }
    const minYear = Math.min(...uniqueYears);
    const maxYear = Math.max(...uniqueYears);
    return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i).map(
      (y) => y.toString() as TaxFormYear,
    );
  }

  function handleForm1120s() {
    for (const { tabName, cashFlow } of form1120sCashFlows) {
      const entityName = cashFlow.underlying[0].renderable.corporation?.name ?? "S Corp";
      builder.addRow(() => [entityName, ...empties], undefined, "highlighted");
      const cashAvailableForDebt: RowWithType = [
        GlobalCashFlowRowHeadings.BusinessCashFlowAvailable,
      ];
      const projectedDebtService: RowWithType = [
        GlobalCashFlowRowHeadings.LessProjectedDebtService,
      ];
      const surplusOrDeficit = [GlobalCashFlowRowHeadings.CashFlowSurplusDeficit];
      const dscr: RowWithType = [GlobalCashFlowRowHeadings.DSCR];
      const tab = `'${sanitizeTabName(tabName)}'!`;

      let currentColumn = startingColumn;
      const cashFlowRowIndex = cashFlow.indexOfHeader("BusinessCashFlowAvailableForDebtService");
      const projectedDebtServiceRowIndex = cashFlow.indexOfHeader("ProjectedDebtService");

      for (const year of yearRange) {
        const form = cashFlow.underlying.find((u) => u.renderable.year === year);
        if (!form) {
          cashAvailableForDebt.push("");
          projectedDebtService.push("");
          surplusOrDeficit.push("");
          dscr.push("");
          continue;
        }
        cashAvailableForDebt.push(`=${tab}${currentColumn}${cashFlowRowIndex}`);
        projectedDebtService.push(`=${tab}${currentColumn}${projectedDebtServiceRowIndex}`);
        surplusOrDeficit.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} - ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        dscr.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} / ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        currentColumn = nextColumn(currentColumn);
      }

      entityCashFlowData.push({ entityName, cashAvailableForDebt, projectedDebtService });

      builder.addRow(() => cashAvailableForDebt);
      builder.addRow(() => projectedDebtService);
      builder.addRow(() => surplusOrDeficit);
      builder.addRow(() => dscr);
      builder.addRow(() => [""]);
    }
  }

  function handleForm1065() {
    for (const { tabName, cashFlow } of form1065CashFlows) {
      const entityName = cashFlow.underlying[0].partnership?.name ?? "Partnership";
      builder.addRow(() => [entityName, ...empties], undefined, "highlighted");
      const cashAvailableForDebt: RowWithType = [
        GlobalCashFlowRowHeadings.BusinessCashFlowAvailable,
      ];
      const projectedDebtService: RowWithType = [
        GlobalCashFlowRowHeadings.LessProjectedDebtService,
      ];
      const surplusOrDeficit = [GlobalCashFlowRowHeadings.CashFlowSurplusDeficit];
      const dscr: RowWithType = [GlobalCashFlowRowHeadings.DSCR];
      const tab = `'${sanitizeTabName(tabName)}'!`;

      let currentColumn = startingColumn;
      const cashFlowRowIndex = cashFlow.indexOfHeader("BusinessCashFlowAvailableForDebtService");
      const projectedDebtServiceRowIndex = cashFlow.indexOfHeader("ProjectedDebtService");

      for (const year of yearRange) {
        const form = cashFlow.underlying.find((u) => u.year === year);
        if (!form) {
          cashAvailableForDebt.push("");
          projectedDebtService.push("");
          surplusOrDeficit.push("");
          dscr.push("");
          continue;
        }
        cashAvailableForDebt.push(`=${tab}${currentColumn}${cashFlowRowIndex}`);
        projectedDebtService.push(`=${tab}${currentColumn}${projectedDebtServiceRowIndex}`);
        surplusOrDeficit.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} - ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        dscr.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} / ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        currentColumn = nextColumn(currentColumn);
      }

      entityCashFlowData.push({ entityName, cashAvailableForDebt, projectedDebtService });

      builder.addRow(() => cashAvailableForDebt);
      builder.addRow(() => projectedDebtService);
      builder.addRow(() => surplusOrDeficit);
      builder.addRow(() => dscr);
      builder.addRow();
    }
  }

  function handleForm1040() {
    for (const { tabName, cashFlow, entityName } of form1040RollUps) {
      builder.addRow(() => [entityName, ...empties], undefined, "highlighted");
      const cashAvailableForDebt: RowWithType = [
        GlobalCashFlowRowHeadings.IndividualCashFlowAvailable,
      ];
      const projectedDebtService: RowWithType = [GlobalCashFlowRowHeadings.LessTotalDebtService];
      const surplusOrDeficit = [GlobalCashFlowRowHeadings.CashFlowSurplusDeficit];
      const dscr: RowWithType = [GlobalCashFlowRowHeadings.DSCR];

      let currentColumn = startingColumn;
      const cashFlowRowIndex = cashFlow.indexOfHeader("CashFlowAvailableForDebtService");
      const projectedDebtServiceRowIndex = cashFlow.indexOfHeader("TotalDebtService");
      const tab = `'${sanitizeTabName(tabName)}'!`;

      for (const year of yearRange) {
        const form = cashFlow.underlying.find((u) => u.wc.renderable.year === year);
        if (!form) {
          cashAvailableForDebt.push("");
          projectedDebtService.push("");
          surplusOrDeficit.push("");
          dscr.push("");
          continue;
        }
        cashAvailableForDebt.push(`=${tab}${currentColumn}${cashFlowRowIndex}`);
        projectedDebtService.push(`=${tab}${currentColumn}${projectedDebtServiceRowIndex}`);
        surplusOrDeficit.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} - ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        dscr.push(
          `=${tab}${currentColumn}${cashFlowRowIndex} / ${tab}${currentColumn}${projectedDebtServiceRowIndex}`,
        );
        currentColumn = nextColumn(currentColumn);
      }

      entityCashFlowData.push({ entityName, cashAvailableForDebt, projectedDebtService });

      builder.addRow(() => cashAvailableForDebt);
      builder.addRow(() => projectedDebtService);
      builder.addRow(() => surplusOrDeficit);
      builder.addRow(() => dscr);
      builder.addRow();
    }
  }

  function handleSummary() {
    builder.addRow(({ labels }) => [labels.GlobalCashFlow, ...empties], undefined, "highlighted");
    for (const entity of entityCashFlowData) {
      builder.addRow(() => [
        `Net Cash - ${entity.entityName}`,
        ...entity.cashAvailableForDebt.slice(1),
      ]);
    }
    const summaryRowCount = entityCashFlowData.length;
    resetColumn();
    builder.addRow(
      ({ labels, rowNumber }) => [
        labels.TotalNetCash,
        ...yearRange.map(() => {
          const formula = `=SUM(${builder.columnId}${rowNumber - summaryRowCount}:${builder.columnId}${rowNumber - 1})`;
          builder.columnId = nextColumn(builder.columnId);
          return formula;
        }),
      ],
      undefined,
      "highlighted",
    );

    for (const entity of entityCashFlowData) {
      builder.addRow(() => [
        `Debt Service - ${entity.entityName}`,
        ...entity.projectedDebtService.slice(1),
      ]);
    }
    resetColumn();
    builder.addRow(
      ({ labels, rowNumber }) => [
        labels.TotalDebtService,
        ...yearRange.map(() => {
          const formula = `=SUM(${builder.columnId}${rowNumber - summaryRowCount}:${builder.columnId}${rowNumber - 1})`;
          builder.columnId = nextColumn(builder.columnId);
          return formula;
        }),
      ],
      undefined,
      "highlighted",
    );

    builder.addRow();
    resetColumn();
    builder.addRow(
      ({ labels, columnReference }) => [
        labels.GlobalSurplusDeficit,
        ...yearRange.map(() => {
          const formula = `=${columnReference(labels.TotalNetCash)} - ${columnReference(labels.TotalDebtService)}`;
          builder.columnId = nextColumn(builder.columnId);
          return formula;
        }),
      ],
      undefined,
      "highlighted",
    );
    resetColumn();
    builder.addRow(
      ({ labels, columnReference }) => [
        labels.GlobalDSCR,
        ...yearRange.map(() => {
          const formula = `=${columnReference(labels.TotalNetCash)} / ${columnReference(labels.TotalDebtService)}`;
          builder.columnId = nextColumn(builder.columnId);
          return formula;
        }),
      ],
      undefined,
      "highlighted",
    );
  }

  function nextColumn(column: string) {
    return columnIndexToId(columnIdToIndex(column) + 1);
  }

  function resetColumn() {
    builder.columnId = startingColumn;
  }
}
