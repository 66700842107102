import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { RowWithType } from "src/classes/RowWithType";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import {
  build1120sIncomeStatement,
  Form1120sIncomeStatementRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatement";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";

export class Form1120sIncomeStatementGrouped extends AutoRendered<
  RenderableWithConfidence<Form1120S>[]
> {
  private rendered: AutoRendered<Form1120S>[] = [];
  constructor(public taxData: RenderableWithConfidence<Form1120S>[]) {
    super(taxData);
    this.process();
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  process(): void {
    const startingRow = 1;
    this.underlying
      .sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year))
      .forEach((renderableWithConfidence, i) => {
        const startingColumn = this.getColumnLetter(i);
        const singleRendered = build1120sIncomeStatement(
          renderableWithConfidence,
          startingRow,
          startingColumn,
        );
        this.rendered.push(singleRendered);
      });

    this.rendered.sort((a, b) => parseInt(a.underlying.year) - parseInt(b.underlying.year));

    this.setConfidenceBody();
  }
  asColumns(): RawCellContent[][] {
    const data = this.rendered.map((r) => r.underlying);
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    this.rendered.forEach((singleRendered, i) => {
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });
    return [header, ...body];
  }

  setConfidenceBody(): void {
    const header = [null, ...Array(this.rendered.length).fill(null)];
    const body: RawConfidenceContent[][] = [];
    this.rendered.forEach((rendered, i) => {
      const singleRendered = rendered.asConfidence();
      singleRendered.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });
    this.confidenceBody = [header, ...body];
  }

  get highlightedRowLabels() {
    return [
      "Year",
      Form1120sIncomeStatementRowHeadings.NetRevenues,
      Form1120sIncomeStatementRowHeadings.GrossProfit,
      Form1120sIncomeStatementRowHeadings.TotalIncome,
      Form1120sIncomeStatementRowHeadings.TotalExpensesAndDeductions,
      Form1120sIncomeStatementRowHeadings.PreTaxProfitOrLoss,
      Form1120sIncomeStatementRowHeadings.TotalTax,
      Form1120sIncomeStatementRowHeadings.NetIncome,
    ];
  }

  get percentageRowLabels() {
    return [];
  }

  metadataRowLabels: string[] = [];

  public indexOfHeader(header: keyof typeof Form1120sIncomeStatementRowHeadings): number {
    const heading = Form1120sIncomeStatementRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
