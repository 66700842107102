import { JobId } from "aws-sdk/clients/textract";
import { Reducer } from "redux";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";;
import { FileReference, OcrResponse } from "src/pages/api/documents/ocr/common";
import {
  OcrCompletedAction,
  OcrErrorAction,
  OcrPollingCompletedAction,
  PollOcrStatus,
  SelectDocumentUploadId,
  SetAvailableUploadIds,
  SetSelectedUploadIds,
  StartOcrSaga,
  UnSelectDocumentUploadId,
  UpdateOcrData,
} from "../actions/ocr.actions";
import { OcrAction } from "../actions/actions.constants";

type DocumentUploadId = string;

export interface OcrState {
  isPolling: boolean;
  jobs: Record<JobId | number, FileReference> | null;
  cachedExtracts: Record<DocumentUploadId, ExtractOutput>;
  error: string | null;
  allExtracts: Record<DocumentUploadId, ExtractOutput> | null;
  dataNeeded: FileReference[];
  availableUploadIds: number[];
  selectedUploadIds: number[];
  addAnyFileOpen: boolean;
}

export const initialOcrState: OcrState = {
  isPolling: false,
  jobs: null,
  cachedExtracts: {},
  error: null,
  allExtracts: null,
  dataNeeded: [],
  availableUploadIds: [],
  selectedUploadIds: [],
  addAnyFileOpen: false,
};

type OcrActionWrapper =
  | ReturnType<typeof StartOcrSaga>
  | ReturnType<typeof PollOcrStatus>
  | ReturnType<typeof UpdateOcrData>
  | ReturnType<typeof OcrCompletedAction>
  | ReturnType<typeof OcrErrorAction>
  | ReturnType<typeof OcrPollingCompletedAction>
  | ReturnType<typeof SetAvailableUploadIds>
  | ReturnType<typeof SetSelectedUploadIds>
  | ReturnType<typeof SelectDocumentUploadId>
  | ReturnType<typeof UnSelectDocumentUploadId>;

export const ocrReducer: Reducer<OcrState> = (state = initialOcrState, action) => {
  const ocrAction = action as OcrActionWrapper;
  const { type } = ocrAction;
  switch (type) {
    case OcrAction.START_OCR:
      const payload = ocrAction.payload as FileReference[];
      return {
        ...state,
        isPolling: true,
        jobs: null,
        error: null,
        pollingStatus: {},
        cachedExtracts: {},
        allExtracts: null,
        dataNeeded: payload,
      };

    case OcrAction.UPDATE_OCR_DATA: {
      const payload = ocrAction.payload as OcrResponse;
      const newCachedExtracts = Object.entries(payload).reduce(
        (acc, [documentUploadId, jobOrData]) => {
          if (typeof jobOrData === "object" && jobOrData !== null) {
            const data = jobOrData as ExtractOutput;
            acc[documentUploadId] = data;
          }
          return acc;
        },
        {} as Record<DocumentUploadId, ExtractOutput>,
      );

      const jobs = Object.entries(payload).reduce(
        (acc, [documentUploadId, jobOrData]) => {
          if (typeof jobOrData === "string" || typeof jobOrData === "number") {
            const neededFile = state.dataNeeded.find(
              (job) => job.documentUploadId === parseInt(documentUploadId),
            );
            if (neededFile) {
              acc[jobOrData] = neededFile;
            }
          }
          return acc;
        },
        {} as Record<JobId | number, FileReference>,
      );

      return {
        ...state,
        cachedExtracts: {
          ...state.cachedExtracts,
          ...newCachedExtracts,
        },
        jobs: {
          ...jobs,
        },
      };
    }
    case OcrAction.OCR_COMPLETED:
      const completedPayload = ocrAction.payload as OcrResponse;
      const allExtracts = Object.entries(completedPayload).reduce(
        (acc, [documentUploadId, jobOrData]) => {
          if (typeof jobOrData === "object" && jobOrData !== null) {
            const data = jobOrData as ExtractOutput;
            acc[documentUploadId] = data;
          }
          return acc;
        },
        {} as Record<DocumentUploadId, ExtractOutput>,
      );
      return {
        ...state,
        isPolling: false,
        allExtracts: allExtracts,
        subjectAssets: null,
      };

    case OcrAction.OCR_ERROR:
      const errorPayload = ocrAction.payload as Error;
      return {
        ...state,
        isPolling: false,
        error: errorPayload.message,
      };

    case OcrAction.SET_AVAILABLE_UPLOAD_IDS:
      const availableUploadIds = ocrAction.payload as number[];
      return {
        ...state,
        availableUploadIds: availableUploadIds,
      };

    case OcrAction.SET_SELECTED_UPLOAD_IDS:
      const selectedUploadIds = ocrAction.payload as number[];
      return {
        ...state,
        selectedUploadIds: selectedUploadIds,
      };

    case OcrAction.SELECT_DOCUMENT_UPLOAD_ID:
      const selectedUploadId = ocrAction.payload as number;
      return {
        ...state,
        selectedUploadIds: [...state.selectedUploadIds, selectedUploadId],
      };

    case OcrAction.UNSELECT_DOCUMENT_UPLOAD_ID:
      return {
        ...state,
        selectedUploadIds: [...state.selectedUploadIds].filter((id) => id !== ocrAction.payload),
      };

    case OcrAction.RESET_OCR_DATA:
      return { ...state, allExtracts: null, cachedExtracts: {} };

    default:
      return state;
  }
};
