// This file is a place for Zod schemas that we use for
// JSONB columns in the database.

import moment from "moment/moment";
import { FrequencyOptionEnum, frequencyOptionEnumSchema } from "@interfold-ai/shared/enums/FrequencyOptionEnum";
import * as z from "zod";
import { TemplateMessage } from "../pages/api/contacts/index.contracts";

// The type of "CustomerNotificationSequence".sequence.
export const sequenceSchema = z.array(
  z.object({
    message: z.string(),
    afterDays: z.number(),
    email_sending_dates: z.array(z.string()).optional(),
  }),
);

export type Sequence = z.infer<typeof sequenceSchema>;

// A document request's cadence has a period: how often the document is requested.
// Typically this is "annually", "quarterly", and so on, but it can also be "custom".
// This schema is for the "custom" case.
// TODO: I don't know what these fields mean, or if they mean anything;
//  custom periods are only partially implemented.
export const customPeriodSchema = z
  .object({
    interval_type: z.string(),
    count: z.number(),
  })
  .partial();

export type CustomPeriod = z.infer<typeof customPeriodSchema>;

// The type of document_request.cadence.
export const cadenceSchema = z.object({
  // When is _this document request_ due?
  // AKA: "final due date".
  // moment.toISOString()
  end: z.string(),

  // IMPORTANT: this array will always have exactly one element.
  periods: z.array(
    z.object({
      // When will we first request _this document request_?
      // AKA: "request date".
      // moment.toISOString()
      start: z.string(),
      period: frequencyOptionEnumSchema,
      customPeriod: customPeriodSchema,
    }),
  ),
  // Historical note: technically, there can be multiple periods in a cadence,
  // which allows for things like "every month, except for the first month, which is a custom period".
  // This was important back when we expected to reuse document requests;
  // we would have interpreted as "end" as "the end of the relationship, and the end of the need for this document altogether,"
  // and there might have been several periods over the years if the lender wanted a document more often.
  // Now that we don't reuse document requests, we can simplify this to just one period,
  // and just make a new document request every time the period changes.
});

export type Cadence = z.infer<typeof cadenceSchema>;

export const incrementCadence = (oldCadence: Cadence): Cadence | undefined => {
  const oldEnd = moment(oldCadence.end);
  const oldStart = moment(oldCadence.periods[0].start);

  switch (oldCadence.periods[0].period) {
    case FrequencyOptionEnum.CUSTOM: {
      // TODO: handle custom periods
      return oldCadence;
    }
    case FrequencyOptionEnum.ANNUALLY: {
      return {
        end: oldEnd.add(1, "year").toISOString(),
        periods: [
          {
            start: oldStart.add(1, "year").toISOString(),
            period: FrequencyOptionEnum.ANNUALLY,
            customPeriod: {},
          },
        ],
      };
    }
    case FrequencyOptionEnum.BIANNUALLY: {
      return {
        end: oldEnd.add(6, "months").toISOString(),
        periods: [
          {
            start: oldStart.add(6, "months").toISOString(),
            period: FrequencyOptionEnum.BIANNUALLY,
            customPeriod: {},
          },
        ],
      };
    }
    case FrequencyOptionEnum.QUARTERLY: {
      return {
        end: oldEnd.add(1, "quarter").toISOString(),
        periods: [
          {
            start: oldStart.add(1, "quarter").toISOString(),
            period: FrequencyOptionEnum.QUARTERLY,
            customPeriod: {},
          },
        ],
      };
    }
    case FrequencyOptionEnum.MONTHLY: {
      return {
        end: oldEnd.add(1, "month").toISOString(),
        periods: [
          {
            start: oldStart.add(1, "month").toISOString(),
            period: FrequencyOptionEnum.MONTHLY,
            customPeriod: {},
          },
        ],
      };
    }
    default: {
      return undefined;
    }
  }
};

export const incrementSequence = (
  sequence: TemplateMessage[],
  newStartDate: moment.Moment,
): TemplateMessage[] => {
  return sequence.map((s) => {
    return {
      ...s,
      email_sending_dates: [newStartDate.clone().add(s.afterDays, "days").toISOString()],
    };
  });
};
