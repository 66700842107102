import { CreateOrUpdateDocumentAnalysis } from "src/models/DocumentAnalysis";
import { DocumentAnalysis } from "@prisma/client";
import { baseApiService } from "src/services/api-services/BaseApiService";

export class DocumentAnalysisService {
  static getInstance(): DocumentAnalysisService {
    return new DocumentAnalysisService();
  }

  async createDocumentAnalysis(
    data: CreateOrUpdateDocumentAnalysis,
  ): Promise<DocumentAnalysis[] | null> {
    const documentAnalysis = await baseApiService.post("/api/document-analysis", data);

    if (!documentAnalysis) {
      return null;
    }

    return documentAnalysis;
  }

  async updateDocumentAnalysis(
    memoId: number,
    data: CreateOrUpdateDocumentAnalysis,
  ): Promise<DocumentAnalysis[] | null> {
    const response = await baseApiService.patch(`/api/document-analysis/${memoId}`, data);

    if (!response) {
      return null;
    }

    return response;
  }

  async deleteDocumentAnalysis(memoId: number): Promise<DocumentAnalysis | undefined> {
    const response = await baseApiService.delete(`/api/document-analysis/${memoId}`);

    if (!response) {
      return;
    }

    return response;
  }

  async getDocumentAnalysisById(memoId: number): Promise<DocumentAnalysis | undefined> {
    const response = await baseApiService.get(`/api/document-analysis/${memoId}`);

    if (!response) {
      return;
    }

    return response;
  }

  async getDocumentAnalysisByLoanId(loanId: number): Promise<DocumentAnalysis[] | undefined> {
    const response = await baseApiService.get("/api/document-analysis", {
      params: {
        loanId: loanId.toString(),
      },
    });

    if (!response) {
      return;
    }

    return response;
  }

  async getDocumentAnalysisByReviewId(reviewId: number): Promise<DocumentAnalysis[] | undefined> {
    const response = await baseApiService.get("/api/document-analysis", {
      params: {
        reviewId: reviewId.toString(),
      },
    });

    if (!response) {
      return;
    }

    return response;
  }
}
