import { baseApiService } from "../api-services/BaseApiService";

export interface GetDocumentAnalysisParams {
  loanId?: number;
  reviewId?: number;
}

export class RawDocumentAnalysisService {
  static getInstance(): RawDocumentAnalysisService {
    return new RawDocumentAnalysisService();
  }

  async getDocumentAnalysis(params: GetDocumentAnalysisParams): Promise<any[]> {
    const response = await baseApiService.get("/api/document-analysis/raw", {
      params: {
        loanId: params.loanId?.toString() ?? "",
        reviewId: params.reviewId?.toString() ?? "",
      },
    });

    if (!response) {
      return [];
    }

    return response;
  }
}
