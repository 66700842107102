import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { RowWithType } from "src/classes/RowWithType";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import {
  buildForm1065BalanceSheet,
  Form1065BalanceSheetRowHeadings,
} from "src/classes/RenderedDocuments/Form1065/Form1065BalanceSheet";

export class Form1065BalanceSheetGrouped extends AutoRendered<Form1065[]> {
  constructor(data: Form1065[]) {
    super(data);
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.underlying.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = buildForm1065BalanceSheet(form, startingColumn, startingRow);
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0] ?? ""];
        }
        body[rowIndex].push(row[1] ?? "");
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    return [
      "Year",
      Form1065BalanceSheetRowHeadings.Assets,
      Form1065BalanceSheetRowHeadings.TotalCurrentAssets,
      Form1065BalanceSheetRowHeadings.TotalNonCurrentAssets,
      Form1065BalanceSheetRowHeadings.TotalAssets,
      Form1065BalanceSheetRowHeadings.LiabilitiesAndEquity,
      Form1065BalanceSheetRowHeadings.TotalCurrentLiabilities,
      Form1065BalanceSheetRowHeadings.TotalNonCurrentLiabilities,
      Form1065BalanceSheetRowHeadings.TotalLiabilities,
      Form1065BalanceSheetRowHeadings.TotalShareholdersEquity,
      Form1065BalanceSheetRowHeadings.TotalLiabilitiesAndShareholdersEquity,
    ];
  }

  percentageRowLabels: string[] = [];
  metadataRowLabels: string[] = [];

  indexOfHeader(header: keyof typeof Form1065BalanceSheetRowHeadings): number {
    const heading = Form1065BalanceSheetRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
