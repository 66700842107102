import { baseApiService } from "src/services/api-services/BaseApiService";
import { WorkflowSettings } from "src/models/WorkflowSettings";
import { LenderWorkflowSettings } from "@interfold-ai/db";

export class LoanWorkflowService {
  static getInstance(): LoanWorkflowService {
    return new LoanWorkflowService();
  }

  async getLoanWorkflows(): Promise<LenderWorkflowSettings[]> {
    return await baseApiService.get("/api/settings/workflow");
  }

  async createNewLoanWorkflow(payload: WorkflowSettings): Promise<any> {
    const loanWorkflow = await baseApiService.post("/api/settings/workflow", payload);

    if (!loanWorkflow) {
      return;
    }

    return loanWorkflow;
  }

  async updateNewLoanWorkflow(payload: WorkflowSettings & { id: number }): Promise<any> {
    const loanWorkflow = await baseApiService.put(`/api/settings/workflow/${payload.id}`, payload);

    if (!loanWorkflow) {
      return;
    }

    return loanWorkflow;
  }

  async deleteLoanWorkflow(payload: { id: number }): Promise<any> {
    const loanWorkflow = await baseApiService.delete(`/api/settings/workflow/${payload.id}`);

    if (!loanWorkflow) {
      return;
    }

    return loanWorkflow;
  }
}
export const loanWorkflowService = LoanWorkflowService.getInstance();
