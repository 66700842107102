import type { TaskStatus } from "@interfold-ai/shared/models/BackgroundTasks";
import type { AnalysisType } from "@interfold-ai/shared/models/SpreadsTabConstants";
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import {
  RawDocumentAnalysisService,
  GetDocumentAnalysisParams,
} from "src/services/document-analysis/RawDocumentAnalysisService";
import { AppState } from "../reducers";

export interface RawDocumentAnalysis {
  analysisType: AnalysisType;
  documentUploadId: number;
  taskStatus: TaskStatus;
  year: number;
  documentType: string;
  output: { output: { raw: string; source: string } };
}

export interface DocumentAnalysisState {
  documentAnalysisForLoans: Record<number, RawDocumentAnalysis[]>;
  documentAnalysisForAnnualReviews: Record<number, RawDocumentAnalysis[]>;
  isLoading: boolean;
  error: string | null;
}

export const initialDocumentAnalysisState: DocumentAnalysisState = {
  documentAnalysisForLoans: {},
  documentAnalysisForAnnualReviews: {},
  isLoading: false,
  error: null,
};

export const fetchDocumentAnalysis = createAsyncThunk<
  RawDocumentAnalysis[],
  GetDocumentAnalysisParams,
  { rejectValue: { loanId?: number; reviewId?: number; error: string } }
>("rawDocumentAnalysis/fetchDocumentAnalysis", async (params, { rejectWithValue }) => {
  try {
    const service = RawDocumentAnalysisService.getInstance();
    const documentAnalysis = await service.getDocumentAnalysis(params);
    return documentAnalysis;
  } catch (error: any) {
    return rejectWithValue({
      loanId: params.loanId,
      reviewId: params.reviewId,
      error: (error as Error).message,
    });
  }
});

export const rawDocumentAnalysisSlice: Slice<DocumentAnalysisState> = createSlice({
  name: "rawDocumentAnalysis",
  initialState: initialDocumentAnalysisState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentAnalysis.pending, (state, _action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentAnalysis.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.meta.arg.loanId) {
        state.documentAnalysisForLoans[action.meta.arg.loanId] = action.payload;
      } else if (action.meta.arg.reviewId) {
        state.documentAnalysisForAnnualReviews[action.meta.arg.reviewId] = action.payload;
      }
      state.error = null;
    });
    builder.addCase(fetchDocumentAnalysis.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error ?? null;
    });
  },
});

export const loanDocumentAnalysisSelector = (state: AppState, loanId: number) =>
  state.rawDocumentAnalysis.documentAnalysisForLoans[loanId];

export const annualReviewDocumentAnalysisSelector = (state: AppState, reviewId: number) =>
  state.rawDocumentAnalysis.documentAnalysisForAnnualReviews[reviewId];

export const isDocumentAnalysisLoadingSelector = (state: AppState) =>
  state.rawDocumentAnalysis.isLoading;

export default rawDocumentAnalysisSlice.reducer;
