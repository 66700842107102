import { RentalRealEstateIncomeAndExpenses } from "@interfold-ai/shared/models/tax/Form8825";
import { NumberFromStatement } from "@interfold-ai/shared/models/tax/common";
import { RentRollTableData } from "@interfold-ai/shared/models/Property";
import { TaxProperty } from "@interfold-ai/shared/models/tax/schedules/ScheduleE";
import { CanonicalRentRoll } from "@interfold-ai/shared/models/Property";
import { spreadablePropertyLabel } from "src/redux/selectors/spreadablePropertyLabel";

export interface NOIGroupedAsset {
  source: string;
  entityName: string;
  propertyName: string;
  year: string;
  grossRents: number;
  totalExpenses: number;
  netIncome: number;
  interest: number;
  depreciation: number;
  amortization: NumberFromStatement;
}

export class NOIGroupedAsset implements NOIGroupedAsset {
  source: string = "";
  entityName: string = "";
  propertyName: string = "";
  year: string = "";
  grossRents: number = 0;
  totalExpenses: number = 0;
  netIncome: number = 0;
  interest: number = 0;
  depreciation: number = 0;
  amortization: NumberFromStatement = { statementValue: 0 };

  private constructor(
    source: string,
    entityName: string,
    propertyName: string,
    year: string,
    grossRents: number,
    totalExpenses: number,
    netIncome: number,
    interest: number,
    depreciation: number,
    amortization: NumberFromStatement,
  ) {
    this.source = source;
    this.entityName = entityName;
    this.propertyName = propertyName;
    this.year = year;
    this.grossRents = grossRents;
    this.totalExpenses = totalExpenses;
    this.netIncome = netIncome;
    this.interest = interest;
    this.depreciation = depreciation;
    this.amortization = amortization;
  }

  static fromTaxProperty(
    data: TaxProperty,
    year: number,
    entityName: string,
    source: string,
  ): NOIGroupedAsset {
    return new NOIGroupedAsset(
      source,
      entityName,
      data.propertyAddress,
      year.toString(),
      data.rentsReceived ?? 0,
      data.totalExpenses ?? 0,
      data.netIncome ?? 0,
      data.mortgageInterest + data.otherInterest,
      data.depreciation ?? 0,
      data.amortization,
    );
  }

  static fromRentalRealEstateIncomeAndExpenses(
    data: RentalRealEstateIncomeAndExpenses,
    year: number,
    entityName: string,
    source: string,
  ): NOIGroupedAsset {
    return new NOIGroupedAsset(
      source,
      entityName,
      data.propertyAddress,
      year.toString(),
      data.grossRents ?? 0,
      data.totalExpenses ?? 0,
      data.netIncome ?? 0,
      data.mortgageInterest + data.otherInterest + (data.interest ?? 0),
      data.depreciation ?? 0,
      data.amortization,
    );
  }

  static fromLegacyRentRoll(rentRoll: RentRollTableData): NOIGroupedAsset {
    const grossRents = (rentRoll.grossRents as number | undefined) ?? 0;
    return new NOIGroupedAsset(
      "Rent Roll",
      rentRoll.extractContext.metadata.entityName,
      rentRoll.propertyName ?? "",
      rentRoll.year.toString(),
      grossRents * 12,
      0, // total expenses
      0, // net income
      0, // interest
      0, // depreciation
      { statementValue: 0 },
    );
  }

  static fromCanonicalRentRoll(rentRoll: CanonicalRentRoll): NOIGroupedAsset {
    const grossRents = rentRoll.yearlyGrossRents ?? (rentRoll.monthlyGrossRents ?? 0) * 12;
    return new NOIGroupedAsset(
      "Rent Roll",
      rentRoll.extractContext.metadata.entityName,
      spreadablePropertyLabel(rentRoll),
      rentRoll.year.toString(),
      grossRents,
      0, // total expenses
      0, // net income
      0, // interest
      0, // depreciation
      { statementValue: 0 },
    );
  }
}
