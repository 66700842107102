import { RawCellContent } from "hyperformula";
import {
  FinancialItemComputed,
  FinancialItemType,
  FinancialPeriodItem,
  FinancialStatementItems,
  CashFlowCandidates,
} from "@interfold-ai/shared/models/FinancialStatement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "@interfold-ai/shared/utils/parseTaxCellAsFloat";
import { colNumberToExcelCol } from "src/classes/RenderedDoc";
import { sanitizeTabName } from "./utils";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { FinancialStatementCashFlowItems } from "@interfold-ai/shared/models/FinancialStatement";

export enum RawFinancialStatementType {
  IncomeStatement = "incomeStatement",
  BalanceSheet = "balanceSheet",
  CashFlowStatement = "cashFlowStatement",
}

const similarItem = (target: string, searchItem: string) => {
  return target.toLowerCase().includes(searchItem.toLowerCase());
};

const getCellReference = (colIndex: number, rowIndex: number): string => {
  const colLetter = colNumberToExcelCol(colIndex + 2);
  return `${colLetter}${rowIndex + 1}`;
};

export const combineFinancialStatementItems = (
  financialStatementItems: FinancialStatementItems[],
): FinancialStatementItems | null => {
  if (!financialStatementItems.length) {
    return null;
  }

  if (financialStatementItems.length === 1) {
    return financialStatementItems[0] || null;
  }

  const sortedItems = [...financialStatementItems].sort((a, b) => {
    const aLatest = Math.max(...a.availablePeriods.map((p) => parseInt(p)));
    const bLatest = Math.max(...b.availablePeriods.map((p) => parseInt(p)));
    return bLatest - aLatest;
  });

  const combined: FinancialStatementItems = {
    ...sortedItems[0],
    availablePeriods: [],
    incomeStatement: [],
    balanceSheet: [],
    cashFlowStatement: [],
    calculatedCashFlowItems: null,
  };

  const allPeriods = new Set<string>();
  sortedItems.forEach((statement) => {
    statement.availablePeriods.forEach((period) => allPeriods.add(period));
  });
  combined.availablePeriods = sortPeriods(Array.from(allPeriods));

  const mergePeriodValues = (
    existingValues: Record<string, string | null> | null = null,
    newValues: Record<string, string | null> | null = null,
  ) => {
    if (!existingValues && !newValues) {
      return null;
    }
    if (!existingValues) {
      return newValues;
    }
    if (!newValues) {
      return existingValues;
    }
    return { ...newValues, ...existingValues };
  };

  const mergeItems = (items: FinancialPeriodItem[]): FinancialPeriodItem[] => {
    const mergedItems = new Map<string, FinancialPeriodItem>();

    // newer period document items take precedence
    [...items].reverse().forEach((item) => {
      const existing = mergedItems.get(item.name);
      if (!existing) {
        mergedItems.set(item.name, { ...item });
      } else {
        existing.periodValues = mergePeriodValues(item.periodValues, existing.periodValues);

        if (item.nestedItems?.length) {
          existing.nestedItems = mergeItems([...item.nestedItems, ...(existing.nestedItems || [])]);
        }

        if (item.total) {
          existing.total = existing.total
            ? {
                ...item.total,
                periodValues: mergePeriodValues(
                  item.total.periodValues,
                  existing.total.periodValues,
                ),
              }
            : item.total;
        }
      }
    });

    return Array.from(mergedItems.values());
  };

  combined.incomeStatement = mergeItems(
    sortedItems.flatMap((statement) => statement.incomeStatement || []),
  );
  combined.balanceSheet = mergeItems(
    sortedItems.flatMap((statement) => statement.balanceSheet || []),
  );
  combined.cashFlowStatement = mergeItems(
    sortedItems.flatMap((statement) => statement.cashFlowStatement || []),
  );
  combined.calculatedCashFlowItems = sortedItems.reduce((acc, statement) => {
    if (statement.calculatedCashFlowItems) {
      const uniqueItems = new Set([
        ...(acc.items || []),
        ...statement.calculatedCashFlowItems.items,
      ]);
      acc.items = Array.from(uniqueItems);
      acc.netIncomeItem = statement.calculatedCashFlowItems.netIncomeItem;
    }
    return acc;
  }, {} as FinancialStatementCashFlowItems);
  return combined;
};

/**
 * This function is used to adjust the interest values for the cash flow tab.
 * It is used to ensure that the interest values are properly signed for the cash flow tab.
 *
 * It only matters if expenses are recorded as negative numbers in the Financials tab.
 * Since we reference that tab for calculating the Cash Flow we need to adjust the sign
 * in some cases. We need to add expenses and subtract income (revenue).
 *
 * @param candidates - The cash flow candidates.
 * @param rows - The rows of the cash flow tab.
 */
export const adjustInterestValuesForCashFlow = (
  candidates: CashFlowCandidates,
  rows: GridRow[],
) => {
  const interestRows = candidates.interest;
  const expenseTerms = ["expense", "expenses"];
  interestRows.forEach((interestRow) => {
    const description = interestRow.rowKey;
    let term = description.match(/\((.*)\)/)?.[1];
    if (!term || term.trim() === "") {
      // work backwards to find the root item for this interest row
      for (let i = interestRow.rowIndex - 1; i >= 0; i--) {
        const row = rows[i];
        term = (row.rowDataArray[0] as string)?.match(/\((.*)\)/)?.[1];
        // if we have a term or we reach the root element
        if (term || row.rowMetadata?.levelIndex === 0) {
          break;
        }
      }
    }
    if (!term || term.trim() === "") {
      return;
    }
    if (expenseTerms.includes(term.toLowerCase().trim())) {
      const gridRow = rows.find((row) => row.rowDataArray[0] === description);
      if (gridRow) {
        gridRow.rowDataArray.slice(1).forEach((cell) => {
          if (cell) {
            // always reverse the sign
            // we want to add expenses (we want them to be positive  number)
            // we want to subtract revenue/income (we want them to be negative numbers)
            interestRow.multipliers = interestRow.multipliers || [];
            interestRow.multipliers.push(-1);
          }
        });
      }
    }
  });
};

export const cashFlowCandidateRowsFromItems = (
  rows: GridRow[],
  items: FinancialStatementCashFlowItems,
): CashFlowCandidates => {
  const usedRowIndices = new Set<number>();
  const netIncomeRows = rows
    .filter((row) => row.rowDataArray[0] === items.netIncomeItem)
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  const addBackRows = rows
    .filter(
      (row) =>
        items.items.includes(row.rowDataArray[0] as string) && !usedRowIndices.has(row.index),
    )
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  // looks like depreciation
  const depreciation = addBackRows.filter((row) =>
    similarItem(row.rowKey, FinancialItemType.Depreciation),
  );

  // looks like amortization
  const amortization = addBackRows.filter(
    (row) => similarItem(row.rowKey, FinancialItemType.Amortization) && !depreciation.includes(row),
  );

  // everything else
  const interest = addBackRows.filter(
    (row) => !depreciation.includes(row) && !amortization.includes(row),
  );

  return {
    netIncome: netIncomeRows,
    depreciation,
    interest,
    amortization,
  };
};

export const cashFlowCandidateRows = (rows: GridRow[]): CashFlowCandidates => {
  const usedRowIndices = new Set<number>();

  const netIncomeRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.NetIncome))
    .filter((row) => row.rowDataArray.slice(1).some((cell) => cell))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    })
    .slice(0, 1);

  const depreciationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Depreciation))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  const interestRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Interest))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
        negateValue: false,
      };
    });

  const amortizationRows = rows
    .filter((row) => similarItem(row.rowDataArray[0] as string, FinancialItemType.Amortization))
    .filter((row) => !usedRowIndices.has(row.index))
    .map((row) => {
      usedRowIndices.add(row.index);
      return {
        rowIndex: row.index,
        rowKey: row.rowDataArray[0] as string,
      };
    });

  return {
    netIncome: netIncomeRows,
    depreciation: depreciationRows,
    interest: interestRows,
    amortization: amortizationRows,
  };
};

export const buildCashFlowFromFinancialsTab = (
  candidateRows: CashFlowCandidates,
  allPeriods: string[],
  sourceTabName: string,
) => {
  const sanitizedTabName = sanitizeTabName(sourceTabName);
  const rendered = new AutoRenderedSheetBuilder({}, {}, 0, "B");
  const sortedPeriods = sortPeriods(allPeriods);

  const periodCellRefs: Record<number, string[]> = {};
  sortedPeriods.forEach((_, i) => (periodCellRefs[i] = []));

  rendered.addRow(() => ["", ...sortedPeriods], "text", "highlighted");

  Object.entries(candidateRows).forEach(([_key, rows]) => {
    if (rows.length === 0) {
      return;
    }
    rows.forEach((row) => {
      const newRowIndex = rendered.body.length;
      rendered.addRow(() => {
        const rowData = [
          row.rowKey,
          ...sortedPeriods.map((_, i) => {
            const multiplier = row.multipliers?.[i];
            let multiplierFormula = "";
            if (multiplier) {
              multiplierFormula = ` * ${multiplier}`;
            } else {
              multiplierFormula = "";
            }
            const cellRef = `='${sanitizedTabName}'!${getCellReference(i, row.rowIndex)}${multiplierFormula}`;
            // can we remove this?
            periodCellRefs[i].push(`${getCellReference(i, newRowIndex)}`);
            return cellRef;
          }),
        ];
        return rowData;
      });
    });
  });

  rendered.addRow(
    () => [
      FinancialItemComputed.BusinessCashFlowBeforeTax,
      ...sortedPeriods.map((_, i) =>
        periodCellRefs[i].length ? `=${periodCellRefs[i].join("+")}` : "",
      ),
    ],
    "number",
    "highlighted",
  );

  return rendered;
};

export const convertToGridRows = (
  jsonData: FinancialStatementItems,
  type: RawFinancialStatementType,
): GridRow[] => {
  let rowIndex = 0;

  const processItem = (
    item: FinancialPeriodItem,
    allPeriods: string[],
    levelIndex: number,
  ): GridRow[] => {
    const gridRows: GridRow[] = [];

    const skipItemLevelValues = item.total || (item.nestedItems && item.nestedItems.length > 0);
    const rowDataArray: RawCellContent[] = [
      item.name,
      ...allPeriods.map((period) => {
        if (skipItemLevelValues) {
          return "";
        } else {
          return parseTaxCellAsFloatOrUndefined(item.periodValues?.[period] || "");
        }
      }),
    ];
    const gridRow: GridRow = {
      rowDataArray,
      rowDataType: "number",
      rowStyle: levelIndex === 0 ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex,
      },
      isManagedByApp: true,
      isEditable: false,
      index: rowIndex++,
    };
    gridRows.push(gridRow);

    item.nestedItems?.forEach((nestedItem) => {
      gridRows.push(...processItem(nestedItem, allPeriods, levelIndex + 1));
    });

    if (item.total) {
      const totalRowDataArray: RawCellContent[] = [
        item.total.name,
        ...allPeriods.map((period) =>
          parseTaxCellAsFloatOrUndefined(item.total?.periodValues?.[period] || ""),
        ),
      ];

      const totalGridRow: GridRow = {
        rowDataArray: totalRowDataArray,
        rowDataType: "number",
        rowStyle: "highlighted",
        rowMetadata: {
          levelIndex: levelIndex + 1,
        },
        isManagedByApp: true,
        isEditable: false,
        index: rowIndex++,
      };
      gridRows.push(totalGridRow);
    }

    return gridRows;
  };

  const gridRows: GridRow[] = [];
  const allPeriods = sortPeriods(jsonData.availablePeriods);

  gridRows.push({
    rowDataArray: ["", ...allPeriods],
    rowDataType: "text",
    rowStyle: "highlighted",
    rowMetadata: {
      levelIndex: 0,
    },
    isManagedByApp: true,
    isEditable: false,
    index: rowIndex++,
  });

  if (type === RawFinancialStatementType.IncomeStatement) {
    jsonData.incomeStatement.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  } else if (type === RawFinancialStatementType.BalanceSheet) {
    jsonData.balanceSheet.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  } else if (type === RawFinancialStatementType.CashFlowStatement) {
    jsonData.cashFlowStatement.forEach((item) => {
      gridRows.push(...processItem(item, allPeriods, 0));
    });
  }

  return gridRows;
};

export const sortPeriods = (periods: string[]): string[] => {
  // Helper to determine period type priority (lower number = higher priority)
  const getTypePriority = (period: string): number => {
    const sanitizedPeriod = period.toLowerCase();
    if (sanitizedPeriod.match(/^[0-9]{4}$/)) {
      return 5; // Full year
    }
    if (sanitizedPeriod.includes("ytd")) {
      return 4;
    }
    if (sanitizedPeriod.match(/h[1-2]/)) {
      return 3; // Half year
    }
    if (sanitizedPeriod.match(/q[1-4]/)) {
      return 2; // Quarter
    }
    for (const month of [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ]) {
      if (sanitizedPeriod.includes(month)) {
        return 1; // Month
      }
    }
    return 6; // Unknown format
  };

  const getPeriodWeight = (period: string): number => {
    const sanitizedPeriod = period.toLowerCase();

    // First check if it's a valid year format
    const yearMatch = period.match(/^\d{4}/);
    if (!yearMatch) {
      return Infinity; // Invalid formats should go to the end
    }

    const quarterMatch = sanitizedPeriod.match(/q[1-4]/);
    if (quarterMatch) {
      return parseInt(quarterMatch[0].slice(-1)) * 3;
    }

    const halfYearMatch = sanitizedPeriod.match(/h[1-2]/);
    if (halfYearMatch) {
      return parseInt(halfYearMatch[0].slice(-1)) * 6;
    }

    const monthMap = {
      jan: 1,
      january: 1,
      feb: 2,
      february: 2,
      mar: 3,
      march: 3,
      apr: 4,
      april: 4,
      may: 5,
      jun: 6,
      june: 6,
      jul: 7,
      july: 7,
      aug: 8,
      august: 8,
      sep: 9,
      september: 9,
      oct: 10,
      october: 10,
      nov: 11,
      november: 11,
      dec: 12,
      december: 12,
    };

    for (const [monthName, weight] of Object.entries(monthMap)) {
      if (sanitizedPeriod.includes(monthName)) {
        return weight;
      }
    }

    if (sanitizedPeriod.includes("ytd")) {
      return 13;
    }

    if (sanitizedPeriod.length === 4) {
      return 14;
    }

    return Infinity;
  };

  return [...periods].sort((a, b) => {
    // First handle invalid formats (no year)
    const aYear = parseInt(a.slice(0, 4)) || 0;
    const bYear = parseInt(b.slice(0, 4)) || 0;

    if (!aYear && !bYear) {
      // Both are invalid, maintain original order
      return 0;
    }
    if (!aYear) {
      return 1;
    } // a is invalid, move to end
    if (!bYear) {
      return -1;
    } // b is invalid, move to end

    // If years differ, sort by year
    if (aYear !== bYear) {
      return aYear - bYear;
    }

    // If years are same, use weights
    const aWeight = getPeriodWeight(a);
    const bWeight = getPeriodWeight(b);

    if (aWeight === bWeight) {
      // If weights are equal, sort by type priority
      return getTypePriority(a) - getTypePriority(b);
    }

    return aWeight - bWeight;
  });
};
