import { DocumentUploadStatus, LenderEmployeeAccess } from "@interfold-ai/db";
import produce from "immer";
import moment from "moment";
import { Reducer } from "redux";
import { UploadDocumentResponse } from "@interfold-ai/shared/models/DocumentRequest";
import { Loan, LoanDetails } from "src/models/Loan";
import { AnnualReviewAction, PipelineLoans, LoanAction } from "../actions/actions.constants";
import { DocumentDetails, DocumentStatus } from "src/models/DocumentDetails";
import { Cents } from "src/utils/currency";
import { WritableDraft } from "immer/dist/types/types-external.js";

export interface PipelineIntakeState {
  entities: {
    list: any[];
    detail: LoanDetails;
    managementList: any[];
    isLoading: boolean;
    isLoaded: boolean;
    annualReviewIsLoading: boolean;
    annualReviewIsLoaded: boolean;
  };
  searchParams?: {
    limit: number;
    offset: number;
    search: string;
    sortBy: string;
    sortOrder: string;
    filterByStatus: string | string[];
    filterByAccountOwner: string | string[];
  };
  currentAnnualReviewId?: number;
  currentLoanId?: number;
  loanStarted: boolean;
  error?: string;
  draftLoans: Loan[];
}

const initialLoanDetails: LoanDetails = {
  id: "",
  accountName: "",
  pauseNotifications: false,
  amountCents: 0 as Cents,
  borrowers: [],
  guarantors: [],
  activity: "",
  alertMessage: "",
  verified_documents: "",
  closingDate: "",
  documentDueDate: "",
  investment_type: "",
  documentRequests: [],
  areDocumentsCompleted: false,
  isLoanClosed: false,
  loan_status: "",
  workFlowType: "",
  lastUpdatedOn: "",
  manager: {
    id: 0,
    employerId: 0,
    role: "CREDIT_ANALYST",
    access: "" as LenderEmployeeAccess,
    isAccountOwner: false,
    name: "",
  },
  entities: [],
  documentClassificationStatus: [],
};

export const initialLoanState: PipelineIntakeState = {
  entities: {
    list: [],
    detail: initialLoanDetails,
    managementList: [],
    isLoading: true,
    isLoaded: false,
    annualReviewIsLoading: true,
    annualReviewIsLoaded: false,
  },
  loanStarted: false,
  draftLoans: [],
};

export const loanReducer: Reducer<PipelineIntakeState> = (state = initialLoanState, action) =>
  produce(state, (draft) => {
    const payload = action.payload as any;
    const payloads = action.payload as any[];
    switch (action.type) {
      case LoanAction.CREATE_LOAN_STARTED: {
        draft.currentLoanId = undefined;
        draft.loanStarted = true;
        break;
      }
      case LoanAction.CREATE_LOAN_ENDED: {
        draft.loanStarted = false;
        break;
      }
      case LoanAction.CREATE_LOAN: {
        draft.entities.isLoading = true;
        draft.currentLoanId = undefined;
        break;
      }
      case LoanAction.CREATE_LOAN_COMPLETED: {
        draft.entities.isLoading = false;
        draft.currentLoanId = payload.loanId;
        break;
      }
      case LoanAction.CREATE_LOAN_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
      case PipelineLoans.FETCH_LOANS: {
        draft.entities.isLoading = true;
        break;
      }
      case LoanAction.FETCH_DRAFT_LOANS: {
        draft.entities.isLoading = true;
        draft.draftLoans = [];
        break;
      }
      case LoanAction.FETCH_DRAFT_LOANS_COMPLETED: {
        draft.entities.isLoading = false;
        draft.draftLoans = payload;
        break;
      }
      case LoanAction.FETCH_DRAFT_LOANS_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
      case LoanAction.DELETE_DRAFT_LOAN: {
        draft.entities.isLoading = true;
        break;
      }
      case LoanAction.DELETE_DRAFT_LOAN_COMPLETED: {
        draft.entities.isLoading = false;
        draft.draftLoans = draft.draftLoans.filter((loan) => loan.id !== payload.loanId);
        break;
      }
      case LoanAction.DELETE_DRAFT_LOAN_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
      case LoanAction.CLEAR_LOAN_DETAIL: {
        draft.entities.detail = initialLoanDetails;
        break;
      }
      case PipelineLoans.SET_PIPELINE_SEARCH_PARAMS: {
        draft.searchParams = payload;
        break;
      }
      case PipelineLoans.FETCH_ANNUAL_REVIEWS: {
        draft.entities.annualReviewIsLoading = true;
        break;
      }
      case PipelineLoans.SET_LOANS: {
        draft.entities.isLoading = false;
        draft.entities.isLoaded = true;
        draft.entities.list = payload;
        break;
      }
      case PipelineLoans.SET_REVIEWS: {
        draft.entities.annualReviewIsLoading = false;
        draft.entities.annualReviewIsLoaded = true;
        draft.entities.list = payloads;
        break;
      }
      case PipelineLoans.SET_MANAGEMENT_DATA: {
        draft.entities.isLoading = false;
        draft.entities.managementList = payloads;
        break;
      }
      case PipelineLoans.FETCH_LOAN_DETAIL: {
        draft.entities.detail = initialLoanDetails;
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.SET_LOAN_DETAIL: {
        draft.entities.isLoading = false;
        draft.entities.detail = payload;
        upsertDetailIntoList(draft, payload);
        break;
      }
      case PipelineLoans.FETCH_ANNUAL_REVIEW_DETAIL: {
        draft.entities.detail = initialLoanDetails;
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.FETCH_ANNUAL_REVIEW_DETAIL_FAILED: {
        draft.entities.isLoading = false;
        draft.error = payload.message;
        break;
      }
      case AnnualReviewAction.CREATE_ANNUAL_REVIEW_COMPLETED: {
        draft.entities.isLoading = false;
        upsertDetailIntoList(draft, payload);
        // Important: we don't call draft.entities.detail = action.payload
        // here, because we got here by clicking on Set reporting sequence
        // from a loan, and we want to stay on the details page for that loan.
        draft.currentAnnualReviewId = payload.id;
        break;
      }
      case PipelineLoans.SET_ANNUAL_REVIEW_DETAIL: {
        draft.entities.isLoading = false;
        draft.entities.detail = payload;
        upsertDetailIntoList(draft, payload);
        break;
      }
      case PipelineLoans.CREATE_DOCUMENT_REQUEST: {
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.CREATE_DOCUMENT_REQUEST_COMPLETED: {
        draft.entities.isLoading = false;
        break;
      }
      case PipelineLoans.CREATE_DOCUMENT_REQUEST_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
      case PipelineLoans.CREATE_REPORTING_SEQUENCE: {
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.UPDATE_REPORTING_SEQUENCE: {
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.EDIT_REPORTING_SEQUENCE: {
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.DELETE_REPORTING_SEQUENCE: {
        draft.entities.isLoading = true;
        break;
      }
      case PipelineLoans.CREATE_REPORTING_SEQUENCE_COMPLETED: {
        draft.currentAnnualReviewId = payload.id;
        draft.entities.isLoading = false;
        break;
      }
      case PipelineLoans.CREATE_REPORTING_SEQUENCE_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
      case PipelineLoans.SET_UPLOADED_DOCUMENT: {
        const data = action.payload as UploadDocumentResponse;
        const loan = draft.entities.detail;

        const _docRequestIndex = loan.documentRequests.findIndex(
          (d) => d.id === data.documentRequestId.toString(),
        );

        let _docStatusIndex = -1;

        // Handle "Add any document"
        if (_docRequestIndex === -1) {
          const docReqIdx = loan.documentRequests.findIndex((d) => d.entityId === data.entityId);
          const newStatus: DocumentStatus = {
            frequencies: [
              {
                year: +data.documentType.year,
                rawDocumentUrl: data.documentUrl,
                lastUploadedOn: moment().format("YYYY-MM-DD hh:mm:ss"),
                status: DocumentUploadStatus.COLLECTED,
                documentUploadId: data.documentUploadId,
                documentRequestId: data.documentRequestId,
                month: data.documentType?.month || data.attributes?.month,
                quarter: data.documentType?.quarter || data.attributes?.quarter,
                autoClassification: null,
              },
            ],
            incompleteDocs: 0,
            documentRequestId: data.documentRequestId.toString(),
            docType: data.documentType.name,
            attributes: {
              year: +data.documentType.year,
              month: data.documentType?.month || data.attributes?.month,
              quarter: data.documentType?.quarter || data.attributes?.quarter,
            },
          };
          if (Array.isArray(draft.entities.detail.documentRequests[docReqIdx]?.docStatus)) {
            draft.entities.detail.documentRequests[docReqIdx].docStatus?.push(newStatus);
          } else {
            draft.entities.detail.documentRequests[docReqIdx].docStatus = [newStatus];
          }
          break;
        }
        const docRequestIndex =
          _docRequestIndex === -1
            ? // Handle "Add any document" .. continued..
              // Attach to existing docRequest of the same entity
              loan.documentRequests.findIndex((d) => d.entityId === data.entityId)
            : _docRequestIndex;
        const docRequest: DocumentDetails | undefined = loan.documentRequests[docRequestIndex];
        const docStatusIndex =
          _docStatusIndex !== -1
            ? _docStatusIndex
            : docRequest?.docStatus?.findIndex(
                (s) => s.documentRequestId == data.documentRequestId.toString(),
              ) || -1;
        const docStatus: DocumentStatus | undefined = docRequest.docStatus?.[docStatusIndex];
        const freqIndex =
          _docStatusIndex !== -1
            ? 0
            : docStatus?.frequencies.findIndex((f) => f.year === +data.documentType.year) || -1;
        // Create new entry
        if (freqIndex == -1 && docStatus) {
          docStatus.frequencies.push({
            year: +data.documentType.year,
            rawDocumentUrl: data.documentUrl,
            lastUploadedOn: moment().format("YYYY-MM-DD hh:mm:ss"),
            status: DocumentUploadStatus.COLLECTED,
            documentUploadId: data.documentUploadId,
            documentRequestId: data.documentRequestId,
            metadata: data.metadata,
            autoClassification: null,
          });
          const alertMessageParts = loan.alertMessage.split("/");
          const totalDocs = alertMessageParts[1].split(" ");
          alertMessageParts[0] = `${+alertMessageParts[0] + 1}`;
          totalDocs[0] = `${+totalDocs[0] + 1}`;
          loan.alertMessage = `${alertMessageParts[0]}/${totalDocs[0]} ${totalDocs[1]}`;

          const verifiedMessageParts = loan.verified_documents.split("/");
          const totalVerifiedDocs = verifiedMessageParts[1].split(" ");
          totalVerifiedDocs[0] = `${+totalVerifiedDocs[0] + 1}`;
          loan.verified_documents = `${verifiedMessageParts[0]}/${totalVerifiedDocs[0]} ${totalVerifiedDocs[1]}`;
        }
        // Update Existing document entry
        else if (docStatus) {
          // Add File Icon
          docStatus.frequencies[freqIndex].rawDocumentUrl = data.documentUrl;
          docStatus.frequencies[freqIndex].status = DocumentUploadStatus.COLLECTED;
          docStatus.incompleteDocs -= 1;
          let typeIncompleteDocs = 0;
          docStatus.frequencies.forEach((s) => {
            if (!s.rawDocumentUrl) {
              typeIncompleteDocs += 1;
            }
          });

          // Change completed documents at headers
          const alertMessageParts = loan.alertMessage.split("/");
          alertMessageParts[0] = `${+alertMessageParts[0] + 1}`;
          loan.alertMessage = alertMessageParts.join("/");
        } else {
          console.log("Document not found", data);
        }
        break;
      }
      case PipelineLoans.UPDATE_DOCUMENT_STATUS_COMPLETED: {
        const data = action.payload as any;
        draft.entities.detail = data;
        upsertDetailIntoList(draft, data);
        break;
      }
      case PipelineLoans.UPDATE_DOCUMENT_STATUS_FAILED: {
        draft.entities.isLoading = false;
        break;
      }
    }
  });

// Replace the LoanDetails in draft.entities.list with payload,
// or add it to the list if it's not there.
const upsertDetailIntoList = (draft: WritableDraft<PipelineIntakeState>, payload: LoanDetails) => {
  let added = false;
  draft.entities.list = draft.entities.list.map((investment: unknown) => {
    if (
      typeof investment === "object" &&
      investment !== null &&
      "id" in investment &&
      "investment_type" in investment &&
      investment.id === payload.id &&
      investment.investment_type === payload.investment_type
    ) {
      added = true;
      return payload;
    }
    return investment;
  });
  if (!added) {
    draft.entities.list = [...draft.entities.list, payload];
  }
};
