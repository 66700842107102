import { CreateOrUpdateMemo } from "src/models/Memo";
import { Memo } from "@prisma/client";
import { baseApiService } from "src/services/api-services/BaseApiService";

export class MemoService {
  static getInstance(): MemoService {
    return new MemoService();
  }

  async createMemo(data: CreateOrUpdateMemo): Promise<Memo | undefined> {
    const memo = await baseApiService.post("/api/memo", data);

    if (!memo) {
      return;
    }

    return memo;
  }

  async updateMemo(memoId: number, data: CreateOrUpdateMemo): Promise<Memo | undefined> {
    const response = await baseApiService.patch(`/api/memo/${memoId}`, data);

    if (!response) {
      return;
    }

    return response;
  }

  async deleteMemo(memoId: number): Promise<Memo | undefined> {
    const response = await baseApiService.delete(`/api/memo/${memoId}`);

    if (!response) {
      return;
    }

    return response;
  }

  async getMemoById(memoId: number): Promise<Memo | undefined> {
    const response = await baseApiService.get(`/api/memo/${memoId}`);

    if (!response) {
      return;
    }

    return response;
  }

  async getMemoByLoanId(loanId: number): Promise<Memo | undefined> {
    const response = await baseApiService.get("/api/memo", {
      params: {
        loanId: loanId.toString(),
      },
    });

    if (!response) {
      return;
    }

    return response;
  }

  async getMemoByReviewId(reviewId: number): Promise<Memo | undefined> {
    const response = await baseApiService.get("/api/memo", {
      params: {
        reviewId: reviewId.toString(),
      },
    });

    if (!response) {
      return;
    }

    return response;
  }
}
