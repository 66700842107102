import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  fetchLenderEmployeeActionComplete,
  fetchLenderSettingsComplete,
} from "src/redux/actions/lender.action";
import { lenderService } from "src/services/lender-service/LenderService";
import { LenderAction } from "../actions/actions.constants";
import {
  annualReviewDetailsSetAction,
  loanDetailsSetAction,
} from "src/redux/actions/pipelineLoans.action";
import { toastService } from "src/services/ToastService";
import { loanDetailSelector } from "src/redux/selectors/loan.selector";
import { LenderEmployee } from "src/models/LenderEmployee";
import { LenderSettings } from "@interfold-ai/db";

function* fetchLenderEmployeesByQuery(action: {
  type: string;
  payload: {
    query: string;
    lenderId: number | undefined;
  };
}): any {
  try {
    const response = yield call(lenderService.fetchLenderEmployeeByQuery, action.payload);
    yield put(fetchLenderEmployeeActionComplete(response));
  } catch (e: any) {
    console.log(e);
  }
}

const compareOldEmployeesToNewEmployees = (oldEmployees: any, newEmployees: any): void => {
  const oldEmployeeIds = oldEmployees.map((employee: LenderEmployee) => employee.id);
  const newEmployeeIds = newEmployees.map((employee: LenderEmployee) => employee.id);

  const addedIds = newEmployeeIds.filter((id: number) => !oldEmployeeIds.includes(id));
  const removedIds = oldEmployeeIds.filter((id: number) => !newEmployeeIds.includes(id));

  const addedEmployees = newEmployees.filter((employee: LenderEmployee) =>
    addedIds.includes(employee.id),
  );
  const removedEmployees = oldEmployees.filter((employee: LenderEmployee) =>
    removedIds.includes(employee.id),
  );

  if (addedEmployees.length) {
    toastService.showSuccess(
      `${addedEmployees
        .map((addedEmployee: LenderEmployee) => addedEmployee.name)
        .join(", ")} has been successfully added as${addedEmployees.map(
        (addedEmployee: LenderEmployee) =>
          addedEmployee.isAccountOwner ? " Account Owner" : " Collaborator",
      )} ${addedEmployees.length > 1 ? `respectively` : ``} to loan.`,
    );
  } else if (removedEmployees.length) {
    toastService.showSuccess(
      `${removedEmployees
        .map((removedEmployee: LenderEmployee) => removedEmployee.name)
        .join(", ")} has been successfully removed from loan.`,
    );
  } else {
    toastService.showSuccess("Lender Employee role's changed successfully");
  }
};

function* updateLoanLenderEmployee(action: {
  type: string;
  payload: {
    loanId: number;
    lenders: {
      lenderId: number;
      isAccountOwner: boolean;
    }[];
  };
}): any {
  try {
    const loan = yield select(loanDetailSelector);
    const response = yield call(lenderService.updateLoanLenderEmployee, action.payload);

    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse = {
      ...response.loan,
      documentRequests: response.loan?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(loanDetailsSetAction(updatedResponse));

    compareOldEmployeesToNewEmployees(loan.employees, updatedResponse.employees);
  } catch (e: any) {
    console.log(e);
    toastService.showError(e?.response.message);
  }
}

function* updateReviewLenderEmployee(action: {
  type: string;
  payload: {
    reviewId: number;
    lenders: {
      lenderId: number;
      isAccountOwner: boolean;
    }[];
  };
}): any {
  try {
    const review = yield select(loanDetailSelector);
    const response = yield call(lenderService.updateReviewLenderEmployee, action.payload);

    //these ids are added temporarily just to make sure formik works fine.
    const ids = [
      "N5hiohmBZNS8",
      "XLnbZDv0ABhj",
      "zaDzQrAJ20Zv",
      "r812fbFnRg3O",
      "K5FccVBuiRZh",
      "LVEl7sVphytB",
      " jHNa637uTFCY",
    ];
    const updatedResponse = {
      ...response.review,
      documentRequests: response.review?.documentRequests?.map((e: any, i: number) => {
        return { ...e, id: ids[i] };
      }),
    };
    yield put(annualReviewDetailsSetAction(updatedResponse));

    compareOldEmployeesToNewEmployees(review.employees, updatedResponse.employees);
  } catch (e: any) {
    console.log(e);
    toastService.showError(e?.response.message);
  }
}

function* fetchLenderSettings(): any {
  try {
    const response = yield call(lenderService.fetchLenderSettings);
    yield put(fetchLenderSettingsComplete(response));
  } catch (e: any) {
    console.log(e);
  }
}

function* updateLenderSettings(action: { type: string; payload: LenderSettings }): any {
  try {
    if (action.payload.id) {
      yield call(lenderService.updateLenderSettings, action.payload);
    } else {
      yield call(lenderService.createLenderSettings, action.payload);
    }
    const response = yield call(lenderService.fetchLenderSettings);
    yield put(fetchLenderSettingsComplete(response));
    toastService.showSuccess("Lender settings updated successfully");
  } catch (e: any) {
    if (!action.payload.id) {
      toastService.showError("Failed to update lender settings");
    } else {
      toastService.showError("Failed to create lender settings");
    }
    console.log(e);
  }
}

function* lender() {
  yield takeEvery(LenderAction.FETCH_LENDER_EMPLOYEE_BY_QUERY, fetchLenderEmployeesByQuery);
  yield takeEvery(LenderAction.UPDATE_LOAN_LENDER_EMPLOYEE, updateLoanLenderEmployee);
  yield takeEvery(LenderAction.UPDATE_REVIEW_LENDER_EMPLOYEE, updateReviewLenderEmployee);
  yield takeEvery(LenderAction.FETCH_LENDER_SETTINGS, fetchLenderSettings);
  yield takeEvery(LenderAction.UPDATE_LENDER_SETTINGS, updateLenderSettings);
}

export default lender;
