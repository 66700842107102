import { FinancialStatementItems } from "@interfold-ai/shared/models/FinancialStatement";
import { Rendered, RenderedDocOptions } from "src/classes/RenderedDoc";
import { GridState } from "../GridState";
import { ColDef } from "ag-grid-community";
import { convertToGridRows, RawFinancialStatementType } from "src/classes/RenderedDocuments/financial-statement-utils";

export abstract class FinancialStatementItemsRenderedDoc extends Rendered<FinancialStatementItems, string> {
  constructor(public items: FinancialStatementItems) {
    super(items);
    this.gridOptions = {
      supressContextMenu: true,
    };
  }

  get numberOfColumns() {
    return this.underlying.availablePeriods.length + 1;
  }

  get defaultRowLabels(): string[] {
    return Object.values(this.initialGridState).map((row) => row.rowDataArray[0]);
  }

  get highlightedRowLabels(): string[] {
    return Object.values(this.initialGridState)
      .filter((row) => row.row === "highlighted")
      .map((row) => row.rowDataArray[0]);
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  metadataRowLabels: string[] = [];

  get columnDefs(): ColDef[] {
    return Array.from({ length: this.numberOfColumns }, (_, index) => {
      const letter = this.colNumberToExcelCol(index + 1);
      return {
        headerName: letter,
        colId: letter,
        field: letter,
        sortable: false,
        flex: 1,
      };
    });
  }

  abstract get initialGridState(): GridState;
}


export class IncomeStatementRenderedDoc extends FinancialStatementItemsRenderedDoc {
  constructor(input: FinancialStatementItems) {
    super(input);
  }

  get initialGridState(): GridState {
    const rows = convertToGridRows(this.items, RawFinancialStatementType.IncomeStatement);
    return Object.fromEntries(
      rows.map((row, index) => {
        return [index, row];
      }),
    );
  }
}

export class BalanceSheetRenderedDoc extends FinancialStatementItemsRenderedDoc {
  constructor(input: FinancialStatementItems) {
    super(input);
  }

  get initialGridState(): GridState {
    const rows = convertToGridRows(this.items, RawFinancialStatementType.BalanceSheet);
    return Object.fromEntries(
      rows.map((row, index) => {
        return [index, row];
      }),
    );
  }
}

export class CashFlowStatementRenderedDoc extends FinancialStatementItemsRenderedDoc {
  constructor(input: FinancialStatementItems) {
    super(input);
  }

  get initialGridState(): GridState {
    const rows = convertToGridRows(this.items, RawFinancialStatementType.CashFlowStatement);
    return Object.fromEntries(
      rows.map((row, index) => {
        return [index, row];
      }),
    );
  }
}
