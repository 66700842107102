import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { FIRST_TRUST_LENDER_ID } from "@interfold-ai/shared/utils/constants";

export const Form1065CashFlowRowHeadings = {
  NetIncome: "Net income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  GainOrLossOnFixedAssetSale: "(Gain)/Loss on Fixed Asset Sale",
  ExtraordinaryGainOrLoss: "Extraordinary (Gain)/Loss *",
  BusinessCashFlowBeforeTax: "Business Cash Flow Before Tax",
  TotalTax: "Total tax",
  BusinessCashFlowAfterTax: "Business Cash Flow After Tax",
  LessDistributionsDividends: "Less: Distributions / Dividends",
  LessOtherCfAdjustment: "Less: Other CF Adjustment *",
  BusinessCashFlowAvailableForDebtService: "Business Cash Flow Available for Debt Service",
  DebtService: "Debt Service  ",
  ProjectedDebtService: "Projected Debt Service *",
  DebtServiceCoverageRatio: "Debt Service Coverage Ratio",
  StressScenarioDebtService: "Stress Scenario - Debt Service",
  ProjectedStressDebtService: "Projected Debt Service *",
  InterestRateStress: "Interest Rate Stress",
  HypotheticalDebtServiceCoverageRatio: "Hypothetical Debt Service Coverage Ratio",
};

export function buildForm1065CashFlow(
  data: Form1065,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilder<Form1065, typeof Form1065CashFlowRowHeadings> {
  const lenderId = data.lenderId;
  const builder = new AutoRenderedSheetBuilder(
    data,
    Form1065CashFlowRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ data, labels }) => [
      labels.NetIncome,
      data.schedules?.scheduleM1?.netIncomeLoss ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.Interest, data.deductions?.interest ?? 0])
    .addRow(({ data, labels }) => [labels.Depreciation, depreciationFormula(data)])
    .addRow(({ data, labels }) => [
      labels.Amortization,
      data.deductions?.amortization?.statementValue ?? 0,
    ]);

  if (lenderId === FIRST_TRUST_LENDER_ID) {
    builder.addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowBeforeTax,
      `=SUM(${columnReference(labels.NetIncome)}:${columnReference(labels.Amortization)})`,
    ]);
  } else {
    builder
      .addRow(({ data, labels }) => [
        labels.GainOrLossOnFixedAssetSale,
        !data.income?.netGainOrLoss ? 0 : data.income?.netGainOrLoss * -1,
      ])
      .addRow(({ labels }) => [labels.ExtraordinaryGainOrLoss, "0"])
      .addRow(({ labels, columnReference }) => [
        labels.BusinessCashFlowBeforeTax,
        `=SUM(${columnReference(labels.NetIncome)}:${columnReference(labels.ExtraordinaryGainOrLoss)})`,
      ]);
  }

  builder
    .addRow(({ labels }) => [labels.TotalTax, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAfterTax,
      `=${columnReference(labels.BusinessCashFlowBeforeTax)} - ${columnReference(labels.TotalTax)}`,
    ])
    .addRow(({ labels }) => [
      labels.LessDistributionsDividends,
      data.schedules?.scheduleM2?.distributionsCash ?? 0,
    ])
    .addRow(({ labels }) => [labels.LessOtherCfAdjustment, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAvailableForDebtService,
      `=${columnReference(labels.BusinessCashFlowAfterTax)} - ${columnReference(labels.LessDistributionsDividends)} - ${columnReference(labels.LessOtherCfAdjustment)}`,
    ])
    .addRow()
    .addRow(({ labels }) => [labels.DebtService, ""])
    .addRow(({ labels }) => [labels.ProjectedDebtService, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.DebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / ${columnReference(labels.ProjectedDebtService)}`,
    ])
    .addRow()
    .addRow(({ labels }) => [labels.StressScenarioDebtService, ""])
    .addRow(({ labels, columnReference }) => [
      labels.ProjectedStressDebtService,
      `=${columnReference(labels.ProjectedDebtService)}`,
    ])
    .addRow(({ labels }) => [labels.InterestRateStress, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.HypotheticalDebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / (${columnReference(labels.ProjectedStressDebtService, "end")} + ${columnReference(labels.InterestRateStress)})`,
    ]);

  return builder;
}

function depreciationFormula(data: Form1065): string {
  return `=${data.deductions?.depreciation ?? 0} + ${data.schedules?.scheduleK?.partnersDistributiveShareItems?.section179Deduction ?? 0} - ${data.schedules?.scheduleM1?.deductionsIncludedOnScheduleKDepreciation ?? 0} + ${data.schedules?.scheduleM1?.expensesRecordedOnBooksDepreciation ?? 0}`;
}
