import { Prisma } from "@interfold-ai/db";

// Prisma's "Enumerable" type is a union of the type and an array of the type, for some reason.
// Turn this into something we can spread.
export const arrayFromPrismaEnumerable = <T>(enumerable: Prisma.Enumerable<T> | undefined): T[] => {
  if (enumerable == null) {
    return [];
  } else if (Array.isArray(enumerable)) {
    return enumerable;
  } else {
    return [enumerable];
  }
};

export const convertJsonValueToRecord = (jsonValue: Prisma.JsonValue): Record<string, any> => {
  if (typeof jsonValue === "object" && jsonValue !== null) {
    return jsonValue as Record<string, any>;
  } else {
    return {};
  }
};
