import { baseApiService } from "src/services/api-services/BaseApiService";
import { Loan } from "src/models/Loan";
import {
  convertAnnualReviewsToLoanCardData,
  loanService,
} from "src/services/loan-services/LoanService";
import { LenderSettings } from "@interfold-ai/db";

export class LenderService {
  static getInstance(): LenderService {
    return new LenderService();
  }

  async fetchLenderEmployeeByQuery(payload: {
    query: string;
    lenderId: number | undefined;
  }): Promise<any> {
    let URL = `/api/lender-employee?searchQuery=${payload.query}`;
    if (payload.lenderId) {
      URL += `&lenderId=${payload.lenderId}`;
    }
    return (await baseApiService.get(URL)).data;
  }

  async updateLoanLenderEmployee(payload: {
    loanId: number;
    lenders: { lenderId: number; isAccountOwner: boolean }[];
  }): Promise<any> {
    const loan = (await baseApiService.post(`/api/lender-employee`, payload)).loan;
    const loanDetail = loanService.convertLoanResponseToLoanCardData([loan as Loan]);
    return {
      loan: loanDetail[0],
    };
  }

  async fetchLenderByUserCode(payload: { code: string }): Promise<any> {
    const lender = (await baseApiService.get(`/api/customer-user?code=${payload.code}`)).lender;
    return {
      lender: lender,
    };
  }

  async updateReviewLenderEmployee(payload: {
    reviewId: number;
    lenders: { lenderId: number; isAccountOwner: boolean }[];
  }): Promise<any> {
    const review = (await baseApiService.post(`/api/lender-employee`, payload)).review;
    const reviewDetail = convertAnnualReviewsToLoanCardData([review]);
    return {
      review: reviewDetail[0],
    };
  }

  async fetchLenderSettings(): Promise<LenderSettings[]> {
    return baseApiService.get("/api/lender/settings");
  }

  async createLenderSettings(payload: LenderSettings): Promise<LenderSettings> {
    return baseApiService.post("/api/lender/settings", payload);
  }

  async updateLenderSettings(payload: LenderSettings): Promise<LenderSettings> {
    return baseApiService.put("/api/lender/settings", payload);
  }
}
export const lenderService = LenderService.getInstance();
