import { AppState } from "src/redux/reducers";

export const intakeStateSelector = (state: AppState) => state.intake;
export const loanStateSelector = (state: AppState) => state.loan;
export const lenderStateSelector = (state: AppState) => state.lender;
export const annualReviewStateSelector = (state: AppState) => state.annualReview;
export const interfoldUserSelector = (state: AppState) => state.interfoldUser;
export const contactsSelector = (state: AppState) => state.contacts;
export const spreadSelector = (state: AppState) => state.spread;
export const featureFlagSelector = (state: AppState) => state.featureFlags;
export const reportingStateSelector = (state: AppState) => state.reporting;
export const ocrStateSelector = (state: AppState) => state.ocr;
export const loanWorkflowStateSelector = (state: AppState) => state.loanWorkflow;
export const managementStateSelector = (state: AppState) => state.management;
export const documentAndReminderStateSelector = (state: AppState) => state.documentAndReminder;
export const documentsStateSelector = (state: AppState) => state.documents;
export const memoQuestionnaireStateSelector = (state: AppState) => state.memoQuestionnaire;
