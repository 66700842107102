import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { SupportedLenderId, supportedLenderIds } from "@interfold-ai/shared/models/SpreadsConfig"
import spreadConfig from "@interfold-ai/shared/spreads-config";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { HoverLabel } from "src/classes/RenderedDoc";

export const Form1120sCashFlowRowHeadings = {
  NetIncome: "Net income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  GainLossOnFixedAssetSale: "(Gain)/Loss on fixed asset sale",
  ExtraordinaryGainLoss: "Extraordinary (gain)/loss *",
  BusinessCashFlowBeforeTax: "Business cash flow before tax",
  TotalTax: "Total tax",
  BusinessCashFlowAfterTax: "Business cash flow after tax",
  LessDistributionsDividends: "Less: Distributions / Dividends",
  LessOtherCfAdjustment: "Less: Other CF adjustment *",
  BusinessCashFlowAvailableForDebtService: "Business cash flow available for debt service",
  DebtService: "Debt service  ",
  ProjectedDebtService: "Projected debt service",
  DebtServiceCoverageRatio: "Debt service coverage ratio",
  StressScenarioDebtService: "Stress scenario - debt service",
  InterestRateStress: "Interest rate stress",
  HypotheticalDebtServiceCoverageRatio: "Hypothetical debt service coverage ratio",
};

export const buildForm1120sCashFlowOrig = (
  data: Form1120S,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilder<Form1120S, typeof Form1120sCashFlowRowHeadings> => {
  const hideAsterisks = supportedLenderIds.find((l) => l === data.lenderId)
    ? spreadConfig.lenderSettings[data.lenderId as SupportedLenderId]?.tempHideManualRows
    : false;
  const asterisk = (header: string) => (hideAsterisks ? header.replace(" *", "") : header);

  return new AutoRenderedSheetBuilder(
    data,
    Form1120sCashFlowRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ data, labels }) => [
      labels.NetIncome,
      data.schedules?.scheduleM1?.netIncomeLoss ?? 0,
    ])
    .addRow(({ data, labels }) => [labels.Interest, data.deductions?.interest ?? 0])
    .addRow(({ data, labels }) => [labels.Depreciation, depreciationFormula(data)])
    .addRow(({ data, labels }) => [
      labels.Amortization,
      data.deductions?.amortization?.statementValue ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.GainLossOnFixedAssetSale,
      !data.income?.netGainOrLossFromForm4797 ? 0 : data.income?.netGainOrLossFromForm4797 * -1,
    ])
    .addRow(({ labels }) => [asterisk(labels.ExtraordinaryGainLoss), "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowBeforeTax,
      `=SUM(${columnReference(labels.NetIncome)}:${columnReference(asterisk(labels.ExtraordinaryGainLoss))})`,
    ])
    .addRow(({ labels }) => [labels.TotalTax, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAfterTax,
      `=${columnReference(labels.BusinessCashFlowBeforeTax)} - ${columnReference(labels.TotalTax)}`,
    ])
    .addRow(({ labels, data }) => [
      labels.LessDistributionsDividends,
      data.schedules?.scheduleM2?.distributions?.accumulatedAdjustments ?? 0,
    ])
    .addRow(({ labels }) => [asterisk(labels.LessOtherCfAdjustment), "0"])
    .addRow(({ labels, columnReference }) => [
      labels.BusinessCashFlowAvailableForDebtService,
      `=${columnReference(labels.BusinessCashFlowAfterTax)} - ${columnReference(labels.LessDistributionsDividends)} - ${columnReference(asterisk(labels.LessOtherCfAdjustment))}`,
    ])

    .addRow(() => ["", ""])
    .addRow(({ labels }) => [labels.DebtService, ""])
    .addRow(({ labels }) => [labels.ProjectedDebtService, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.DebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / ${columnReference(labels.ProjectedDebtService)}`,
    ])

    .addRow(() => ["", ""])
    .addRow(({ labels }) => [labels.StressScenarioDebtService, ""])
    .addRow(({ labels, columnReference }) => [
      labels.ProjectedDebtService,
      `=${columnReference(labels.ProjectedDebtService)}`,
    ])
    .addRow(({ labels }) => [labels.InterestRateStress, "0"])
    .addRow(({ labels, columnReference }) => [
      labels.HypotheticalDebtServiceCoverageRatio,
      `=${columnReference(labels.BusinessCashFlowAvailableForDebtService)} / (${columnReference(labels.ProjectedDebtService, "end")} + ${columnReference(labels.InterestRateStress)})`,
    ]);
};

function depreciationFormula(data: Form1120S): string {
  // Note: The field data.form4562?.section179Deduction is intentionally omitted
  // because we believe it represents the same data as data.schedules?.scheduleK?.section179Deduction.
  return `=${data.deductions?.depreciation ?? 0} + ${data.schedules?.scheduleK?.section179Deduction ?? 0} - ${data.schedules?.scheduleM1?.deductionsOnReturnDepreciation ?? 0} + ${data.schedules?.scheduleM1?.expensesRecordedOnBooksDepreciation ?? 0}`;
}
export const buildForm1120sCashFlow = (
  data: RenderableWithConfidence<Form1120S>,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilderWithConfidence<Form1120S, typeof Form1120sCashFlowRowHeadings> => {
  const hideAsterisks = supportedLenderIds.includes(data.renderable.lenderId as SupportedLenderId)
    ? spreadConfig.lenderSettings[data.renderable.lenderId as SupportedLenderId]?.tempHideManualRows
    : false;
  const asterisk = (header: string) => (hideAsterisks ? header.replace(" *", "") : header);

  const ret = AutoRenderedSheetBuilderWithConfidence.from(
    data,
    Form1120sCashFlowRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRowFromData("NetIncome", (data) => data.schedules?.scheduleM1?.netIncomeLoss ?? 0)
    .addRowFromData("Interest", (data) => data.deductions?.interest ?? 0)
    .addRowWithFormula("Depreciation", (finders, labels, data) => {
      const depreciation = data.deductions?.depreciation ?? 0;
      const section179 = data.schedules?.scheduleK?.section179Deduction ?? 0;
      const deductionsDepreciation =
        data.schedules?.scheduleM1?.deductionsOnReturnDepreciation ?? 0;
      const expensesDepreciation =
        data.schedules?.scheduleM1?.expensesRecordedOnBooksDepreciation ?? 0;
      return `=${depreciation} + ${section179} - ${deductionsDepreciation} + ${expensesDepreciation}`;
    })
    .addRowFromData("Amortization", (data) => data.deductions?.amortization?.statementValue ?? 0)
    .addRowFromData("GainLossOnFixedAssetSale", (data) => {
      const gainLoss = data.income?.netGainOrLossFromForm4797 ?? 0;
      return gainLoss ? gainLoss * -1 : 0;
    })
    .addRowWithStaticValue(asterisk(Form1120sCashFlowRowHeadings.ExtraordinaryGainLoss), "0")
    .addRowWithFormula("BusinessCashFlowBeforeTax", (finders, labels) => {
      const startCell = finders.columnReference(labels.NetIncome);
      const endCell = finders.labelReference(
        asterisk(Form1120sCashFlowRowHeadings.ExtraordinaryGainLoss),
      );
      return `=SUM(${startCell}:${endCell})`;
    })
    .addRowWithStaticValue("TotalTax", "0")
    .addRowWithFormula("BusinessCashFlowAfterTax", (finders, labels) => {
      const beforeTax = finders.columnReference(labels.BusinessCashFlowBeforeTax);
      const totalTax = finders.labelReference(labels.TotalTax);
      return `=${beforeTax} - ${totalTax}`;
    })
    .addRowFromData(
      "LessDistributionsDividends",
      (data) => data.schedules?.scheduleM2?.distributions?.accumulatedAdjustments ?? 0,
    )
    .addRowWithStaticValue(asterisk(Form1120sCashFlowRowHeadings.LessOtherCfAdjustment), "0")
    .addRowWithFormula("BusinessCashFlowAvailableForDebtService", (finders, labels) => {
      const afterTax = finders.columnReference(labels.BusinessCashFlowAfterTax);
      const distributions = finders.columnReference(labels.LessDistributionsDividends);
      const otherAdj = finders.labelReference(
        asterisk(Form1120sCashFlowRowHeadings.LessOtherCfAdjustment),
      );
      return `=${afterTax} - ${distributions} - ${otherAdj}`;
    })
    .addEmptyRow()
    .addRowWithStaticValue("DebtService", "")
    .addRowWithStaticValue("ProjectedDebtService", "0")
    .addRowWithFormula("DebtServiceCoverageRatio", (finders, labels) => {
      const availableCashFlow = finders.columnReference(
        labels.BusinessCashFlowAvailableForDebtService,
      );
      const projectedDebtService = finders.columnReference(labels.ProjectedDebtService);
      return `=${availableCashFlow} / ${projectedDebtService}`;
    })
    .addEmptyRow()
    .addRowWithStaticValue("StressScenarioDebtService", "")
    .addRowWithFormula("ProjectedDebtService", (finders, labels) => {
      const projectedDebtService = finders.columnReference(labels.ProjectedDebtService);
      return `=${projectedDebtService}`;
    })
    .addRowWithStaticValue("InterestRateStress", "0")
    .addRowWithFormula("HypotheticalDebtServiceCoverageRatio", (finders, labels) => {
      const availableCashFlow = finders.columnReference(
        labels.BusinessCashFlowAvailableForDebtService,
      );
      const projectedDebtServiceEnd = finders.columnReference(labels.ProjectedDebtService, "end");
      const interestRateStress = finders.columnReference(labels.InterestRateStress);
      return `=${availableCashFlow} / (${projectedDebtServiceEnd} + ${interestRateStress})`;
    });

  ret.setHoverInfoForLabel(
    Form1120sCashFlowRowHeadings.Depreciation,
    HoverLabel.from(
      `Depreciation = Depreciation + Section 179 Deduction - Deductions on Return Depreciation + Expenses Recorded on Books Depreciation`,
    ),
  );
  return ret;
};
