import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { Form1120 } from "@interfold-ai/shared/models/tax/Form1120";
import { TaxFormData, TaxFormYear } from "@interfold-ai/shared/models/tax/common";
import { RenderedWorkflow } from "src/classes/RenderedDocuments/RenderedWorkflow";
import type { ColDef } from "ag-grid-community";
import { BoundingBoxContext, GridState } from "src/classes/GridState";
import { ExtractableDocumentType } from "@interfold-ai/shared/enums/ExtractableDocumentType";
import { PersonalCashFlowData } from "@interfold-ai/shared/models/PersonalCashFlow";
import { Form1120sIncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatementGrouped";
import { ScheduleK1 } from "@interfold-ai/shared/models/tax/schedules/ScheduleK1";
import { ScheduleK1Rendered } from "src/classes/RenderedDocuments/ScheduleK1Rendered";
import { TabName } from "src/redux/reducers/types";
import { Corporation, Person } from "@interfold-ai/shared/models/tax/common";
import { ScheduleCBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleCBreakoutRendered";
import { ScheduleEBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleEBreakoutRendered";
import { capitalizeFirstLetterOfEachWord } from "@interfold-ai/shared/extractors/helpers";
import { Form1120sBalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlowGrouped";
import { Form1120sRatiosGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sRatiosGrouped";
import { Form1065BalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065BalanceSheetGrouped";
import { Form1065CashFlowGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065CashFlowGrouped";
import { Form1065IncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatementGrouped";
import { Form1065RatiosGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065RatiosGrouped";
import { HoverInfo, RenderedDocOptions } from "src/classes/RenderedDoc";
import { Form8825BreakoutRendered } from "src/classes/RenderedDocuments/Form8825BreakoutRendered";
import {
  Form1040Grouped,
  Form1040WithTabNames,
} from "src/classes/RenderedDocuments/Form1040/Form1040Grouped";
import {
  buildGlobalCashFlow,
  Form1040RollUpAndTabName,
  Form1065CashFlowAndTabName,
  Form1120sCashFlowAndTabName,
} from "src/classes/RenderedDocuments/GlobalCashFlow/GlobalCashFlow";
import { ContextedBy } from "@interfold-ai/shared/models/extract/common";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";
import { RenderableWithConfidence } from "./TaxFormWithConfidence";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableBase } from "@interfold-ai/shared/models/render/common";
import { sanitizeTabName } from "src/classes/RenderedDocuments/utils";
import { FinancialStatementItems } from "@interfold-ai/shared/models/FinancialStatement";
import {
  IncomeStatementRenderedDoc,
  BalanceSheetRenderedDoc,
  CashFlowStatementRenderedDoc,
} from "src/classes/RenderedDocuments/FinancialStatementItemsRenderedDoc";
import {
  TabGroup,
  GeneralSpreadsTabTypeEnum,
  GeneralSpreadsTabGroupEnum,
} from "src/classes/RenderedDocuments/helpers";
import {
  buildCashFlowFromFinancialsTab,
  cashFlowCandidateRows,
  adjustInterestValuesForCashFlow,
  combineFinancialStatementItems,
  cashFlowCandidateRowsFromItems,
} from "src/classes/RenderedDocuments/financial-statement-utils";

export type GeneralSpreadsData = {
  [key: TabName]: {
    output: TaxFormData;
    source: ExtractableDocumentType;
    geometry?: ContextedBy<TaxFormData, BoundingBoxContext>;
    confidence?: ContextedBy<TaxFormData, number>;
  };
};

export class RenderedGeneralSpreads extends RenderedWorkflow {
  colDefs: Map<TabName, ColDef<any, any>[]> = new Map();
  gridStates: Map<TabName, GridState> = new Map();
  gridOptions: Map<TabName, RenderedDocOptions> = new Map();
  confidences: Map<TabName, RawConfidenceContent[][]> = new Map();
  tabGroups: TabGroup[] = [];
  hoverInfoData: Map<TabName, HoverInfo[][]> = new Map();
  form1040s: RenderableWithConfidence<Form1040>[] = [];
  form1065s: Form1065[] = [];
  form1120Ss: RenderableWithConfidence<Form1120S>[] = [];
  form1120: Form1120[] = [];
  formk1: ScheduleK1[] = [];
  financialStatements: FinancialStatementItems[] = [];
  legacyPersonalData: PersonalCashFlowData[] = [];

  get tabs(): TabName[] {
    return Array.from(this.gridStates.keys());
  }

  constructor(public generalSpreads: GeneralSpreadsData) {
    super();
    Object.values(generalSpreads).forEach((extract) => this.placeForm(extract as ExtractOutput));

    const form1040Rollups = this.process1040();
    const form1065CashFlows = this.process1065();
    const form1120sCashFlows = this.process1120s();

    if (
      form1040Rollups.length > 0 ||
      form1065CashFlows.length > 0 ||
      form1120sCashFlows.length > 0
    ) {
      this.processGlobalCashFlow(form1040Rollups, form1065CashFlows, form1120sCashFlows);
    }

    this.processScheduleK1();
    this.process8825();
    this.processFinancialStatements();
  }

  private placeForm(extract: ExtractOutput) {
    const { output, source, confidence, geometry } = extract;

    if (source === ExtractableDocumentType.FINANCIALS) {
      this.financialStatements.push(output as FinancialStatementItems);
      return;
    }
    if (source === ExtractableDocumentType.SCHEDULE_K1 && Array.isArray(output)) {
      const k1s = output as ScheduleK1[];
      this.formk1.push(...k1s);
    }

    const taxFormData = output as TaxFormData;

    switch (taxFormData.form) {
      case GeneralSpreadsTabGroupEnum.Form1040: {
        const renderable = taxFormData as Form1040;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1040, number>,
        };
        this.form1040s.push(res);
        if (renderable.relatedK1s) {
          this.formk1.push(...renderable.relatedK1s);
        }
        return;
      }
      case GeneralSpreadsTabGroupEnum.Form1065:
        const form1065 = taxFormData as Form1065;
        this.form1065s.push(form1065);
        if (form1065.schedules?.scheduleK1) {
          this.formk1.push(...form1065.schedules.scheduleK1);
        }
        return;
      case GeneralSpreadsTabGroupEnum.Form1120S: {
        const renderable = taxFormData as Form1120S;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1120S, number>,
        };

        this.form1120Ss.push(res);

        if (renderable.schedules?.scheduleK1) {
          this.formk1.push(...renderable.schedules.scheduleK1);
        }

        return;
      }
      case GeneralSpreadsTabGroupEnum.Form1120:
        this.form1120.push(taxFormData as Form1120);
        return;
    }
  }

  private addTab(
    tabName: TabName,
    content: RenderedContent,
    group: GeneralSpreadsTabGroupEnum,
    tabType: GeneralSpreadsTabTypeEnum,
  ) {
    const { gridState, columnDefs, hoverInfos, gridOptions } = content;
    const sanitizedTabName = sanitizeTabName(tabName) as TabName;
    const confidence = content.asConfidence?.() || [];
    this.gridStates.set(sanitizedTabName, gridState);
    this.colDefs.set(sanitizedTabName, columnDefs);
    this.gridOptions.set(sanitizedTabName, gridOptions);
    this.tabGroups.push({
      tabName: sanitizedTabName,
      fullTabName: tabName,
      group,
      tabType,
    });
    this.hoverInfoData.set(tabName, hoverInfos);
    this.confidences.set(tabName, confidence || []);
  }

  //): rowBuilder is RowBuilder<T, L> => AutoRenderedSheetBuilder<T, L> {
  //): AutoRenderedSheetBuilder<T, L> {
  private process1040() {
    const taxpayerForms: Map<string, RenderableWithConfidence<Form1040>[]> = new Map();
    this.form1040s.forEach((form1040WithConfidence) => {
      const { renderable: form1040 } = form1040WithConfidence;
      const name = normalizeTaxpayerName(form1040.taxpayer, form1040.entityName);
      const forms = taxpayerForms.get(name) || [];
      taxpayerForms.set(name, [...forms, form1040WithConfidence]);
    });

    const form1040Rollups: Form1040RollUpAndTabName[] = [];
    taxpayerForms.forEach((wcs, taxpayer) => {
      const form1040Rollup = this.create1040Rollup(wcs, taxpayer);
      this.createScheduleCBreakout(wcs, taxpayer);
      this.createScheduleEBreakout(wcs, taxpayer);
      form1040Rollups.push(form1040Rollup);
    });
    return form1040Rollups;
  }

  private processFinancialStatements() {
    const financialStatements = this.financialStatements;
    const statementsByEntity = new Map<number, FinancialStatementItems[]>();

    financialStatements.forEach((statement) => {
      const entityId = statement.entityId || 0;
      const statements = statementsByEntity.get(entityId) || [];
      statementsByEntity.set(entityId, [...statements, statement]);
    });

    statementsByEntity.forEach((statements, _entityId) => {
      const combinedStatement = combineFinancialStatementItems(statements);
      if (!combinedStatement) {
        return;
      }

      const isTabName = `Financials IS - ${combinedStatement.entityName}` as TabName;
      const isRendered = new IncomeStatementRenderedDoc(combinedStatement);
      if (isRendered.items?.incomeStatement?.length > 0) {
        this.addTab(
          isTabName,
          isRendered,
          GeneralSpreadsTabGroupEnum.Financials,
          GeneralSpreadsTabTypeEnum.FINANCIALS,
        );
      }

      const bsTabName = `Financials BS - ${combinedStatement.entityName}` as TabName;
      const bsRendered = new BalanceSheetRenderedDoc(combinedStatement);
      if (bsRendered.items?.balanceSheet?.length > 0) {
        this.addTab(
          bsTabName,
          bsRendered,
          GeneralSpreadsTabGroupEnum.Financials,
          GeneralSpreadsTabTypeEnum.FINANCIALS,
        );
      }

      const csTabName = `Financials CF - ${combinedStatement.entityName}` as TabName;
      const csRendered = new CashFlowStatementRenderedDoc(combinedStatement);
      if (csRendered.items?.cashFlowStatement?.length > 0) {
        this.addTab(
          csTabName,
          csRendered,
          GeneralSpreadsTabGroupEnum.Financials,
          GeneralSpreadsTabTypeEnum.FINANCIALS,
        );
      }

      if (isRendered.items?.incomeStatement?.length > 0) {
        const cfTabName = `CF Computed - ${combinedStatement.entityName}` as TabName;
        const gridRows = Object.values(isRendered.initialGridState);
        const candidateRows = combinedStatement.calculatedCashFlowItems
          ? cashFlowCandidateRowsFromItems(gridRows, combinedStatement.calculatedCashFlowItems)
          : cashFlowCandidateRows(gridRows);
        adjustInterestValuesForCashFlow(candidateRows, gridRows);

        if (Object.values(candidateRows).some((rows) => rows.length > 0)) {
          const cfRendered = buildCashFlowFromFinancialsTab(
            candidateRows,
            combinedStatement.availablePeriods,
            isTabName,
          );
          this.addTab(
            cfTabName,
            cfRendered,
            GeneralSpreadsTabGroupEnum.Financials,
            GeneralSpreadsTabTypeEnum.FINANCIALS_CASH_FLOW,
          );
        }
      }
    });
  }

  private createScheduleCBreakout(wcs: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    wcs.forEach((wc) => {
      const scheduleCs = wc.renderable.schedules?.scheduleC;
      if (!scheduleCs?.length) {
        return;
      }
      const scheduleCBreakout = new ScheduleCBreakoutRendered(scheduleCs);
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT,
        taxpayer,
        wc.renderable.year,
      );
      this.addTab(
        tabName,
        scheduleCBreakout,
        GeneralSpreadsTabGroupEnum.Form1040,
        GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT,
      );
    });
  }

  private createScheduleEBreakout(forms: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    forms.forEach((wc) => {
      const { renderable: form } = wc;
      if (
        form.schedules?.scheduleE?.properties === undefined ||
        form.schedules?.scheduleE.properties.length === 0
      ) {
        return;
      }
      const scheduleEBreakout = new ScheduleEBreakoutRendered(form.schedules?.scheduleE);
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT,
        taxpayer,
        form.year,
      );
      this.addTab(
        tabName,
        scheduleEBreakout,
        GeneralSpreadsTabGroupEnum.Form1040,
        GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT,
      );
    });
  }

  private k1TotalFor1040(form: Form1040) {
    // total schedule k1 by year
    const scheduleK1Totals: Map<TaxFormYear, number> = new Map();
    this.formk1.forEach((k1) => {
      const formTaxpayer = normalizeTaxpayerName(form.taxpayer, "");
      const k1Taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
      if (k1Taxpayer.toLowerCase() === formTaxpayer.toLowerCase()) {
        const total = scheduleK1Totals.get(k1.year) || 0;
        const dist = k1.distributions || 0;
        const contrib = k1.contributions || 0;
        scheduleK1Totals.set(k1.year, total + dist - contrib);
      }
    });

    return scheduleK1Totals.get(form.year) || 0;
  }

  private create1040Rollup(
    formsWithConfidence: RenderableWithConfidence<Form1040>[],
    taxpayer: string,
  ) {
    const formsWithBreakoutTabNames = formsWithConfidence
      .sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year))
      .map((wc) => {
        const { renderable: form } = wc;
        const scheduleETabName = form.schedules?.scheduleE
          ? this.formatTabName(GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT, taxpayer, form.year)
          : null;
        const scheduleCTabName = form.schedules?.scheduleC?.length
          ? this.formatTabName(GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT, taxpayer, form.year)
          : null;
        const k1DistributionTotal = this.k1TotalFor1040(form);

        return {
          wc,
          k1DistributionTotal,
          scheduleETabName,
          scheduleCTabName,
        } as Form1040WithTabNames;
      });

    const form1040Rollup = new Form1040Grouped(formsWithBreakoutTabNames);

    const tabName = this.formatTabName(GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS, taxpayer);
    this.addTab(
      tabName,
      form1040Rollup,
      GeneralSpreadsTabGroupEnum.Form1040,
      GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: form1040Rollup,
      entityName: taxpayer,
    };
  }

  private groupContextedFormsByNormalizedName<TForm extends RenderableBase>(
    forms: RenderableWithConfidence<TForm>[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, RenderableWithConfidence<TForm>[]> {
    const groupedForms: Map<string, RenderableWithConfidence<TForm>[]> = new Map();
    forms.forEach((formWithConfidence) => {
      const form = formWithConfidence.renderable;
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, formWithConfidence]);
    });
    return groupedForms;
  }

  private groupFormsByNormalizedName<TForm>(
    forms: TForm[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, TForm[]> {
    const groupedForms: Map<string, TForm[]> = new Map();
    forms.forEach((form) => {
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, form]);
    });
    return groupedForms;
  }

  private process1120s() {
    const sCorpForms = this.groupContextedFormsByNormalizedName(
      this.form1120Ss,
      (form) => form.corporation,
    );
    const form1120sCashFlows: Form1120sCashFlowAndTabName[] = [];
    sCorpForms.forEach((formsWithConfidence) => {
      const forms = formsWithConfidence.map((wc) => wc.renderable);
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.corporation);

      const incomeStatement = this.create1120sIncomeStatement(formsWithConfidence, corporationName);
      const balanceSheet = this.create1120sBalanceSheet(formsWithConfidence, corporationName);
      const cashflow = this.create1120sCashFlow(formsWithConfidence, corporationName);

      const ratiosRendered = new Form1120sRatiosGrouped(
        formsWithConfidence,
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS, corporationName),
        balanceSheet,
        incomeStatement,
        cashflow.cashFlow,
      );
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
        corporationName,
      );
      this.addTab(
        tabName,
        ratiosRendered,
        GeneralSpreadsTabGroupEnum.Form1120S,
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
      );
      form1120sCashFlows.push(cashflow);
    });
    return form1120sCashFlows;
  }

  private process1065() {
    const partnershipForms = this.groupFormsByNormalizedName(
      this.form1065s,
      (form) => form.partnership,
    );
    const form1065CashFlows: Form1065CashFlowAndTabName[] = [];
    partnershipForms.forEach((forms) => {
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.partnership);

      const incomeStatement = this.create1065IncomeStatement(forms, corporationName);
      const balanceSheet = this.create1065BalanceSheet(forms, corporationName);
      const cashFlow = this.create1065CashFlow(forms, corporationName);
      form1065CashFlows.push(cashFlow);

      const ratiosRendered = new Form1065RatiosGrouped(
        forms,
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS, corporationName),
        balanceSheet,
        incomeStatement,
        cashFlow.cashFlow,
      );
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
        corporationName,
      );
      this.addTab(
        tabName,
        ratiosRendered,
        GeneralSpreadsTabGroupEnum.Form1065,
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
      );
    });
    return form1065CashFlows;
  }

  processGlobalCashFlow(
    form1040Rollups: Form1040RollUpAndTabName[],
    form1065CashFlows: Form1065CashFlowAndTabName[],
    form1120sCashFlows: Form1120sCashFlowAndTabName[],
  ) {
    const globalCashFlow = buildGlobalCashFlow(
      form1120sCashFlows,
      form1065CashFlows,
      form1040Rollups,
    );
    const tabName = GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW.valueOf() as TabName;
    this.addTab(
      tabName,
      globalCashFlow,
      GeneralSpreadsTabGroupEnum.Global,
      GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW,
    );
  }

  private create1065IncomeStatement(forms: Form1065[], corporation: string) {
    const rendered = new Form1065IncomeStatementGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
    );
    return rendered;
  }

  private create1065BalanceSheet(forms: Form1065[], corporation: string) {
    const rendered = new Form1065BalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
    );
    return rendered;
  }

  private create1065CashFlow(forms: Form1065[], corporation: string) {
    const rendered = new Form1065CashFlowGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private create1120sIncomeStatement(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ) {
    const rendered = new Form1120sIncomeStatementGrouped(forms);
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
    );
    return rendered;
  }

  private create1120sBalanceSheet(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ): Form1120sBalanceSheetGrouped {
    const rendered = new Form1120sBalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
    );
    return rendered;
  }

  private create1120sCashFlow(forms: RenderableWithConfidence<Form1120S>[], corporation: string) {
    const rendered = new Form1120sCashFlowGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private process8825() {
    this.form1065s.forEach((form, index) => {
      if (form.form8825) {
        const name = form?.partnership?.name ?? `Partnership ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName(
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
          name,
          form.form8825.year,
        );
        this.addTab(
          tabName,
          rendered,
          GeneralSpreadsTabGroupEnum.Form1065,
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
        );
      }
    });
    this.form1120Ss.forEach((formWithConfidence, index) => {
      const form = formWithConfidence.renderable;
      if (form.form8825) {
        const name = form?.corporation?.name ?? `Corporation ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName(
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
          name,
          form.form8825.year,
        );
        this.addTab(
          tabName,
          rendered,
          GeneralSpreadsTabGroupEnum.Form1120S,
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
        );
      }
    });
  }

  private processScheduleK1() {
    const taxpayerYears: String[] = [];
    this.formk1.forEach((form) => {
      if (!taxpayerYears.includes(form.year)) {
        const taxpayer = normalizeTaxpayerName(form.taxpayer, form.entityName);
        taxpayerYears.push(`${form.year}-${taxpayer}`);
      }
    });

    taxpayerYears.sort((a, b) => (a > b ? 1 : -1));
    for (const taxpayerYear of taxpayerYears) {
      const taxPayersK1ThisYear = this.formk1.filter((k1) => {
        const taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
        return taxpayerYear === `${k1.year}-${taxpayer}`;
      });
      const [taxformyear, entityName, ..._parts] = taxpayerYear.split("-");
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR,
        entityName,
        taxformyear as TaxFormYear,
      );
      const rendered = new ScheduleK1Rendered(taxPayersK1ThisYear, taxpayerYear as TaxFormYear);
      this.addTab(
        tabName,
        rendered,
        GeneralSpreadsTabGroupEnum.K1,
        GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR,
      );
    }
  }

  public formatTabName(
    tabType: GeneralSpreadsTabTypeEnum,
    entityName?: string,
    year?: TaxFormYear,
  ): TabName {
    switch (tabType) {
      case GeneralSpreadsTabTypeEnum.PCF_FOR_YEAR: {
        if (!year) {
          throw new Error("Year is required for PCF");
        }
        return `${entityName} - ${year}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR: {
        if (!year) {
          throw new Error("Year is required for K-1s");
        }
        return `K-1 - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS: {
        return `${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT: {
        return `Schedule C - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT: {
        if (!year) {
          throw new Error("Year is required for Schedule E");
        }
        return `Schedule E - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS: {
        return `Bus IS - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS: {
        return `Bus BS - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS: {
        return `Bus CF - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS: {
        return `Bus Ratios - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT: {
        if (!year) {
          throw new Error(`Year is required for ${tabType}`);
        }
        return `Form 8825 - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW: {
        return "Global Cash Flow" as TabName;
      }
      default: {
        throw new Error(`Unknown tab type: ${tabType}`);
      }
    }
  }
}

export function normalizeTaxpayerName(input: Person | undefined, entityName: string): string {
  if (entityName || !input || !input?.firstName || !input?.lastName) {
    return entityName;
  }
  const taxpayer = input as Person;
  const onlyFirst = taxpayer.firstName?.split(" ").shift();
  const last = taxpayer.lastName?.split(" ").pop();
  return capitalizeFirstLetterOfEachWord(`${onlyFirst} ${last}`);
}

export function normalizeCorporationName(input: Corporation | undefined): string {
  if (!input || !input.name) {
    return "";
  }

  return capitalizeFirstLetterOfEachWord(input.name.trim());
}

type RenderedContent = {
  gridState: GridState;
  gridOptions: RenderedDocOptions;
  columnDefs: ColDef[];
  hoverInfos: HoverInfo[][];
  asConfidence?: () => RawConfidenceContent[][];
};
