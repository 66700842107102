import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ReportingAction } from "src/redux/actions/actions.constants";
import { Pagination, PaginationDebounce } from "src/utils/pagination";
import { toastService } from "src/services/ToastService";
import { ReportingDetails } from "src/components/ReportingDetailsTable/ReportingDetailsTable";
import { reportingService } from "src/services/reporting-service/ReportingService";
import {
  reportingDataForCsvActionCompleted,
  resetIsLoadingAction,
  setReportingAllDataLoadedAction,
  setReportingDataForTableAction,
  setReportingFilters,
  setReportingHeaders,
} from "src/redux/actions/reporting.action";
import {
  reportingDataSelector,
  reportingHeadersSelector,
} from "src/redux/selectors/reporting-selector";
import Papa from "papaparse";
import { openSaveFileDialog } from "src/utils/helpers";
import { SQLQueryWhere } from "src/utils/sql-query-builder";
import { TableHeader } from "src/models/ReportingFilter";

const paginationDebounce = new PaginationDebounce();
const CSV_PAGINATION_LIMIT = 300;
const PAGINATION_LIMIT = 20;
const pagination = new Pagination(PAGINATION_LIMIT);

function* getReportingDataForTable(action: {
  type: string;
  payload: {
    sortKey: string;
    isAscending: boolean;
    filterValues: SQLQueryWhere;
    columns: string[];
    fetchNextPage: boolean;
  };
}): any {
  try {
    const { fetchNextPage } = action.payload;
    const { limit, offset: previousOffset } = pagination.getPage();
    const offset = !fetchNextPage ? 0 : previousOffset;

    const { sortKey, isAscending, filterValues, columns } = action.payload;
    const existingData = yield select(reportingDataSelector) ?? [];

    const incomingData: ReportingDetails[] = yield call(
      reportingService.getReportingData,
      limit,
      offset,
      sortKey,
      isAscending ? "ASC" : "DESC",
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      filterValues,
      columns,
    );
    const combinedData = !fetchNextPage ? incomingData : existingData.concat(incomingData);
    paginationDebounce.setDebounceTimer();
    pagination.setOffset(offset + limit);
    yield put(setReportingAllDataLoadedAction(incomingData.length === 0));
    yield put(setReportingDataForTableAction(combinedData));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch reporting data. Something went wrong!");
  } finally {
    yield put(resetIsLoadingAction());
  }
}

function* getReportingDataForCsv(action: {
  type: string;
  payload: { columns: string[]; filterValues: SQLQueryWhere };
}): any {
  try {
    let offset = 0;
    let combinedData: ReportingDetails[] = [];
    let haveMoreData = true;

    const headers: TableHeader[] = yield select(reportingHeadersSelector) || [];
    while (haveMoreData) {
      const response: ReportingDetails[] = yield call(
        reportingService.getReportingData,
        CSV_PAGINATION_LIMIT,
        offset,
        "",
        "ASC",
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        action.payload.filterValues,
        action.payload.columns,
      );
      combinedData.push(...response);
      offset = offset + CSV_PAGINATION_LIMIT;
      if (!response.length) {
        haveMoreData = false;
      }
    }

    const modifiedData = combinedData.map((obj) => ({
      // get keys of obj, find the label from headers, create new object with label as key and obj[key] as value
      ...Object.keys(obj).reduce((acc: Record<string, any>, key) => {
        const header = headers.find((h) => h.key === key);
        if (header) {
          acc[header.label] = obj[key];
        }
        return acc;
      }, {}),
    }));

    const csvData = Papa.unparse(modifiedData);
    openSaveFileDialog(csvData, "report", "text/csv");

    yield put(reportingDataForCsvActionCompleted());
    toastService.showSuccess("Report generated successfully.");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Unable to generate report. Something went wrong!");
  } finally {
    yield put(resetIsLoadingAction());
  }
}

function* getReportingFilters(): any {
  try {
    const filters = yield call(reportingService.getReportingFilters);
    yield put(setReportingFilters(filters));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch filters. Something went wrong!");
  }
}

function* getReportingHeaders(): any {
  try {
    const headers = yield call(reportingService.getReportingHeaders);
    yield put(setReportingHeaders(headers));
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't fetch headers. Something went wrong!");
  }
}

function* createReportingFilter(action: { type: string; payload: any }): any {
  try {
    yield call(reportingService.createReportingFilter, action.payload);
    yield put({ type: ReportingAction.GET_REPORTING_FILTERS });
    toastService.showSuccess("Filter created successfully.");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't create filter. Something went wrong!");
  }
}

function* updateReportingFilter(action: { type: string; payload: any }): any {
  try {
    yield call(reportingService.updateReportingFilter, action.payload);
    yield put({ type: ReportingAction.GET_REPORTING_FILTERS });
    toastService.showSuccess("Filter updated successfully.");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't update filter. Something went wrong!");
  }
}

function* deleteReportingFilter(action: { type: string; payload: number }): any {
  try {
    yield call(reportingService.deleteReportingFilter, action.payload);
    yield put({ type: ReportingAction.GET_REPORTING_FILTERS });
    toastService.showSuccess("Filter deleted successfully.");
  } catch (e: any) {
    console.log(e);
    toastService.showError("Couldn't delete filter. Something went wrong!");
  }
}

function* reporting() {
  yield takeEvery(ReportingAction.GET_REPORTING_DATA_FOR_TABLE, getReportingDataForTable);
  yield takeEvery(ReportingAction.GET_REPORTING_DATA_FOR_CSV, getReportingDataForCsv);
  yield takeEvery(ReportingAction.GET_REPORTING_FILTERS, getReportingFilters);
  yield takeEvery(ReportingAction.GET_REPORTING_HEADERS, getReportingHeaders);
  yield takeEvery(ReportingAction.CREATE_REPORTING_FILTER, createReportingFilter);
  yield takeEvery(ReportingAction.UPDATE_REPORTING_FILTER, updateReportingFilter);
  yield takeEvery(ReportingAction.DELETE_REPORTING_FILTER, deleteReportingFilter);
}

export default reporting;
