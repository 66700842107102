import { z } from "zod";

export const ZodInputSchema = z.object({
  type: z.enum([
    "date",
    "text",
    "checkbox",
    "checkbox-group",
    "dropdown",
    "filepicker",
    "radio",
    "segments",
    "text-area",
    "bulk-upload",
  ]),
  documentTypeId: z.number().optional(),
  documentYear: z.string().optional(),
  title: z.string().optional(),
  required: z.boolean(),
  role: z.string(),
  meta: z
    .object({
      width: z.string().optional(),
      placeholder: z.string().optional(),
      options: z.array(z.string()).optional(),
    })
    .optional(),
});

export enum QuestionActions {
  CREATE_BORROWER = "create-borrower",
  CREATE_BORROWER_OWNERS = "create-borrower-owners",
  PROVIDE_ENTITY_FINANCIALS = "provide-entity-financials",
  ADD_OWNED_BUSINESS_ENTITIES = "add-owned-business-entities",
  UPDATE_LOAN = "update-loan",
}

export type QuestionSchema = {
  id: string;
  type: string;
  title?: string;
  toolTip?: string;
  inputs?: z.infer<typeof ZodInputSchema>[];
  allFilePickers?: boolean;
  dynamicSubQuestions?: boolean;
  action?: QuestionActions;
  actionLabel?: string;
  subQuestions?: QuestionSchema[];
  validationSchema?: { [key: string]: any };
};

export const ZodQuestionSchema: z.ZodType<QuestionSchema> = z.object({
  id: z.string(),
  type: z.string(),
  title: z.string(),
  toolTip: z.string().optional(),
  inputs: z.array(ZodInputSchema).optional(),
  allFilePickers: z.boolean().optional(),
  dynamicSubQuestions: z.boolean().optional(),
  action: z.nativeEnum(QuestionActions).optional(),
  actionLabel: z.string().optional(),
  subQuestions: z.array(z.lazy(() => ZodQuestionSchema)).optional(),
  validationSchema: z.record(z.string(), z.any()).optional(),
});

const ZodPageActionSchema = z.object({
  label: z.string(),
  bgColor: z.string().optional(),
  section: z.enum(["overview", "guarantors", "borrower", "collateral"]),
  questions: z.array(ZodQuestionSchema),
  page: z.number(),
});

const ZodPageSchema = z.object({
  id: z.number(),
  page: z.number(),
  owner_name: z.string().optional(),
  owner_type: z.string().optional(),
  pageActions: z.array(ZodPageActionSchema).optional(),
  questions: z.array(ZodQuestionSchema),
});

export type PageSchema = z.infer<typeof ZodPageSchema>;

export const ZodWorkflowSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  key: z.string().min(1),
  borrower: z.object({
    individual: z.array(ZodPageSchema),
    company: z.array(ZodPageSchema),
  }),
  guarantor: z.object({
    individual: z.array(ZodPageSchema),
    company: z.array(ZodPageSchema),
    owned_by_guarantor: z.array(ZodPageSchema).optional(),
  }),
});

export function formatWorkflowKeyFromLabel(label: string): string {
  return label
    .toUpperCase()
    .replace(/[^A-Z]/g, "")
    .replace(/ /g, "_");
}

export type WorkflowSettings = z.infer<typeof ZodWorkflowSchema>;
