import { RumInitConfiguration } from "@datadog/browser-rum";
import process from "process";

// Is the app configured to connect to LocalStack?
// Note: this isn't as simple as checking whether LOCALSTACK_ENDPOINT is set,
// because in Render, environment variables pass
// from the preview environment group to production,
// and cannot be unset.
export const appConnectedToLocalStack = Boolean(
  process.env.LOCALSTACK_ENDPOINT && process.env.LOCALSTACK_ENDPOINT.includes("localhost"),
);

// Is Datadog's Real User Monitoring (RUM) enabled?
// This defaults to "true", even in testing environments and locally,
// but developers may want to opt out on their own machines.
export const appShouldUseRUM = process.env.NEXT_PUBLIC_RUM_ENABLED === "true";

if (
  appShouldUseRUM &&
  (process.env.NEXT_PUBLIC_BUILD_ID === undefined || process.env.NEXT_PUBLIC_BUILD_ID === "")
) {
  throw new Error("NEXT_PUBLIC_BUILD_ID is not set");
}

export const datadogRUMConfiguration: RumInitConfiguration = {
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? "",
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "",
  site: process.env.NEXT_PUBLIC_DATADOG_SITE ?? "us1.datadoghq.com",
  service: "interfold-app",
  // set "env" to either "prod" or "dev"
  // via configuration file on render
  env: process.env.NEXT_PUBLIC_DATADOG_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  version: process.env.NEXT_PUBLIC_BUILD_ID,
};
