import { Rendered, guessRowDataType } from "src/classes/RenderedDoc";
import { GridRow, GridState } from "src/classes/GridState";
import { RawCellContent } from "hyperformula";
import { ColDef } from "ag-grid-community";
import { ExtractContext } from "@interfold-ai/shared/models/extract/common";
import { Table } from "@interfold-ai/shared/models/extract/table";

export type TableWithContext = Table & {
  context: ExtractContext;
};

export class GenericPDFTable extends Rendered<TableWithContext, string> {
  get numberOfColumns() {
    const { rows } = this.table;
    const maxRowLength = Math.max(...rows.map((row) => Object.keys(row).length));
    return maxRowLength;
  }

  get defaultRowLabels(): string[] {
    return this.table.rows.map((row) => row[0]).flat();
  }

  get highlightedRowLabels() {
    return [];
  }
  get percentageRowLabels() {
    return this.defaultRowLabels.filter((label) => {
      return label.includes("Percentage");
    });
  }

  metadataRowLabels: string[] = [];

  constructor(public table: TableWithContext) {
    super(table);
  }

  get columnDefs(): ColDef[] {
    return Array.from({ length: this.numberOfColumns }, (_, index) => {
      const letter = this.colNumberToExcelCol(index + 1);
      return {
        headerName: letter,
        colId: letter,
        field: letter,
        sortable: false,
        flex: 1,
      };
    });
  }

  get initialGridState(): GridState {
    // Use keys of the longest row as headers if none are provided.

    const rows = this.table.rows;

    const gridState: GridState = Object.fromEntries(
      rows.map((row, index) => {
        const cells: RawCellContent[] = Object.values(row).map((cell) => cell.toString());
        const gridRow: GridRow = {
          rowDataArray: cells,
          rowDataType: guessRowDataType(cells),
          rowStyle: "standard",
          isManagedByApp: false,
          index,
        };
        return [index, gridRow];
      }),
    );

    return gridState;
  }
}
