import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { RowWithType } from "src/classes/RowWithType";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { Form1065IncomeStatementRowHeadings } from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatement";
import { Form1065IncomeStatementGrouped } from "./Form1065IncomeStatementGrouped";
import { Form1065BalanceSheetGrouped } from "./Form1065BalanceSheetGrouped";
import { Form1065CashFlowGrouped } from "./Form1065CashFlowGrouped";
import { buildForm1065Ratios } from "./Form1065Ratios";
import { sanitizeTabName } from "../utils";

export class Form1065RatiosGrouped extends AutoRendered<Form1065[]> {
  constructor(
    public taxData: Form1065[],

    public balanceSheetTabName: string,
    public incomeStatementTabName: string,
    public cashFlowTabName: string,

    public balanceSheet: Form1065BalanceSheetGrouped,
    public incomeStatement: Form1065IncomeStatementGrouped,
    public cashFlow: Form1065CashFlowGrouped,
  ) {
    super(taxData);
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  asColumns(): RawCellContent[][] {
    const startingRow = 1;
    const data = this.taxData?.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    if (!data) {
      return [];
    }

    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.year)]);
    const body: RawCellContent[][] = [];

    data.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = buildForm1065Ratios(
        form,
        startingColumn,
        startingRow,

        sanitizeTabName(this.incomeStatementTabName),
        sanitizeTabName(this.cashFlowTabName),
        sanitizeTabName(this.balanceSheetTabName),

        this.incomeStatement,
        this.cashFlow,
        this.balanceSheet,
      );
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels() {
    return ["Year"];
  }

  get percentageRowLabels() {
    return [];
  }

  metadataRowLabels: string[] = [];

  public indexOfHeader(header: keyof typeof Form1065IncomeStatementRowHeadings): number {
    const heading = Form1065IncomeStatementRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading);
  }
}
