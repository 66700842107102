import { UpdatedFeatureFlag } from "@interfold-ai/shared/models/FeatureFlag";
import { FeatureFlags } from "src/redux/actions/actions.constants";

export const fetchFeatureFlags = () => {
  return {
    type: FeatureFlags.FETCH_FEATURE_FLAGS,
  };
};

export const setFeatureFlags = (payload: { featureFlags: any }) => {
  return {
    type: FeatureFlags.SET_FEATURE_FLAGS,
    payload: payload,
  };
};

export const updateFeatureFlag = (payload: { featureFlag: UpdatedFeatureFlag }) => {
  return {
    type: FeatureFlags.UPDATE_FEATURE_FLAG,
    payload: payload,
  };
};

export const updatedFeatureFlag = () => {
  return {
    type: FeatureFlags.UPDATED_FEATURE_FLAG,
  };
};

export const cancelEditFeatureFlag = () => {
  return {
    type: FeatureFlags.CANCEL_EDIT_FEATURE_FLAG,
  };
};

export const editFeatureFlag = () => {
  return {
    type: FeatureFlags.EDIT_FEATURE_FLAG,
  };
};
