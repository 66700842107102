import { ScheduleE } from "@interfold-ai/shared/models/tax/schedules/ScheduleE";
import { TaxPropertiesRendered } from "./TaxPropertyBreakoutRendered";

export class ScheduleEBreakoutRendered extends TaxPropertiesRendered {
  constructor(underlying: ScheduleE, columnId: string = "B", rowStart: number = 0) {
    super(underlying.properties, columnId, rowStart, {
      "Net Income": 21,
      Depreciation: 18,
      Interest: 12,
      "Other Interest": 13,
      Amortization: 19,
    });
  }
}
