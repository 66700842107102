import { AutoRendered } from "src/classes/AutoRendered";
import { RawCellContent } from "hyperformula";
import { Form1040RowHeadings } from "src/classes/RenderedDocuments/Form1040/Form1040Rendered";
import { createForm1040Rendered } from "src/classes/RenderedDocuments/Form1040/Form1040Rendered";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { RowWithType } from "src/classes/RowWithType";
("src/classes/RenderedDocuments/Form1040/Form1040Rendered");
import { HoverLabel } from "src/classes/RenderedDoc";
import { sanitizeTabName } from "../utils";

export interface Form1040WithTabNames {
  wc: RenderableWithConfidence<Form1040>;
  k1DistributionTotal: number | undefined;
  scheduleETabName: string | null;
  scheduleCTabName: string | null;
}
export interface Form1040GroupedData {
  formWithTabNames: Form1040WithTabNames;
  rendered: ReturnType<typeof createForm1040Rendered>;
}

export class Form1040Grouped extends AutoRendered<Form1040WithTabNames[]> {
  private renderedForms: Form1040GroupedData[];

  constructor(
    data: Form1040WithTabNames[],
    public enableLoanCalculator: boolean = false,
  ) {
    super(data);

    // Build the rendered forms once and store them
    this.renderedForms = data
      .sort((a, b) => parseInt(a.wc.renderable.year) - parseInt(b.wc.renderable.year))
      .map((formWithTabNames, index) => {
        const {
          wc: form,
          k1DistributionTotal,
          scheduleETabName,
          scheduleCTabName,
        } = formWithTabNames;

        const rendered = createForm1040Rendered(
          form,
          String.fromCharCode(this.columnId.charCodeAt(0) + index),
          this.rowStart,
          k1DistributionTotal,
          //  these can be null if no schedule E or C is present
          scheduleETabName ? sanitizeTabName(scheduleETabName) : null,
          scheduleCTabName ? sanitizeTabName(scheduleCTabName) : null,
          this.enableLoanCalculator,
        );
        return { formWithTabNames, rendered };
      });

    this.setDefaultHoverInfos();

    this.setHoverInfoForLabel(
      Form1040RowHeadings.WagesAndSalaries,
      HoverLabel.from("Form 1040 Line 1a"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.InterestAndDividends,
      HoverLabel.from("Form 1040 Line 2a + 2b + 3a + 3b"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.PensionsAndAnnuities,
      HoverLabel.from("Form 1040 Line 5a"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.SocialSecurityBenefits,
      HoverLabel.from("Form 1040 Line 6a"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.ScheduleCEBIDA,
      HoverLabel.from("Schedule C Line 13 + 16a + 16b + 27a + 31"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.ScheduleDCapitalGains,
      HoverLabel.from("Form 1040 Line 7"),
    );
    this.setHoverInfoForLabel(
      Form1040RowHeadings.LessFederalTaxes,
      HoverLabel.from("Form 1040 Line 24"),
    );
  }

  asColumns(): RawCellContent[][] {
    const header = [
      "Year",
      ...this.renderedForms.map((r) => r.formWithTabNames.wc.renderable.year.toString()),
    ] as RowWithType;
    header.rowDataType = "text";
    const body: RawCellContent[][] = [];

    // Collect all unique labels from the rendered forms
    const allLabels = new Set<string>();
    this.renderedForms.forEach((renderedData) => {
      renderedData.rendered.body.forEach((row) => {
        allLabels.add(row[0] as string);
      });
    });

    // Convert set to array and sort labels if necessary
    const labels = Array.from(allLabels);

    // Build the body by matching labels and collecting values from each rendered form
    labels.forEach((label) => {
      const row: RawCellContent[] = [label];
      this.renderedForms.forEach((renderedData) => {
        const matchingRow = renderedData.rendered.body.find((r) => r[0] === label);
        if (matchingRow) {
          row.push(matchingRow[1]);
        } else {
          row.push(null); // or use a default value
        }
      });
      body.push(row);
    });

    return [header, ...body];
  }

  asConfidence(): RawConfidenceContent[][] {
    const header: RawConfidenceContent[] = [null, ...this.renderedForms.map(() => null)];
    const confidenceBody: RawConfidenceContent[][] = [];

    // Collect all unique labels from the rendered forms
    const allLabels = new Set<string>();
    this.renderedForms.forEach((renderedData) => {
      renderedData.rendered.body.forEach((row) => {
        if (row[0]) {
          allLabels.add(row[0].toString());
        }
      });
    });

    const labels = Array.from(allLabels);

    // Build the confidence body by matching labels and collecting confidence values
    labels.forEach((label) => {
      const row: RawConfidenceContent[] = [null];
      this.renderedForms.forEach((renderedData) => {
        const index = renderedData.rendered.body.findIndex((r) => r[0] === label);
        if (index !== -1) {
          const confidenceRow = renderedData.rendered.confidenceBody[index];
          row.push(confidenceRow[1]);
        } else {
          row.push(null); // or use a default confidence value
        }
      });
      confidenceBody.push(row);
    });

    return [header, ...confidenceBody];
  }

  get highlightedRowLabels(): string[] {
    return [
      "Year",
      Form1040RowHeadings.Subtotal,
      Form1040RowHeadings.CashFlowAvailableForDebtService,
      Form1040RowHeadings.TotalDebtService,
      Form1040RowHeadings.PersonalDSCR,
      Form1040RowHeadings.ExcessCashFlow,
    ];
  }

  percentageRowLabels: string[] = [];
  metadataRowLabels: string[] = [];

  indexOfHeader(header: keyof typeof Form1040RowHeadings): number {
    const heading = Form1040RowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
