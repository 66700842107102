import { createAsyncThunk, createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import {
  MemoQuestionnaire,
  MemoQuestionnaireFormSchema,
  MemoQuestionnaireStatus,
} from "src/models/MemoQuestionnaire";
import { MemoQuestionnaireService } from "src/services/memo-questionnaire/MemoQuestionnaireService";
import { AppState } from "../reducers";
import { StatusCodes } from "src/constants/status-codes";

export interface MemoQuestionnaireState {
  questionnaires: MemoQuestionnaire[];
  isLoading: boolean;
  error: string | null;
}

export interface MemoQuestionnaireAnswersState {
  answers: MemoQuestionnaireAnswers[];
  isLoading: boolean;
  error: string | null;
}

export interface MemoQuestionnaireStateByLenderId {
  questionnaires: Record<string, MemoQuestionnaireState>;
  answers: Record<string, MemoQuestionnaireAnswersState>;
}

export const initialMemoQuestionnaireState: MemoQuestionnaireStateByLenderId = {
  questionnaires: {},
  answers: {},
};

export const memoQuestionnaireStateSelector = (state: AppState) => state.memoQuestionnaire;

export const fetchMemoQuestionnaires = createAsyncThunk<
  MemoQuestionnaire[],
  FetchMemoQuestionnairesParams,
  { rejectValue: { lenderId: string; error: string } }
>("memoQuestionnaire/fetchMemoQuestionnaires", async ({ lenderId }, { rejectWithValue }) => {
  try {
    const service = MemoQuestionnaireService.getInstance();
    const questionnaires = await service.getMemoQuestionnairesByLenderId();
    return questionnaires;
  } catch (error: any) {
    if (error?.status === StatusCodes.NOT_FOUND) {
      return [];
    }
    return rejectWithValue({ lenderId, error: (error as Error).message });
  }
});

export const createMemoQuestionnaire = createAsyncThunk<
  MemoQuestionnaire,
  CreateMemoQuestionnaireParams,
  { rejectValue: { lenderId: string; error: string } }
>("memoQuestionnaire/createMemoQuestionnaire", async (params, { rejectWithValue }) => {
  try {
    const service = MemoQuestionnaireService.getInstance();
    const updatedQuestionnaire = await service.saveMemoQuestionnaire(params);
    if (!updatedQuestionnaire) {
      return rejectWithValue({
        lenderId: params.lenderId,
        error: "Failed to create memo questionnaire",
      });
    }
    return updatedQuestionnaire;
  } catch (error: any) {
    return rejectWithValue({
      lenderId: params.lenderId,
      error: (error as Error).message,
    });
  }
});

export const saveMemoQuestionnaireAnswers = createAsyncThunk<
  MemoQuestionnaireAnswers,
  SaveMemoQuestionnaireAnswersParams,
  { rejectValue: { loanId: string; questionnaireId: string; error: string } }
>("memoQuestionnaire/saveMemoQuestionnaireAnswers", async (params, { rejectWithValue }) => {
  try {
    const service = MemoQuestionnaireService.getInstance();
    const updatedQuestionnaire = await service.saveMemoQuestionnaireAnswers(params);
    if (!updatedQuestionnaire) {
      return rejectWithValue({
        loanId: params.loanId,
        questionnaireId: params.questionnaireId,
        error: "Failed to save memo questionnaire answers",
      });
    }
    return updatedQuestionnaire;
  } catch (error: any) {
    return rejectWithValue({
      loanId: params.loanId,
      questionnaireId: params.questionnaireId,
      error: (error as Error).message,
    });
  }
});

export const getMemoQuestionnaireAnswers = createAsyncThunk<
  MemoQuestionnaireAnswers[],
  GetMemoQuestionnaireAnswersParams,
  { rejectValue: { loanId: number; questionnaireId?: string; error: string } }
>("memoQuestionnaire/getMemoQuestionnaireAnswers", async (params, { rejectWithValue }) => {
  try {
    const service = MemoQuestionnaireService.getInstance();
    const answers = await service.getMemoQuestionnaireAnswers(params);
    if (!answers) {
      return rejectWithValue({
        loanId: params.loanId,
        questionnaireId: params.questionnaireId,
        error: "Failed to get memo questionnaire answers",
      });
    }
    return answers;
  } catch (error: any) {
    return rejectWithValue({
      loanId: params.loanId,
      questionnaireId: params.questionnaireId,
      error: (error as Error).message,
    });
  }
});

export const memoQuestionnaireSlice: Slice<MemoQuestionnaireStateByLenderId> = createSlice({
  name: "memoQuestionnaire",
  initialState: initialMemoQuestionnaireState,
  reducers: {
    initializeLender: (
      state,
      action: PayloadAction<{
        lenderId: string;
        state: MemoQuestionnaireState;
      }>,
    ) => {
      state.questionnaires[action.payload.lenderId] = action.payload.state;
    },
    initializeLoan: (
      state,
      action: PayloadAction<{
        loanId: number;
        state: MemoQuestionnaireAnswersState;
      }>,
    ) => {
      state.answers[action.payload.loanId] = action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchMemoQuestionnaires
      .addCase(fetchMemoQuestionnaires.pending, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = true;
      })
      .addCase(fetchMemoQuestionnaires.fulfilled, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = false;
        state.questionnaires[action.meta.arg.lenderId].questionnaires = action.payload;
        state.questionnaires[action.meta.arg.lenderId].error = null;
      })
      .addCase(fetchMemoQuestionnaires.rejected, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = false;
        state.questionnaires[action.meta.arg.lenderId].error = action.payload?.error ?? null;
      })

      // createMemoQuestionnaire
      .addCase(createMemoQuestionnaire.pending, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = true;
      })
      .addCase(createMemoQuestionnaire.fulfilled, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = false;
        const questionnaires = state.questionnaires[action.meta.arg.lenderId].questionnaires;
        const existing = questionnaires.findIndex(
          (q) => q.questionnaireId === action.payload.questionnaireId,
        );
        if (existing === -1) {
          questionnaires.push(action.payload);
        } else {
          questionnaires[existing] = action.payload;
        }
        state.questionnaires[action.meta.arg.lenderId].questionnaires = questionnaires;
      })
      .addCase(createMemoQuestionnaire.rejected, (state, action) => {
        state.questionnaires[action.meta.arg.lenderId].isLoading = false;
        state.questionnaires[action.meta.arg.lenderId].error = action.payload?.error ?? null;
      })

      // getMemoQuestionnaireAnswers
      .addCase(getMemoQuestionnaireAnswers.pending, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = true;
      })
      .addCase(getMemoQuestionnaireAnswers.fulfilled, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = false;
        state.answers[action.meta.arg.loanId].answers = action.payload;
        state.answers[action.meta.arg.loanId].error = null;
      })
      .addCase(getMemoQuestionnaireAnswers.rejected, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = false;
        state.answers[action.meta.arg.loanId].error = action.payload?.error ?? null;
      })

      // saveMemoQuestionnaireAnswers
      .addCase(saveMemoQuestionnaireAnswers.pending, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = true;
      })
      .addCase(saveMemoQuestionnaireAnswers.fulfilled, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = false;
        state.answers[action.meta.arg.loanId].error = null;

        const answers = state.answers[action.meta.arg.loanId].answers;
        const existing = answers.findIndex(
          (a) => a.questionnaireResponseId === action.payload.questionnaireResponseId,
        );
        if (existing === -1) {
          answers.push(action.payload);
        } else {
          answers[existing] = action.payload;
        }
        state.answers[action.meta.arg.loanId].answers = answers;
      })
      .addCase(saveMemoQuestionnaireAnswers.rejected, (state, action) => {
        state.answers[action.meta.arg.loanId].isLoading = false;
        state.answers[action.meta.arg.loanId].error = action.payload?.error ?? null;
      });
  },
});

export const selectMemoQuestionnairesByLenderId = (state: AppState, lenderId: string) =>
  state.memoQuestionnaire.questionnaires[lenderId];

export const selectMemoQuestionnaireAnswersByLoanId = (state: AppState, loanId?: number) =>
  loanId ? state.memoQuestionnaire.answers[loanId] : undefined;

export interface FetchMemoQuestionnairesParams {
  lenderId: string;
}

export interface CreateMemoQuestionnaireParams {
  lenderId: string;
  questionnaire: MemoQuestionnaireFormSchema;
  status: MemoQuestionnaireStatus;
  template: string;
}

export interface SaveMemoQuestionnaireAnswersParams {
  questionnaireId: string;
  questionnaireResponseId: string;
  questionnaireVersion: number;
  loanId: string;
  responses: any;
}

export interface GetMemoQuestionnaireAnswersParams {
  loanId: number;
  questionnaireId?: string;
}

export interface MemoQuestionnaireAnswers {
  id?: number;
  questionnaireId: string;
  questionnaireResponseId: string;
  questionnaireVersion: number;
  loanId: string;
  responses: any;
}

export default memoQuestionnaireSlice.reducer;
