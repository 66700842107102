import { RawCellContent } from "hyperformula";
import { excelColToColIndex } from "src/classes/RenderedDoc";
import { AutoRenderedWithLineNumberHovers } from "src/classes/AutoRenderedWithLineNumberHovers";
import { TaxProperty } from "@interfold-ai/shared/models/tax/schedules/ScheduleE";

const labels = [
  "",
  "Net Income",
  "Depreciation",
  "Interest",
  "Other Interest",
  "Amortization",
  "Cash Flow",
] as const;

type ValidLabels = (typeof labels)[number];
export class TaxPropertiesRendered extends AutoRenderedWithLineNumberHovers<TaxProperty[]> {
  get defaultRowLabels(): string[] {
    return [...labels];
  }

  asColumns(): RawCellContent[][] {
    if (!this.underlying || this.underlying.length === 0) {
      return [];
    }

    const col = this.columnId; // this is excel A, B, C, etc.
    const startCol = excelColToColIndex(col);

    const rows: RawCellContent[][] = [...this.defaultRowLabels.map((label) => [label])];

    this.underlying.forEach((property, index) => {
      const thisCol = this.columnIndexToExcelCol(startCol + index);
      rows[0].push(property.propertyAddress);
      rows[1].push(property.netIncome);
      rows[2].push(property.depreciation);
      rows[3].push(property.mortgageInterest + (property.interest ?? 0));
      rows[4].push(property.otherInterest);
      rows[5].push(property.amortization.statementValue);
      rows[6].push(`=SUM(${thisCol}2:${thisCol}6)`);
    });

    // Add totals column for each row
    rows[0].push("Total");
    for (let i = 1; i < rows.length; i++) {
      const startingLetter = this.colNumberToExcelCol(startCol + 1);
      const endingLetter = this.colNumberToExcelCol(startCol + this.underlying.length);
      const formula = `=SUM(${startingLetter}${i + 1}:${endingLetter}${i + 1})`;
      rows[i].push(formula);
    }
    return [...rows];
  }

  get highlightedRowLabels(): string[] {
    return [];
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  metadataRowLabels: string[] = [];

  constructor(
    public underlying: TaxProperty[],
    public columnId: string = "B",
    public rowStart: number = 0,
    public labelToLineNumber: Partial<Record<ValidLabels, number>>,
  ) {
    super(underlying, columnId, rowStart);
    this.setDefaultHoverInfos();
    const totalCols = this.underlying.length + 2;
    const firstAndLastCols = [0, totalCols - 1];
    this.setHoverLabels(firstAndLastCols);
    this.highlightedRowIndexes = [0];
  }
}
